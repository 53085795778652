import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Customer, User } from '../../../interfaces/ApiInterfaces';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { SearchDefaults } from '../../../constants/SearchDefaults';
import { ClearUserMessageAction, SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { getLabel } from '../../common/label/Label.library';
import cloneDeep from "lodash/cloneDeep";
import { Card, CardContent, List, ListItem, Pagination, Paper } from '@mui/material';
import { APIButton } from '../../common/button/APIButton';
import './UserCustomers.css';
import { RoleIds } from '../../../constants/RoleIds';
import { ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { DialogModal } from '../../common/dialog.modal/DialogModal';
import { useNavigate } from 'react-router-dom';

interface Props {
    user: User;
    updateUser: any;
}

export const UserCustomerMessagesOnLoad = 2; // currently no "working" msg here but it sets "business units found" twice (first for the list initially empty)

export const UserCustomers: React.FC<Props> = ({ user, updateUser }) => {
    const [allCustomers, setAllCustomers] = useState<Customer[]>([]);
    const dispatch = useDispatch();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const canUpdateUser = hasPermissions(Permissions.CAN_UPDATE_USER);
    const isUserDemoUser = user.roleIds.includes(RoleIds.DEMO);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [customerToRemove, setCustomerToRemove] = useState<string>('');
    const navigate = useNavigate();

    const getCustomers = async (newPage: number, showSuccessMessage: boolean = true) => {
        if (user.id !== -1) {
            var filteredCustomers = allCustomers;
            filteredCustomers.sort((a, b) => (a.name > b.name) ? 1 : -1);
            var totalCount = filteredCustomers.length;
            var skip = (newPage - 1) * SearchDefaults.TAKE;
            var take = SearchDefaults.TAKE;

            if (showSuccessMessage) {
                if (totalCount === 0) {
                    dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_none')));
                }
                else if (totalCount === 1) {
                    dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_one')));
                }
                else {
                    dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_many', {
                        totalCount: totalCount,
                    })));
                }
            } else {
                dispatch(ClearUserMessageAction());
            }

            setCustomers(filteredCustomers.slice(skip, skip + take));
            setTotalResults(totalCount);
            setPage(newPage);
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        getCustomers(page, false);
    };

    const handleAdd = async (customerShortCode: string) => {
        try {
            const response = await makeJSONPostRequest(createUrl(ApiUrls.CREATE_USER_CUSTOMER_ASSOCIATION, { userId: user.id, customerShortCode }), dispatch, null, true, true);

            if (response.response.ok) {
                var newUser = cloneDeep(user);
                newUser.customerShortCodes.push(customerShortCode);
                updateUser(newUser);

                dispatch(SetUserMessageSuccessAction('user_customer_success_create_association_text'));
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('user_customer_failed_create_association_unauthorized'));
        }
    };

    const handleRemove = async (customerShortCode: string) => {
        var customerShortCodes = customers.map(customer => customer.shortCode);
        if (user.customerShortCodes.filter(value => customerShortCodes.includes(value)).length == 1 && !hasPermissions(Permissions.CAN_VIEW_CUSTOMERS) && !user.isCreator) {
            setCustomerToRemove(customerShortCode);
            setShowRemoveDialog(true);
        }
        else {
            try {
                const response = await makeJSONPostRequest(createUrl(ApiUrls.REMOVE_USER_CUSTOMER_ASSOCIATION, { userId: user.id, customerShortCode }), dispatch, null, true, true);

                if (response.response.ok) {
                    var newUser = cloneDeep(user);
                    const indexOfCustomerToRemove = newUser.customerShortCodes.findIndex((associatedCustomerShortCode: string) => associatedCustomerShortCode === customerShortCode);
                    if (indexOfCustomerToRemove !== -1) {
                        newUser.customerShortCodes.splice(indexOfCustomerToRemove, 1);
                        updateUser(newUser);
                    }

                    dispatch(SetUserMessageSuccessAction('user_customer_success_remove_association_text'));
                }
            } catch (error: any) {
                if (error.status === 401) dispatch(SetUserMessageErrorAction('user_customer_failed_remove_association_unauthorized'));
            }
        }
    };

    useEffect(() => {
        const getAllCustomers = async () => {
            const response = await makeJSONGetRequest(ApiUrls.GET_ALL_CUSTOMERS, dispatch, null, false, false);
            setAllCustomers(response.body.filter((c: any) => c.isActive === true));
        }
        getAllCustomers();
    }, [user.id]);

    useEffect(() => {
        getCustomers(1, true);
    }, [allCustomers]);

    const completeRemove = (async () => {
        try {
            setShowRemoveDialog(false);
            await makeJSONPostRequest(createUrl(ApiUrls.REMOVE_USER_CUSTOMER_ASSOCIATION, { userId: user.id, customerShortCode: customerToRemove }), dispatch, null, true, true);
            dispatch(SetUserMessageSuccessAction('user_customer_success_remove_association_text'));
            navigate(ApplicationRoutes.USERS);
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('user_customer_failed_remove_association_unauthorized'));
        }
    });

    const cancelRemove = (() => {
        setShowRemoveDialog(false);
    });

    return (
        <Card>
            <DialogModal id="removeDialog" open={showRemoveDialog} labelLeft={getLabel('remove_customer_cancel_button')} labelRight={getLabel('remove_customer_remove_button')} onClickLeft={cancelRemove} onClickRight={completeRemove}
                title={getLabel('remove_customer_remove_dialog_message')}></DialogModal>
            <CardContent className="user-customers">
                <List id="customerList">
                    {customers.map((customer: Customer) => 
                        <Paper key={customer.shortCode} elevation={2}>
                            <ListItem className="row">
                                <div className="user-customer-row truncate">
                                    <div>
                                        <div className="name">{customer.name}</div>
                                        <div className="user-customer-row-data truncate">
                                            <span><div className="colHeader">{getLabel("customer_list_address_header")}</div><div>{customer.address}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_city_header")}</div><div>{customer.city}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_state_header")}</div><div>{customer.state}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_zip_code_header")}</div><div>{customer.zipCode}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_telephone_header")}</div><div>{customer.telephone}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_email_header")}</div><div>{customer.email}</div></span>
                                        </div>
                                    </div>
                                    <div className="user-customer-buttons">
                                        {canUpdateUser &&
                                            <>
                                                {user.customerShortCodes.includes(customer.shortCode)
                                                    ? <APIButton className="remove-button button" type="button" variant="contained" color="primary" disabled={isUserDemoUser && !customer.forDemoOnly} onClick={(e:any) => handleRemove(customer.shortCode)}>{getLabel("user_customer_list_remove")}</APIButton>
                                                    : <APIButton className="add-button button" type="button" variant="contained" color="primary" disabled={isUserDemoUser && !customer.forDemoOnly} onClick={(e:any) => handleAdd(customer.shortCode)}>{getLabel("user_customer_list_add")}</APIButton>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </ListItem>
                        </Paper>
                    )}
                </List>
                    {totalResults === 0 && <p className="paging">{getLabel("user_customer_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handlePageChange} />}
            </CardContent>
        </Card>
    );
};