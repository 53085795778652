import React from 'react';
import { Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
import { getLabel } from '../../common/label/Label.library';
import './CustomerAttributes.css';
import { Customer } from '../../../interfaces/ApiInterfaces';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { Permissions } from '../../../constants/Permissions';
import { hasPermissions } from '../../../services/auth/auth';
import { formatLongDate } from '../../../services/format/date';

interface Props {
    customer: Customer
}

export const ViewCustomerAttributes: React.FC<Props> = ({ customer }) => {
    const featureFlags = useFeatureFlags();
    return (
        <Card>
            <CardContent>
                <div className="viewCustomer view-only">
                    <div className="customer-grid">
                        <div>
                            <span className="customerLabel"><div className="colHeader">{getLabel('customer_attributes_short_code_label')}</div><div>{customer.shortCode}</div></span>
                            <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_name')}</div><div>{customer.name}</div></span>
                            {featureFlags?.ServiceDirectoryCustomers &&
                                <span className="half-width grid">
                                    <span className="customerLabel"><div className="colHeader">{getLabel('customer_attributes_startDate_label')}</div><div>{formatLongDate(customer.startDate)}</div></span>
                                    <span className="customerLabel"><div className="colHeader">{getLabel('customer_attributes_endDate_label')}</div><div>{formatLongDate(customer.endDate)}</div></span>
                                </span>}
                        </div>
                        <div>
                            <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_address')}</div><div>{customer.address}</div></span>
                            <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_city')}</div><div>{customer.city}</div></span>
                            <span className="half-width grid">
                                <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_state')}</div><div>{customer.state}</div></span>
                                <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_zip')}</div><div>{customer.zipCode}</div></span>
                            </span>
                        </div>
                        <div>
                            <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_contact_name')}</div><div>{customer.contactName}</div></span>
                            <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_email')}</div><div>{customer.email}</div></span>
                            <span className="half-width grid">
                                <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_telephone')}</div><div>{customer.telephone}</div></span>
                                <span className="customerLabel"><div className="colHeader">{getLabel('view_customer_extension')}</div><div>{customer.extension}</div></span>
                            </span>
                        </div>
                        <div className="customer-attributes-checkboxes">
                            {featureFlags?.ServiceDirectoryCustomers && hasPermissions(Permissions.CAN_VIEW_PRIVILEGED_ATTRIBUTES) &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={customer.hasCoreProduct}
                                            disabled
                                        />
                                    }
                                    label={getLabel('customer_attributes_has_core_product_label')}
                                />}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={customer.forDemoOnly}
                                        disabled
                                    />
                                }
                                label={getLabel('customer_attributes_for_demonstration_only_label')}
                            />
                            {!featureFlags?.ServiceDirectoryCustomers &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={customer.isActive}
                                            disabled
                                        />
                                    }
                                    label={getLabel('customer_attributes_active_label')}
                                />}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
