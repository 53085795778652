import { UserRoles } from "../../interfaces/ApiInterfaces";

export function getUserPermissions(roles: UserRoles[]): string {
  var userPermissions = new Set<string>();
  roles.forEach((role) =>
    role.permissions.forEach((permission) =>
      userPermissions.add(permission.code)
    )
  );
  return Array.from(userPermissions).join(',');
}

export function getUserRoles(roles: UserRoles[]): string {
  return roles.map((role) => role.name).join(',');
}