import React, { useState, useEffect } from 'react';
import { getLabel } from '../../common/label/Label.library';
import { Button, CardContent, Card, List, ListItem, Paper, FormControl, FormControlLabel, Checkbox, Pagination } from '@mui/material';
import { useDispatch } from 'react-redux';
import './CustomerReports.css';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { ApiUrls } from '../../../constants/ApiUrls';
import { makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { Customer, Report } from '../../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { SearchDefaults } from '../../../constants/SearchDefaults';
import { TabValue } from '../../../constants/TabValue';

interface Props {
    customer: Customer
}

export const CustomerReports: React.FC<Props> = ({ customer }) => {
    const [reports, setReports] = useState([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customerShortCodes = [customer.shortCode];
    const [includeInactive, setIncludeActive] = useState(false);

    const getReports = async (newPage: number, showSuccessMessage: boolean = true, showWorkingMessage: boolean = true) => {
        const data = {
            CustomerShortCodes: customerShortCodes,
            IncludeInactive: includeInactive,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        const response = await makeJSONPostRequest(
            ApiUrls.GET_REPORTS, dispatch, data, showSuccessMessage, showWorkingMessage
        );
        if (showSuccessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }
        setReports(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getReports(page, false, true);
    };

    const editReport = (index: number) => {
        navigate(createRoute(ApplicationRoutes.EDIT_REPORT, { customerShortCode: customer.shortCode, reportId: index, tab: TabValue.REPORT_ATTRIBUTES }));
    }

    useEffect(() => {
        if (customer.shortCode) {
            getReports(1, true, true);
        }
    }, [customer, includeInactive])

    return (
        <Card>
            <CardContent>
                <div className="customerReports">
                    <div className="customer-report-actions">
                        {hasPermissions(Permissions.CAN_CREATE_REPORT_PARAMETERS) && <Button id="addButton" variant="contained" color="primary" onClick={() => editReport(-1)}>{getLabel('customer_add_report_label')}</Button>}
                        <FormControl variant="outlined">
                            <FormControlLabel
                                className='checkbox-input-field-padded'
                                control={<Checkbox
                                    color="primary"
                                    name="IncludeInactive"
                                    checked={includeInactive}
                                    onChange={(event) => {
                                        setIncludeActive(!includeInactive)
                                    }}
                                />}
                                label={getLabel('customer_report_include_inactive_label')}
                            />
                        </FormControl>
                    </div>
                    <List id="resultList">
                        {reports.map((report: Report) => <Paper> <ListItem className="row" button onClick={() => hasPermissions(Permissions.CAN_VIEW_REPORT_PARAMETERS) ? editReport(report.id) : null}>
                            {report.active && <div className="name">{report.name}</div>}
                            {!report.active && <div className="name inactive-result">{getLabel('inactive_name', { name: report.name })}</div>}
                            <div>
                                <span className="reportCol"><div className="colHeader">{getLabel("report_list_category_header")}</div><div>{(report.categories.map((category: { name: any; }) => category.name)).join(', ')}</div></span>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("report_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handlePageChange} />}
                </div>
            </CardContent>
        </Card>
    )
}
