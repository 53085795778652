import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import './CustomerAdvanced.css';
import { getLabel } from '../../common/label/Label.library';
import { TextInput } from '../../common/text.input/TextInput';
import { CheckboxInput } from '../../common/checkbox.input/CheckboxInput';
import { Button, CardContent, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ClearUserMessageAction, SetUserMessageErrorAction} from '../../../actions/userMessageAction';
import { CustomerAttribute } from '../../../interfaces/ApiInterfaces';
import { CustomerAttributeTypes } from '../../../constants/CustomerAttributeTypes';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { useNavigate } from 'react-router-dom';


interface Props {
    customerShortCode: string
}

interface AttributeValueMap { 
    [name: string]: string | boolean
}

export const CustomerAdvanced: React.FC<Props> = ({ customerShortCode }) => {
    const [attributes, setAttributes] = useState<CustomerAttribute[]>([]);
    const [initialAttributes, setInitialAttributes] = useState<AttributeValueMap>({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const saveAttributes = async (values: any) => {
        try {
            const data: { [name: string] : string } = {};
            Object.keys(values).forEach(attributeName => data[attributeName] = String(values[attributeName]).trim());
            await makeJSONPostRequest(createUrl(ApiUrls.UPDATE_CUSTOMER_ATTRIBUTES, { customerShortCode }), dispatch, data, true, true);
            cancelEdit();
        } catch {
            dispatch(SetUserMessageErrorAction(getLabel('customer_advanced_update_failure')));
        }
    }

    const getAttributeValue = (attribute: CustomerAttribute) : string | boolean =>  {
        if (attribute.type === CustomerAttributeTypes.BOOL) {
            return attribute.value.toLowerCase() === "true";
        }
        return attribute.value;
    }

    useEffect(() => {
        const getAttributes = async () => {
            const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER_ATTRIBUTES, { customerShortCode }), dispatch, null, true, true);
            const customerAttributes = response.body as CustomerAttribute[];
            setAttributes(customerAttributes);
            const attributeNameValuePairs: AttributeValueMap = {};
            customerAttributes.forEach(attribute => attributeNameValuePairs[attribute.name] = getAttributeValue(attribute));
            setInitialAttributes(attributeNameValuePairs);
            dispatch(ClearUserMessageAction());
        }
        if (!!customerShortCode) {
            getAttributes();
        }
    }, [customerShortCode])

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.CUSTOMERS));
    }

    return (
        <Card className='customer-advanced'>
            <CardContent>
                {attributes.length >= 1 &&
                    <Formik enableReinitialize={true}
                        initialValues={initialAttributes}
                        onSubmit={(values, actions) => {
                            saveAttributes(values).finally(() => {
                                actions.setSubmitting(false);
                            });
                        }}
                    >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                            <div className='attribute-row'>
                                <div><strong>{getLabel("customer_advanced_attribute_header")}</strong></div>
                                <div><strong>{getLabel("customer_advanced_value_header")}</strong></div>
                            </div>
                            {attributes.map((attribute: CustomerAttribute) => 
                                <>
                                    <hr />
                                    <div className='attribute-row'>
                                        <div>{attribute.isSecret ? <i>{attribute.name}</i> : attribute.name}</div>
                                        <div>
                                            {attribute.type === CustomerAttributeTypes.BOOL &&
                                                <CheckboxInput name={attribute.name} label="" disabled={attribute.readOnly} padded={false} />}
                                            {[CustomerAttributeTypes.STRING, CustomerAttributeTypes.NUMBER].includes(attribute.type) &&
                                                (attribute.readOnly ? 
                                                    <div>{attribute.value}</div> : 
                                                    <TextInput name={attribute.name} label="" placeholder={attribute.default} type={attribute.type === CustomerAttributeTypes.NUMBER ? "digit" : "text"} fullwidth />)}
                                        </div>
                                    </div>
                                </>
                            )}
                            {hasPermissions(Permissions.CAN_UPDATE_CUSTOMER) &&
                                <div className="edit-buttons">
                                    <Button className="button" type="button" variant="contained" color="primary" onClick={() => cancelEdit()} disabled={props.isSubmitting}>
                                        {getLabel('customer_advanced_cancel_button_label')}
                                    </Button>
                                    <Button className="edit-button button" type="submit" variant="contained" color="primary" disabled={props.isSubmitting || !props.dirty}>
                                        {getLabel('customer_advanced_submit_button_label')}
                                    </Button>
                                </div>}
                        </form>)}
                    </Formik>}
            </CardContent>
        </Card>
    )
}
