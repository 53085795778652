import { Breadcrumbs } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { getLabel } from "../label/Label.library";
import './PageHeading.css';

export interface HeadingNode {
    label: string;
    link?: string;
}

interface Props {
    nodes: HeadingNode[];
}

export const PageHeading: React.FC<Props> = ({ nodes }) => {
    return (
        <Breadcrumbs separator={getLabel('em_dash')} className="page-heading">
            {nodes.map((node: HeadingNode, index) =>
                !!node.link 
                ? <Link key={index} to={node.link}><h2 className="linked-node">{getLabel(node.label)}</h2></Link>
                : <h2 key={index} className="unlinked-node">{getLabel(node.label)}</h2>
            )}
        </Breadcrumbs>
    );
}