import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { SetAppFeatureFlagsAction } from '../../actions/applicationInfoAction';
import { ApiUrls } from '../../constants/ApiUrls';
import { Permissions } from '../../constants/Permissions';
import { createRoute, getActiveClass, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { useCustomerFlags } from '../../hooks/useCustomerFlags';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { getHomePage, getUserEmail, getUserName, hasPermissions, isAuthenticated, logOut } from '../../services/auth/auth';
import { Label } from '../common/label/Label';
import { getLabel } from '../common/label/Label.library';
import { Notifications } from '../common/notifications/Notifications';
import { InfoLink } from '../info/InfoLink';
import './Header.css';

export const Header: React.FC<any> = (props) => {
    const featureFlags = useFeatureFlags();
    const customerFlags = useCustomerFlags();
    const customerSkin = useCustomerSkin();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const getAppFeatureFlags = async () => {
            const featureFlags = await makeJSONGetRequest(ApiUrls.GET_APP_FEATURE_FLAGS, dispatch, null, false, false);
            dispatch(SetAppFeatureFlagsAction(featureFlags.body.featureFlags));
        }
        if (!featureFlags) {
            getAppFeatureFlags();
        }
    }, [dispatch, featureFlags])

    function NavLinks() {
        return (
            <>
                {
                    (hasPermissions(Permissions.CAN_VIEW_USERS) || hasPermissions(Permissions.CAN_VIEW_CUSTOMER_USERS_ONLY) || hasPermissions(Permissions.CAN_VIEW_CUSTOMER_AND_CUSTOMER_ADMIN_USERS_ONLY)) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.USERS])} to={createRoute(ApplicationRoutes.USERS)}>
                        <Label label="users_nav_heading" />
                    </Link>
                }
                {
                    (hasPermissions(Permissions.CAN_SEE_CUSTOMERS_PAGE)) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.CUSTOMERS])} to={createRoute(ApplicationRoutes.CUSTOMERS)}>
                        <Label label="customers_nav_heading" />
                    </Link>
                }
                {
                    hasPermissions(Permissions.CAN_VIEW_CATEGORY_PARAMETERS) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.CATEGORIES])} to={createRoute(ApplicationRoutes.CATEGORIES)}>
                        <Label label="categories_nav_heading" />
                    </Link>
                }
                {
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.REPORTS])} to={createRoute(ApplicationRoutes.REPORTS)}>
                        <span>{getLabel('reports_nav_heading') }</span>
                        <span className="unbolded-header">{(!!props.reportName ? props.reportName : '')}</span>
                    </Link>
                }
                {
                    (featureFlags?.Files && customerFlags?.HasStorageAccount) && (hasPermissions(Permissions.CAN_VIEW_PRIVILEGED_CONTAINER) || hasPermissions(Permissions.CAN_VIEW_CONTAINER)) &&
                    <Link className={getActiveClass(location.pathname, [ApplicationRoutes.LIST_FILES])} to={createRoute(ApplicationRoutes.LIST_FILES)}>
                        <Label label="files_nav_heading" />
                    </Link>
                }
                { featureFlags?.Info && <InfoLink infoTitle={props.infoTitle} />}
            </>
        );
    }

    return (
        <header>
            <div className="portal-header">
                <Link to={getHomePage()}>
                    <img src={require(`../../resources/images/${customerSkin.logoName}`)} alt="Header logo" />
                </Link>
                <Link className="application-title" to={getHomePage()}>
                    <Label label={customerSkin.title} />
                </Link>
            </div>
            <div className="header-right">
                {isAuthenticated() && (
                    <>
                        <NavLinks />
                        <Notifications isAuthenticated={isAuthenticated()} />
                        <Link to={ApplicationRoutes.LOGIN} onClick={() => logOut(dispatch)} title={`${getUserName()} (${getUserEmail()})`}>
                            <Label label="header_log_out" />
                        </Link>
                    </>
                )}
            </div>
        </header>
    );
};
