import { Button, Card, CardContent, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { makeJSONGetRequest, makeJSONPostFileRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { getLabel } from '../../../components/common/label/Label.library';
import { Formik, useFormikContext } from 'formik';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import { InfoTitles } from '../../../constants/InfoTitles';
import { FileInput } from '../../../components/common/file.input/FileInput';
import './FileUpload.css';
import { ClearUserMessageAction, SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { ActingFor } from '../../../components/common/acting.for/ActingFor';
import { useActingForShortCode } from '../../../hooks/useActingFor';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { setBrowserTitle } from '../../../services/browser/browser';
import { TextInput } from '../../../components/common/text.input/TextInput';
import { SelectInput } from '../../../components/common/select.input/SelectInput';
import { AutocompleteInput } from '../../../components/common/autocomplete.input/AutocompleteInput';
import { UploadContainer, UploadRestrictions } from '../../../interfaces/ApiInterfaces';
import { TextFieldLengths } from '../../../constants/TextFieldLengths';
import { ReinstateApiTokenTimeoutExpiryAction, SuspendApiTokenTimeoutExpiryAction } from '../../../actions/authAction';
import { AppState } from '../../../store/configureStore';
import { applicationInfoUploadRestrictions } from '../../../reducers/rootReducer';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes, createRoute } from '../../../constants/ApplicationRoutes';

export const FileUpload: React.FC<any> = (props) => {
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customerActingForShortCode = useActingForShortCode();
    const customerSkin = useCustomerSkin();
    const [isMisconfigured, setIsMisconfigured] = useState(false);
    const [containers, setContainers] = useState<UploadContainer[]>([]);
    const [emails, setEmails] = useState<string[]>([]);
    const uploadRestrictions = useSelector<AppState, UploadRestrictions | null>(applicationInfoUploadRestrictions);
    const [canAddVisibleTo, setCanAddVisibleTo] = useState(false);

    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_upload_file');
    }, [customerSkin])

    const uploadFiles = async (values: any, resetFunc: (any)) => {
        try {
            var data = new FormData();
            values.Files.forEach((file: any) => data.append("files", file, file.name));
            data.append("customerShortCode", customerActingForShortCode);
            data.append("container", values.Container);
            values.VisibleTo.forEach((email: string) => data.append("visibleTo", email));
            if (values.Comment) {
                data.append("comment", values.Comment);
            }
            
            const dataSize = getFormDataSize(data);
            const requestHeaderSizeBuffer = 51200; // 50 KB
            if (!!uploadRestrictions?.maximumSizeInBytes && (dataSize + requestHeaderSizeBuffer) > uploadRestrictions.maximumSizeInBytes) {
                dispatch(SetUserMessageErrorAction(getLabel('file_upload_max_file_size_exceeded')));
                return;
            }
            
            dispatch(SuspendApiTokenTimeoutExpiryAction());
            await makeJSONPostFileRequest(ApiUrls.UPLOAD_TO_BLOB_STORAGE, dispatch, data);
            setUploadSuccess(true);
            resetFunc({ values: { Files: [], Comment: '', Container: values.Container, VisibleTo: [] } });
            dispatch(SetUserMessageSuccessAction(getLabel('file_upload_success', { success: values.Files.length, total: values.Files.length })));
        }
        finally {
            dispatch(ReinstateApiTokenTimeoutExpiryAction());
        }
    };

    const getFormDataSize = (formData: FormData): number => {
        var size = 0;
        formData.forEach((value: FormDataEntryValue) => size += typeof value === "string" ? value.length : value.size);
        return size
    }

    const getInfoTitle = () => {
        return InfoTitles.FILE_UPLOAD;
    };

    const getEmails = async () => {
        try {
            const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER_AND_CUSTOMER_ADMIN_EMAILS, { customerShortCode: customerActingForShortCode }), dispatch, null, false, false);
            setEmails(response.body);
        } catch {
            setEmails([]);
        }
    }

    useEffect(() => {
        const getContainersAndEmails = async () => {
            dispatch(ClearUserMessageAction());
            try {
                await makeJSONPostRequest(createUrl(ApiUrls.CAN_CONNECT_TO_STORAGE, { customerShortCode: customerActingForShortCode }), dispatch, null, false, false);
                const response = await makeJSONPostRequest(createUrl(ApiUrls.GET_CONTAINERS, { customerShortCode: customerActingForShortCode, isForUpload: true }), dispatch, null, false, false);
                setContainers(response.body);
                setIsMisconfigured(false);
                await getEmails();
            } catch {
                setContainers([]);
                setIsMisconfigured(true);
            }       
        }
        if (customerActingForShortCode) {
            getContainersAndEmails();
        }
    }, [customerActingForShortCode]);
    
    const getAllowedExtensions = (): string | undefined => {
        return uploadRestrictions?.allowedExtensions.join(',');
    };

    const getContainerOptions = (): any => {
        return containers.map((container) =>
            <option key={container.uiName} value={container.blobStorageName}>
                {container.uiName}
            </option>
        );
    };

    const getDefaultContainer = (): any => {
        return containers.filter((container) => (container.isDefaultForUpload))[0].blobStorageName;
    };

    const DisableAndEmptyVisibleToBasedOnCurrentContainer = () => {
        const { values, setFieldValue } = useFormikContext<{ Container: string, VisibleTo: string[] }>();

        useEffect(() => {
            const blobContainer = containers.find(container => container.blobStorageName === values.Container) as UploadContainer;
            setCanAddVisibleTo(blobContainer.isVisibleToEditable);
            if (!canAddVisibleTo) {
                setFieldValue('VisibleTo', []);
            }
        }, [values.Container]);

        return null;
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="file-upload">
                <div className="report-list-heading">
                    <PageHeading nodes={[
                        { label: "file_page_heading", link: createRoute(ApplicationRoutes.LIST_FILES) },
                        { label: "file_upload_page_heading" }]} />
                    <ActingFor />
                </div>
                {containers.length > 0 &&
                <Card className="file-upload-panel">
                    <CardContent>
                        <Formik
                            initialValues={{ Files: [], Comment: '', Container: getDefaultContainer(), VisibleTo: [] }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                setUploadSuccess(false);
                                uploadFiles(values, actions.resetForm).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form encType='multipart/form-data' onSubmit={props.handleSubmit}>
                                    <div className="file-upload truncate">
                                        <FileInput name="Files" clear={uploadSuccess} disabled={isMisconfigured || props.isSubmitting} multiple={true} accept={getAllowedExtensions()}/>
                                        <SelectInput name="Container" label="file_filter_folder" blankOption={false} values={getContainerOptions()}/>
                                        <TextInput name="Comment" label="file_upload_comment_label" fullwidth={true} multiline={true} maxLength={TextFieldLengths.FILE_COMMENT_MAX_LENGTH} disabled={isMisconfigured}/>
                                    </div>
                                    <div className="file-upload">
                                        <div /><div />
                                        <AutocompleteInput name="VisibleTo" setFieldValue={props.setFieldValue} key={customerActingForShortCode} disabled={!canAddVisibleTo || isMisconfigured} options={emails} label="file_upload_visible_to_label" />
                                        <DisableAndEmptyVisibleToBasedOnCurrentContainer />
                                    </div>
                                    <div className="file-upload-buttons">
                                            <Button className="button" type="button" variant="contained" color="primary"
                                                onClick={() => { props.resetForm(); navigate(createRoute(ApplicationRoutes.LIST_FILES)); }}
                                                disabled={isMisconfigured || props.isSubmitting}>
                                            {getLabel('file_upload_cancel_button_label')}
                                        </Button>
                                            <Button className="upload-button button" type="submit" variant="contained" color="primary"
                                                disabled={isMisconfigured || props.isSubmitting || props.values.Files.length === 0 || props.values.Container.length === 0}>
                                            {getLabel('file_upload_submit_button_label')}
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>}
            </Container>
        </AuthenticatedLayout>
    )
}
