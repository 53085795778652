import {
  AuthStatus,
  AuthStatusActionTypes,
  REINSTATE_API_TOKEN_TIMEOUT_EXPIRY,
  SET_LOG_OUT,
  SET_START_INACTIVITY_TIMER,
  SUSPEND_API_TOKEN_TIMEOUT_EXPIRY,
} from '../actions/authAction';

const initialState: AuthStatus = {
  isLoggedOut: false,
  startInactivityTimer: false,
  enableApiTokenTimeoutExpiry: true
};

export const authStatus = (state: AuthStatus = initialState, action: AuthStatusActionTypes): AuthStatus => {
  switch (action.type) {
    case SET_LOG_OUT:
      return { isLoggedOut: action.authStatus.isLoggedOut, startInactivityTimer: state.startInactivityTimer, enableApiTokenTimeoutExpiry: state.enableApiTokenTimeoutExpiry };
    case SET_START_INACTIVITY_TIMER:
      return { isLoggedOut: state.isLoggedOut, startInactivityTimer: action.authStatus.startInactivityTimer, enableApiTokenTimeoutExpiry: state.enableApiTokenTimeoutExpiry };
    case SUSPEND_API_TOKEN_TIMEOUT_EXPIRY:
      return { isLoggedOut: state.isLoggedOut, startInactivityTimer: state.startInactivityTimer, enableApiTokenTimeoutExpiry: false };
    case REINSTATE_API_TOKEN_TIMEOUT_EXPIRY:
      return { isLoggedOut: state.isLoggedOut, startInactivityTimer: state.startInactivityTimer, enableApiTokenTimeoutExpiry: true };
    default: {
      return state;
    }
  }
};

export const getLoggedOutStatus = (state: AuthStatus) => state.isLoggedOut;
export const getStartInactivityTimerStatus = (state: AuthStatus) => state.startInactivityTimer;
export const getEnableApiTokenTimeoutExpiryStatus = (state: AuthStatus) => state.enableApiTokenTimeoutExpiry;
