import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { ApiUrls } from '../../constants/ApiUrls';
import { makeAuthenticationRequest } from '../ajax/ajax';
import { Dispatch } from 'redux';
import { DestroyStoreAction } from '../../actions/rootAction';
import { Permissions } from '../../constants/Permissions';

const LOCAL_STORAGE_USER_ID_KEY: string = 'user_id';
const LOCAL_STORAGE_USER_NAME_KEY: string = 'user_name';
const LOCAL_STORAGE_USER_EMAIL_KEY: string = 'user_email';
const LOCAL_STORAGE_RESTRICT_FILES_ACCESS_KEY: string = 'user_restrict_files_access';
const LOCAL_STORAGE_ALLOW_FILE_UPLOAD_KEY: string = 'user_allow_file_upload';
const LOCAL_STORAGE_USER_CUSTOMER_SHORTCODE_KEY: string = 'user_customer_shortCode';
const LOCAL_STORAGE_USER_CUSTOMER_NAME_KEY: string = 'user_customer_name';
const LOCAL_STORAGE_USER_ROLES_KEY: string = 'user_roles';
const LOCAL_STORAGE_USER_PERMISSIONS_KEY: string = 'user_permissions';
const LOCAL_STORAGE_USER_ACTING_FOR_SHORTCODE_KEY: string = 'user_acting_for_shortCode';

export function getUserId(): number | null {
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY);
  return userId ? +userId : null;
}

export function getUserName(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_NAME_KEY);
}

export function getUserEmail(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_EMAIL_KEY);
}

export function getUserCustomerShortCode(): string | null {
  const userCustomerShortCode = localStorage.getItem(LOCAL_STORAGE_USER_CUSTOMER_SHORTCODE_KEY);
  return userCustomerShortCode;
}

export function getUserCustomerName(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_CUSTOMER_NAME_KEY);
}

export function getRoles(): string[] {
  const roleString = localStorage.getItem(LOCAL_STORAGE_USER_ROLES_KEY);
  if (!roleString) {
    return [];
  }
  return roleString.split(',');
}

export function getPermissions(): string[] {
  const permissionString = localStorage.getItem(LOCAL_STORAGE_USER_PERMISSIONS_KEY);
  if (!permissionString) {
    return [];
  }
  return permissionString.split(',');
}

export function getActingForShortCode(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_ACTING_FOR_SHORTCODE_KEY);
}

export function setUserId(id: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, id);
}

export function setUserName(name: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_NAME_KEY, name);
}

export function setUserEmail(name: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_EMAIL_KEY, name);
}

export function setRestrictFilesAccess(restrictFilesAccess: string): void {
  localStorage.setItem(LOCAL_STORAGE_RESTRICT_FILES_ACCESS_KEY, restrictFilesAccess);
}

export function setFileUploadAllowed(allowFileUpload: string): void {
  localStorage.setItem(LOCAL_STORAGE_ALLOW_FILE_UPLOAD_KEY, allowFileUpload);
}

export function setUserCustomerShortCode(customerShortCode: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_CUSTOMER_SHORTCODE_KEY, customerShortCode);
}

export function setUserCustomerName(customerName: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_CUSTOMER_NAME_KEY, customerName);
}

export function setRoles(roles: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ROLES_KEY, roles);
}

export function setPermissions(permissions: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_PERMISSIONS_KEY, permissions);
}

export function setActingForShortCode(customerShortCode: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ACTING_FOR_SHORTCODE_KEY, customerShortCode);
}

export function clearUserCustomer(): void {
  localStorage.removeItem(LOCAL_STORAGE_USER_CUSTOMER_SHORTCODE_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_CUSTOMER_NAME_KEY);
}

export function hasPermissions(permission: string): boolean {
  return getPermissions().includes(permission);
}

export function hasRestrictedFilesAccess(): boolean {
  return localStorage.getItem(LOCAL_STORAGE_RESTRICT_FILES_ACCESS_KEY) === 'true';
}

export function isFileUploadAllowed(): boolean {
  return localStorage.getItem(LOCAL_STORAGE_ALLOW_FILE_UPLOAD_KEY) === 'true';
}

export function isAuthenticated(): boolean {
  return getPermissions().length > 0;
}

export async function logOut(dispatcher: Dispatch, sendLogOutApiRequest: boolean = true): Promise<void> {
  localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_NAME_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_EMAIL_KEY);
  localStorage.removeItem(LOCAL_STORAGE_RESTRICT_FILES_ACCESS_KEY);
  localStorage.removeItem(LOCAL_STORAGE_ALLOW_FILE_UPLOAD_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_CUSTOMER_SHORTCODE_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_CUSTOMER_NAME_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_PERMISSIONS_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_ACTING_FOR_SHORTCODE_KEY);
  if (sendLogOutApiRequest) {
    await makeAuthenticationRequest(ApiUrls.LOGOUT, dispatcher, null, false);
  }
  dispatcher(DestroyStoreAction());
}

export function getAuthenticatedHomePage(): string {
  if (hasPermissions(Permissions.CAN_VIEW_USERS)) {
    return createRoute(ApplicationRoutes.USERS);
  } else {
    return createRoute(ApplicationRoutes.REPORTS);
  }
}

export function getHomePage(): string {
  if (isAuthenticated()) {
    return getAuthenticatedHomePage();
  }
  return ApplicationRoutes.LOGIN;
}
