export interface LoginRedirect {
    pathname: string;
}

export const SET_LOGIN_REDIRECT = "SET_LOGIN_REDIRECT";
export const CLEAR_LOGIN_REDIRECT = "CLEAR_LOGIN_REDIRECT";

interface SetLoginRedirect {
    type: typeof SET_LOGIN_REDIRECT;
    redirect: LoginRedirect;
}

interface ClearLoginRedirect {
    type: typeof CLEAR_LOGIN_REDIRECT;
}

export type LoginRedirectAction = SetLoginRedirect | ClearLoginRedirect;

export const SetLoginRedirectAction = (pathname: string): SetLoginRedirect => ({
    type: SET_LOGIN_REDIRECT,
    redirect: { pathname: pathname }
});

export const ClearLoginRedirectAction = (): ClearLoginRedirect => ({
    type: CLEAR_LOGIN_REDIRECT
});