import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper, Pagination } from '@mui/material';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { ClearReportSearchAction, ReportSearch, SetReportSearchAction } from '../../actions/reportSearchAction';
import { reportCategoryDropdownValues, reportSearchValues } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import './Reports.css';
import { Report } from '../../interfaces/ApiInterfaces';
import { ActingFor } from '../../components/common/acting.for/ActingFor';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { SelectInput } from '../../components/common/select.input/SelectInput';
import { InfoTitles } from '../../constants/InfoTitles';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { useActingForShortCode } from '../../hooks/useActingFor';
import { SetReportCategoryDropdownAction } from '../../actions/ReportCategoryDropdownAction';
import { getUserCustomerShortCode } from '../../services/auth/auth';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { useNavigate } from 'react-router-dom';

export const Reports: React.FC<any> = (props) => {
    const [reports, setReports] = useState<Report[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, ReportSearch>(reportSearchValues);
    const categoryValues = useSelector<AppState, any>(reportCategoryDropdownValues);
    const dispatch = useDispatch();
    const customerActingForShortCode = useActingForShortCode();
    const customerSkin = useCustomerSkin();
    const navigate = useNavigate();
    
    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_list_reports');
    }, [customerSkin])

    const getReports = async (values: any, newPage: number, showSuccessMessage: boolean = true, showWorkingMessage: boolean = true) => {
        const data = {
            Name: values.name,
            Category: values.category,
            CustomerShortCodes: [getUserCustomerShortCode() ?? customerActingForShortCode],
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Name) delete data.Name;
        if (!data.Category) delete data.Category;
        const response = await makeJSONPostRequest(
            ApiUrls.GET_REPORTS, dispatch, data, showSuccessMessage, showWorkingMessage
        );
        if (showSuccessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }
        setReports(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    }

    const selectReport = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, report: Report) => {
        navigate(createRoute(ApplicationRoutes.VIEW_REPORT, { id: report.id, actingForShortCode: customerActingForShortCode }))
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getReports(searchValues, page, false, true);
    };

    const performSearch = async (values: any) => {
        dispatch(SetReportSearchAction(values.Name, values.Category));
        await getReports({name: values.Name, category: values.Category}, 1, true, true);
    }

    const clearSearchFilters = async () => {
        dispatch(ClearReportSearchAction());
        await getReports({ name: '', category: '' }, 1, true, true);
    }

    useEffect(() => {
        const getCategories = async () => {
            var result = await makeJSONGetRequest(ApiUrls.GET_REPORT_CATEGORIES, dispatch, null, false, false);
            dispatch(SetReportCategoryDropdownAction(result.body));
        }
        if (categoryValues.length === 0) {
            getCategories();
        }
    }, [categoryValues, dispatch]);

    useEffect(() => {
        if (customerActingForShortCode) getReports(searchValues, 1, true, true);
    }, [customerActingForShortCode])

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_REPORTS}>
            <Container maxWidth={false} className="listReports">
                <div className="report-list-heading">
                    <PageHeading nodes={[{label: "report_page_heading"}]} />
                    <ActingFor />
                </div>
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ Name: searchValues.name, Category: searchValues.category }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="grid">
                                        <TextInput name="Name" label="report_filter_name" fullwidth={false} />
                                        <SelectInput name="Category" label="report_filter_category" 
                                            values={categoryValues.map((category: any) =>
                                                <option key={category.name} value={category.name}>
                                                    {category.name}
                                                </option>
                                            )}
                                        />
                                    </div>
                                    <div></div>
                                    <div className="flexRow">
                                        <span className="flexFill"></span>
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                <div>
                    <List id="resultList">
                        {reports.map((report: Report) => <Paper key={report.id}> <ListItem className="row" button onClick={(event) => selectReport(event, report)}>
                            <div className="name">{report.name}</div>
                            <div>
                                <span className="reportCol"><div className="colHeader">{getLabel("report_list_category_header")}</div><div>{(report.categories.map((category: { name: any; }) => category.name)).join(', ')}</div></span>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("report_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handlePageChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
