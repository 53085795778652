import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent, List, ListItem, Paper } from '@mui/material';
import { Report, User } from '../../../interfaces/ApiInterfaces';
import { getLabel } from '../../common/label/Label.library';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { SetUserMessageSuccessAction } from '../../../actions/userMessageAction';

interface Props {
    user: User;
}

export const ViewUserReports: React.FC<Props> = ({ user }) => {
    const [userReports, setUserReports] = useState<Report[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const getUserReports = async () => {
            const response = await makeJSONGetRequest(
                createUrl(ApiUrls.GET_USER_REPORTS, { userId: user.id }), dispatch, null, true, true
            );
            setUserReports(response.body);

            var totalCount = response.body.length;
            if (totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_none')));
            }
            else if (totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('report_search_result_many', {
                    totalCount: totalCount,
                })));
            }
        };
        getUserReports();
    }, [user]);

    return (
        <Card>
            <CardContent>
                <List id="reportsList">
                    {userReports.map((report: Report) =>
                        <Paper key={report.id}>
                            <ListItem className="row">
                                <div className="user-report-row truncate">
                                    <div>
                                        {report.active && <div className="name">{report.name}</div>}
                                        {!report.active && <div className="name inactive-result">{getLabel('inactive_name', { name: report.name })}</div>}
                                        <div>
                                            <span className="reportCol"><div className="colHeader">{getLabel("report_list_customer_header")}</div><div>{report.customer?.name}</div></span>
                                            <span className="reportCol"><div className="colHeader">{getLabel("report_list_category_header")}</div><div>{(report.categories.map(category => category.name)).join(', ')}</div></span>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            </ListItem>
                        </Paper>)}
                </List>
                {userReports.length === 0 && <p className="paging">{getLabel("report_search_result_none")}</p>}
            </CardContent>
        </Card>
        );
}