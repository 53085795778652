import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { Container, Card, AppBar, Tabs, Tab } from '@mui/material';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { CategoryAttributes } from '../../../components/categories/category.info/CategoryAttributes';
import './EditCategories.css';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { TabValue } from '../../../constants/TabValue';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { Category } from '../../../interfaces/ApiInterfaces';
import { HeadingNode, PageHeading } from '../../../components/common/page.heading/PageHeading';
import { setBrowserTitle } from '../../../services/browser/browser';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { InfoTitles } from '../../../constants/InfoTitles';
import { useNavigate, useParams } from 'react-router-dom';

export const EditCategory: React.FC<any> = (props) => {
    const dispatch = useDispatch();

    const [tabValue, setTabValue] = useState(0);
    const params = useParams();
    const categoryId = +params.id!;
    const [category, updateCategory] = useState<Category>({id: -1, name: '', active: true});
    const customerSkin = useCustomerSkin();
    const navigate = useNavigate();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, categoryId === -1 ? 'title_add_category' : 'title_edit_category');
    }, [customerSkin]);

    useEffect(() => {
        if (categoryId !== -1) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_CATEGORY, { categoryId }), dispatch, null, false, false).then((categoryResponse) => {
                updateCategory(categoryResponse.body);
            });
        }
    }, [categoryId, dispatch]);

    useEffect(() => {
        if (params.tab === TabValue.CATEGORY_ATTRIBUTES) {
            setTabValue(0);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_CATEGORY, { id: categoryId, tab: TabValue.CATEGORY_ATTRIBUTES }))
            setTabValue(0);
        }
    }, [categoryId, params.tab])

    function getAddEditLabel() {
        return categoryId === -1
            ? getLabel('category_add_page_heading')
            : getLabel('category_edit_page_heading', { displayName: category.name });
    }

    function getPageHeadingNodes(): HeadingNode[] {
        var nodes: HeadingNode[] = [];
        nodes.push({label: "category_page_heading", link: createRoute(ApplicationRoutes.CATEGORIES)});
        nodes.push({label: getAddEditLabel()});
        return nodes;
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_CATEGORY, { id: categoryId, tab: TabValue.CATEGORY_ATTRIBUTES }))
        }
        setTabValue(value);
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_CATEGORY;
        }
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="edit-category-container">
                <PageHeading nodes={getPageHeadingNodes()} />
                <Card className="edit-category-panel">
                    <AppBar position="static" color="default">
                        <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary"
                            textColor="primary">
                            <Tab value={0} label={getLabel('tab_label_attributes')} />
                        </Tabs>
                    </AppBar>
                    {tabValue === 0 && <CategoryAttributes category={category} />}
                </Card>
            </Container>
        </AuthenticatedLayout>
    );
};
