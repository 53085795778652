import { setActingForShortCode } from "../services/auth/auth";

export interface CustomerActingForValue { shortCode: string, name: string }

export const SET_CUSTOMER_ACTING_FOR_VALUE = "SET_CUSTOMER_ACTING_FOR_VALUE";

interface SetCustomerActingForValue {
    type: typeof SET_CUSTOMER_ACTING_FOR_VALUE;
    customerActingForValue: CustomerActingForValue;
}

export type CustomerActingForValueAction = SetCustomerActingForValue;

export const SetCustomerActingForValueAction = (shortCode: string, name: string): SetCustomerActingForValue => {
    if (shortCode) {
        setActingForShortCode(shortCode);
    }
    return {
        type: SET_CUSTOMER_ACTING_FOR_VALUE,
        customerActingForValue: { shortCode, name }
    }
};
