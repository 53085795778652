import React from 'react';
import { getLabel } from '../label/Label.library';
import { TextField, FormHelperText } from '@mui/material';
import './TextInput.css';
import { useField } from 'formik';
import { formatDigits } from '../../../services/format/format';
import { TextFieldLengths } from '../../../constants/TextFieldLengths';

interface Props {
    name: string;
    label: string;
    type?: string;
    fullwidth?: boolean;
    multiline?: boolean;
    shrink?: boolean;
    noautofill?: boolean;
    maxLength?: number | null;
    disabled?: boolean;
    placeholder?: string;
}

export const TextInput: React.FC<Props> = ({ name, label, type, fullwidth, multiline, shrink, noautofill, maxLength = TextFieldLengths.DEFAULT, disabled = false, placeholder }) => {
    const [field, meta] = useField(name);

    const formatText = (e: any) => {
        if (!!type && type === 'digit') {
            return formatDigits(e);
        }
        return e;
    }

    return (
        <div className="text-input">
            <TextField
                className={
                    fullwidth != null && fullwidth ? 'text-input-field' : 'text-input-field-padded'
                }
                InputLabelProps={{ shrink: shrink }}
                fullWidth={fullwidth != null ? fullwidth : true}
                multiline={multiline != null ? multiline : false}
                variant="outlined"
                label={getLabel(label)}
                id={field.name}
                name={field.name}
                error={meta.touched && Boolean(meta.error)}
                value={formatText(field.value)}
                type={type ? type : 'text'}
                autoComplete={noautofill == null ? undefined :
                    type == 'password' ? 'new-password' : 'off'}
                onChange={(event) => {
                    field.onChange(event);
                    field.onBlur(event);
                }}
                inputProps={maxLength ? { maxLength: maxLength } : undefined}
                placeholder={placeholder}
                disabled={disabled}
            />
            {meta.touched && Boolean(meta.error) && (
                <FormHelperText className="validateText">{meta.error}</FormHelperText>
            )}
        </div>
    );
};
