export interface CustomerSearch {
    name: string;
    city: string;
    state: string;
    includeDemo: boolean;
    includeInactive: boolean;
}

export const SET_CUSTOMER_SEARCH = "SET_CUSTOMER_SEARCH";
export const CLEAR_CUSTOMER_SEARCH = "CLEAR_CUSTOMER_SEARCH";

interface SetCustomerSearch {
    type: typeof SET_CUSTOMER_SEARCH;
    customerSearch: CustomerSearch;
}

interface ClearCustomerSearch {
    type: typeof CLEAR_CUSTOMER_SEARCH;
}


export type CustomerSearchAction = SetCustomerSearch | ClearCustomerSearch;

export const SetCustomerSearchAction = (name: string, city: string, state: string, includeDemo: boolean, includeInactive: boolean): SetCustomerSearch => ({
    type: SET_CUSTOMER_SEARCH,
    customerSearch: { name: name, city: city, state: state, includeInactive: includeInactive, includeDemo: includeDemo }
});

export const ClearCustomerSearchAction = (): ClearCustomerSearch => ({
    type: CLEAR_CUSTOMER_SEARCH
});