import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container } from '@mui/material';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import './ViewReport.css';
import { makeJSONGetRequest, makePostBlobRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { Report as ReportInterface, EmptyCustomer } from '../../../interfaces/ApiInterfaces';
import { PowerBIEmbed } from 'powerbi-client-react';
import { IEmbedConfiguration, service, Embed, models, Report as PowerBiReport } from 'powerbi-client';
import { ReportViewProperties } from '../../../constants/ReportViewProperties';
import { setReportBrowserTitle } from '../../../services/browser/browser';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { throttle } from 'lodash';
import { InfoTitles } from '../../../constants/InfoTitles';
import { getLabel } from '../../../components/common/label/Label.library';
import { useParams } from 'react-router-dom';
import { SetUserMessageSuccessAction } from '../../../actions/userMessageAction';

export const ViewReport: React.FC<any> = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const reportId = +params.id!;
    const viewReportRef = useRef<HTMLDivElement>(null);
    const [reportValues, setReportValues] = useState<ReportInterface>({ id: -1, name: '', reportShortName: '', url: '', categories: [], customer: EmptyCustomer, active: true, embedToken: '', reportId: '', isDashboard: false, hasExternalNav: false });
    const customerSkin = useCustomerSkin();

    useEffect(() => {
        setReportBrowserTitle(customerSkin.title, reportValues.reportShortName);
    }, [customerSkin]);

    const [isTile, setIsTile] = useState(false);
    const [tileReportId, setTileReportId] = useState('');
    const [report, setReport] = useState<PowerBiReport>();
    const [isLoaded, setIsLoaded] = useState(false);

    const [reportConfig, setReportConfig] = useState<IEmbedConfiguration | null>(null);

    const getReportStyleClass = () => {
        if (viewReportRef.current && viewReportRef.current.clientWidth && viewReportRef.current.clientHeight) {
            if ((viewReportRef.current.clientWidth / ReportViewProperties.WIDTH_TO_HEIGHT_VIEW_RATIO) > viewReportRef.current.clientHeight) {
                return "report-height-limiting";
            } else {
                return "report-width-limiting";
            }
        }
    };

    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
            setIsLoaded(true);
        }],
        ['tileClicked', async function (e: any) {
            setReportConfig(null);
            console.log(e.detail);
            const reportIdRegex = e.detail.navigationUrl.match(/(?<=reports\/)(.*?)(?=\/|$)/);
            if (reportIdRegex.length > 0 && !!reportIdRegex[0]) {
                const tileId = reportIdRegex[0];
                setTileReportId(tileId);
                const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_REPORT_TILE, { reportId, tileId }), dispatch, null, false, false);
                assignReportConfig('report', e.detail.reportEmbedUrl, response.body.embedToken, response.body.hasExternalNav);
                setIsTile(true);
            }
        }],
        ['rendered', function () {
            console.log('Report has rendered');
            refreshToken();
        }],
        ['error', function (event?: service.ICustomEvent<any>) {
            if (event) {
                console.error(event.detail);
            }
        }]
    ]);

    const assignReportConfig = (type: string, url: string, token: string, hasExternalNav: boolean) => {
        setIsLoaded(false);
        setReportConfig({
            tokenType: models.TokenType.Embed,
            settings: {
                panes: {
                    pageNavigation: {
                        visible: hasExternalNav
                    },
                    filters: {
                        visible: false
                    }
                }    
            },
            type: type,
            embedUrl: url,
            accessToken: token
        });
    }

    useEffect(() => {
        const loadReport = async () => {
            const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_REPORT, { reportId }), dispatch, null, false, false);
            setReportValues(response.body);

            // Set the fetched embedUrl and embedToken in the report config
            assignReportConfig(response.body.isDashboard ? 'dashboard' : 'report', response.body.url, response.body.embedToken, response.body.hasExternalNav);
        }
        loadReport();
    }, []);

    const refreshToken = throttle( async () => {
        await makeJSONGetRequest(ApiUrls.GET_APP_VERSION, dispatch, null, false, false);
    }, 10000);

    const getReportName = () => {
        return reportValues.reportShortName ? getLabel('spaced_em_dash') + reportValues.reportShortName : '';
    }

    const resetEmbeddedReport = () => {
        setReportConfig(null);

    }

    useEffect(() => {
        if (isTile && !reportConfig) {
            assignReportConfig('dashboard', reportValues.url, reportValues.embedToken, false);
            setIsTile(false);
            setTileReportId('');
        }
    }, [reportConfig])

    const downloadReport = async () => {
        let page = await report!.getActivePage();
        let bookmark = await report!.bookmarksManager.capture();
        const data = {
            TileId: !!tileReportId ? tileReportId : null,
            PageName: page.name,
            BookmarkState: bookmark.state
        };
        await makePostBlobRequest(createUrl(ApiUrls.DOWNLOAD_REPORT, { reportId }), dispatch, `${reportValues.name}.pdf`, data, true, true);
        dispatch(SetUserMessageSuccessAction(getLabel('file_download_success')));
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.VIEW_REPORT} reportName={getReportName()}>
            <Container ref={viewReportRef} maxWidth={false} className="viewReport">
                <div className='reportDiv'>
                    {isTile && <Button className="button reportButton" variant="contained" color="primary" onClick={resetEmbeddedReport}>{getLabel('return_to_dashboard_label')}</Button>}
                    {isLoaded && (!reportValues.isDashboard || isTile) && <Button className="button reportButton" variant="contained" color="primary" onClick={downloadReport}>{getLabel('download_as_pdf_label')}</Button>}
                </div>
                {reportValues.id != -1 && !!reportConfig && <PowerBIEmbed
                    embedConfig={reportConfig}
                    eventHandlers={eventHandlersMap}
                    cssClassName={`report-style-class ${getReportStyleClass()}`}
                    getEmbeddedComponent={(embedObject: Embed) => {
                        console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                        setReport(embedObject as PowerBiReport);
                    }}
                />}
            </Container>
        </AuthenticatedLayout>
    );
};
