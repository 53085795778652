import {
  Severity,
  UserMessage,
  UserMessageActionTypes,
  SET_USER_MESSAGE,
  CLEAR_USER_MESSAGE,
} from '../actions/userMessageAction';

const initialState: UserMessage = {
  severity: Severity.Success,
  message: '',
  ttl: 0
};

const checkOverride = (state: UserMessage) => {
  if (state.ttl) {
    state.ttl--;
    return state;
  }
};

export const userMessage = (
  state: UserMessage = initialState,
  action: UserMessageActionTypes
): UserMessage => {
  switch (action.type) {
    case SET_USER_MESSAGE:
      return checkOverride(state) ?? { ...action.userMessage };
    case CLEAR_USER_MESSAGE:
      return checkOverride(state) ?? initialState;
    default:
      return state;
  }
};

export const getSeverity = (state: UserMessage) => state.severity;
export const getUserMessage = (state: UserMessage) => state.message;
