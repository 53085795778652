export const isEmpty = (value: string) => !value || !value.trim();
export const isAllEmpty = (...values: string[]) => values.every(isEmpty);


const emailRegex = new RegExp(
    '^' +
    '([A-Z0-9_.%+-]+)@' +
    '([A-Z0-9-]{1,63}\\.)+' +
    '([A-Z0-9-]{1,63})' +
    '$', 'i');

export const isValidEmail = (email: string) => emailRegex.test(email);


const phoneRegex = new RegExp(
    '^\\s*' +
    '(?:\\+?(\\d{1,3}))?' +
    '[-. (]*(\\d{3})' + 
    '[-. )]*(\\d{3})' +
    '[-. ]*(\\d{4})' + 
    '(?: *x(\\d+))?' + 
    '\\s*$', 'i');

export const isValidPhone = (phone: string) => phoneRegex.test(phone);
