import { useSelector } from "react-redux";
import { CustomerSkinDefaults } from "../constants/CustomerSkinDefaults";
import { CustomerSkin, CustomerSkinsDictionary } from "../interfaces/ApiInterfaces";
import { customerSkinValues } from "../reducers/rootReducer";
import { isAuthenticated } from "../services/auth/auth";
import { AppState } from "../store/configureStore";
import { useActingFor } from "./useActingFor";

const getDefaultSkin = (customerShortCode: string): CustomerSkin => {
    return {
        customerShortCode: customerShortCode,
        hostname: '',
        title: CustomerSkinDefaults.TITLE,
        logoName: CustomerSkinDefaults.LOGO_NAME,
        themePrimaryMainColor: CustomerSkinDefaults.THEME_PRIMARY_MAIN_COLOR,
        themePrimaryContrastTextColor: CustomerSkinDefaults.THEME_PRIMARY_CONTRAST_TEXT_COLOR,
        themeCssClassName: CustomerSkinDefaults.CSS_CLASS_NAME
    };
}

export function useCustomerSkins() {
    return useSelector<AppState, CustomerSkinsDictionary | null>(customerSkinValues);
}

export function useCustomerSkin(): CustomerSkin {
    const customerSkins = useCustomerSkins();
    const customerActingFor = useActingFor();
    const defaultSkin = getDefaultSkin(customerActingFor.shortCode);

    if (!customerSkins) {
        return defaultSkin;
    }

    if (isAuthenticated()) {
        return customerSkins[customerActingFor.shortCode] ?? defaultSkin;
    }
    else {
        return Object.values(customerSkins)[0] ?? defaultSkin;
    }
}
