import { Customer } from "../interfaces/ApiInterfaces";

export interface CustomerDropdownValues { customers: Customer[] }

export const SET_CUSTOMER_DROPDOWN_VALUES = "SET_CUSTOMER_DROPDOWN_VALUES";

interface SetCustomerDropdownValues {
    type: typeof SET_CUSTOMER_DROPDOWN_VALUES;
    customerDropdownValues: CustomerDropdownValues;
}

export type CustomerDropdownValuesAction = SetCustomerDropdownValues;

export const SetCustomerDropdownValuesAction = (customers: Customer[]): SetCustomerDropdownValues => ({
    type: SET_CUSTOMER_DROPDOWN_VALUES,
    customerDropdownValues: { customers: customers }
});
