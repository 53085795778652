export interface RoleDropdown {
    roles: any
}

export const SET_ROLE_DROPDOWN = "SET_ROLE_DROPDOWN";

interface SetRoleDropdown {
    type: typeof SET_ROLE_DROPDOWN;
    roleDropdown: RoleDropdown;
}

export type RoleDropdownAction = SetRoleDropdown;

export const SetRoleDropdownAction = (roles: any): SetRoleDropdown => ({
    type: SET_ROLE_DROPDOWN,
    roleDropdown: { roles: roles}
});
