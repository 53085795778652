import { CustomerSkinsActionTypes, SET_CUSTOMER_SKINS } from "../actions/customerSkinsAction";
import { CustomerSkinsDictionary } from "../interfaces/ApiInterfaces";

type CustomerSkinsStateTypes = CustomerSkinsDictionary | null;

const initialState: CustomerSkinsStateTypes = null;

export const customerSkins = (state: CustomerSkinsStateTypes = initialState, action: CustomerSkinsActionTypes): CustomerSkinsStateTypes => {
    switch (action.type) {
        case SET_CUSTOMER_SKINS:
            return action.customerSkins;
        default: {
            return state;
        }
    }
}

export const getCustomerSkins = (state: CustomerSkinsStateTypes) => state;