import React, { useState } from 'react';
import { Card, CardContent, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { getLabel } from '../../common/label/Label.library';
import { TextInput } from '../../common/text.input/TextInput';
import { CheckboxInput } from '../../common/checkbox.input/CheckboxInput';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ApiUrls } from '../../../constants/ApiUrls';
import './CategoryAttributes.css';
import { Category } from '../../../interfaces/ApiInterfaces';
import { DialogModal } from '../../common/dialog.modal/DialogModal';
import { SetReportCategoryDropdownAction } from '../../../actions/ReportCategoryDropdownAction';
import { ClearReportSearchAction } from '../../../actions/reportSearchAction';
import { useNavigate } from 'react-router-dom';

interface Props {
    category: Category
}

export const CategoryAttributes: React.FC<Props> = ({ category }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [categoryValues, setCategoryValues] = useState <Category>(category);

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        // Validate name
        if (!values.name) {
            errors.name = getLabel('validation_message_required');
        }
        return errors;
    };

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.CATEGORIES));
    };

    const saveCategory = async (values: any) => {
        setCategoryValues({ id: category.id, name: values.name, active: values.active });
        if (category.id != -1 && !values.active) {
            setOpenDialog(true);
        }
        else {
            await continueSubmit(values);
        }
    }

    const continueSubmit = async (values: any = null) => {
        setOpenDialog(false);
        const isAdd = category.id === -1;
        const data = {
            Id: category.id,
            Name: values.name ?? categoryValues.name,
            Active: values.active ?? categoryValues.active,
        };
        try {
            const response = isAdd
                ? await makeJSONPostRequest(ApiUrls.CREATE_CATEGORY, dispatch, data)
                : await makeJSONPostRequest(ApiUrls.UPDATE_CATEGORY, dispatch, data);

            if (response.response.ok) {
                dispatch(SetReportCategoryDropdownAction([]));
                dispatch(ClearReportSearchAction());
                dispatch(SetUserMessageSuccessAction(isAdd ? 'category_attributes_success_add_text' : 'category_attributes_success_edit_text'));
                navigate(createRoute(ApplicationRoutes.CATEGORIES));
            }
        } catch (error: any) {
            if (error.status === 401) {
                dispatch(SetUserMessageErrorAction('category_failed_save_unauthorized'));
            }
        }
    };

    const cancelSubmit = () => {
        setOpenDialog(false);
    }

    return (
        <Card>
            <CardContent>
                <DialogModal id="inactiveDialog" title={getLabel("category_inactive_dialog_title")} onClickLeft={cancelSubmit} onClickRight={continueSubmit} open={openDialog} labelLeft={getLabel("category_dialog_cancel_submit")} labelRight={getLabel("category_dialog_complete_submit")} />
                <Formik enableReinitialize={true}
                    initialValues={{
                        name: category.name ?? '',
                        active: category.active ?? true
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveCategory(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}
                >
                    {(props) => (
                        <form className="edit-category-attributes" onSubmit={props.handleSubmit}>
                            <div className="edit-category-attributes-fields grid">
                                <div>
                                    <TextInput name="name" label="category_attributes_name_label" fullwidth={false} />
                                </div>
                            </div>
                            <div className="edit-category-attributes-buttons">
                                <CheckboxInput name="active" label="category_attributes_active_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>
                                    {getLabel('category_attributes_cancel_button_label')}
                                </Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={!props.dirty || props.isSubmitting}>
                                    {getLabel('category_attributes_submit_button_label')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};
