import React, { useEffect } from 'react';
import { Label } from '../common/label/Label';
import AppLinks from '../../resources/data/links.json';
import MagLogo from '../../resources/images/mag-logo-dark.png';
import './Footer.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { applicationInfoCurrentVersion } from '../../reducers/rootReducer';
import { isAuthenticated } from '../../services/auth/auth';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { SetCurrentAppVersionAction } from '../../actions/applicationInfoAction';

export const Footer: React.FC = () => {
  const dispatch = useDispatch();
  const appVersion = useSelector<AppState, string>(applicationInfoCurrentVersion);

  useEffect(() => {
    if (isAuthenticated()) {
      const getCurrentAppVersion = async () => {
        const appVersion = await makeJSONGetRequest(ApiUrls.GET_APP_VERSION, dispatch, null, false, false);
        dispatch(SetCurrentAppVersionAction(appVersion.body.version));
      }
      if (!appVersion) {
        getCurrentAppVersion();
      }
    }

  }, [appVersion]);

  return (
    <footer>
      <div className="footer-links">
        <div>
          <Label label={'footer_copyright_label'} />
          <a href={AppLinks.mag} target="_blank" rel="noopener noreferrer">
            <Label label={'footer_mag_label'} />
          </a>
        </div>
        <a href={AppLinks.term_and_conditions} target="_blank" rel="noopener noreferrer">
          <Label label={'footer_terms_and_conditions_label'} />
        </a>
      </div>
      <div className="footer-right">
        <span className="current-version">{appVersion}</span>
      </div>
    </footer>
  );
};
