import { SelectInput } from '../select.input/SelectInput';
import { StateCodes } from '../../../constants/StateCodes';
import React from 'react';

interface Props {
  name: string,
  label: string;
  disabled?: boolean;
  halfWidth?: boolean;
}

export const StatesDropdown: React.FC<Props> = ({ name, label, disabled = false, halfWidth = false }) => {
  return (
    <SelectInput name={name} label={label} values={getStateDropdownValues()} disabled={disabled} halfWidth={halfWidth} />
  )
};

function getStateDropdownValues() {
  const stateCodesTemp = StateCodes.STATE_CODES as any;
  return stateCodesTemp.map((state: string) => {
    return <option key={state} value={state}>{state}</option>;
  });
}