import React, { useEffect } from 'react';
import { Header } from '../../header/Header';
import { Footer } from '../../footer/Footer';
import './AuthenticatedLayout.css';
import { UserMessagePanel } from '../../user.message.panel/UserMessagePanel';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import { applicationInfoInactivityTimeoutInMinutes, applicationInfoUploadRestrictions, customerDropdownValues, shouldEnableApiTokenTimeoutExpiry, shouldStartInactivityTimer } from '../../../reducers/rootReducer';
import { ApiUrls } from '../../../constants/ApiUrls';
import { SetAppInactivityTimeoutAction, SetAppUploadRestrictionsAction } from '../../../actions/applicationInfoAction';
import { SetStartInactivityTimerAction } from '../../../actions/authAction';
import { getActingForShortCode, getUserCustomerShortCode, getUserCustomerName } from '../../../services/auth/auth';
import { useActingFor } from '../../../hooks/useActingFor';
import { SetCustomerDropdownValuesAction } from '../../../actions/customerDropdownValuesAction';
import { CustomerActingForValue, SetCustomerActingForValueAction } from '../../../actions/customerActingForValueAction';
import { useParams } from 'react-router-dom';
import { UploadRestrictions } from '../../../interfaces/ApiInterfaces';

export const AuthenticatedLayout: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const apiInactivityTimeoutMinutes = useSelector<AppState, number | null>(applicationInfoInactivityTimeoutInMinutes);
  const uploadRestrictions = useSelector<AppState, UploadRestrictions | null>(applicationInfoUploadRestrictions);
  const startInactivityTimer = useSelector<AppState, boolean>(shouldStartInactivityTimer);
  const enableApiTokenTimeoutExpiry = useSelector<AppState, boolean>(shouldEnableApiTokenTimeoutExpiry);
  const customerSkin = useCustomerSkin();
  const customerValues = useSelector<AppState, any>(customerDropdownValues);
  const customerActingFor = useActingFor();
  const params = useParams();

  const shouldCreateInactivityTimer: boolean = startInactivityTimer && !!apiInactivityTimeoutMinutes;
  // Extra 5 seconds to make sure to hit the API after token expiry
  const inactivityTimerValue: number = !apiInactivityTimeoutMinutes ? 0 : (apiInactivityTimeoutMinutes*60000 + 5000);
  // Ping every 75% of the timeout value
  const refreshApiTokenPingTimerValue: number = !apiInactivityTimeoutMinutes ? 0 : (apiInactivityTimeoutMinutes*60000*0.75);

  const pingApi = async () => {
    await makeJSONGetRequest(ApiUrls.GET_APP_VERSION, dispatch, null, false, false)
  };

  useEffect(() => {
    if (!apiInactivityTimeoutMinutes) {
      const getInactivityTimeout = async () => {
        const inactivityTimeout = await makeJSONGetRequest(ApiUrls.GET_APP_INACTIVITY_TIMEOUT, dispatch, null, false, false);
        dispatch(SetAppInactivityTimeoutAction(inactivityTimeout.body.inactivityTimeoutInMinutes));
      }
      getInactivityTimeout();
    }
  }, [dispatch, apiInactivityTimeoutMinutes]);

  useEffect(() => {
    if (!uploadRestrictions) {
      const getFileUploadRestrictions = async () => {
        const fileUploadRestrictions = await makeJSONGetRequest(ApiUrls.GET_APP_UPLOAD_RESTRICTIONS, dispatch, null, false, false);
        dispatch(SetAppUploadRestrictionsAction(fileUploadRestrictions.body.uploadRestrictions));
      }
      getFileUploadRestrictions();
    }
  }, [dispatch, uploadRestrictions]);

  useEffect(() => {
    if (!enableApiTokenTimeoutExpiry) {
      return;
    }
    const routeToLoginTimer = setTimeout(pingApi, inactivityTimerValue);
    dispatch(SetStartInactivityTimerAction(false));
    return () => clearTimeout(routeToLoginTimer);
  }, [dispatch, enableApiTokenTimeoutExpiry, shouldCreateInactivityTimer, inactivityTimerValue]);

  useEffect(() => {
    if (enableApiTokenTimeoutExpiry) {
      return;
    }
    pingApi(); // Reset the api cookie expiry timeout
    const refreshApiTokenTimer = setInterval(pingApi, refreshApiTokenPingTimerValue);
    return () => clearInterval(refreshApiTokenTimer);
  }, [enableApiTokenTimeoutExpiry, refreshApiTokenPingTimerValue]);
  
  useEffect(() => {
    const getCustomers = async () => {
        var result = await makeJSONGetRequest(ApiUrls.GET_ACTIVE_CUSTOMERS, dispatch, null, false, false);
        var values = result.body;
        if (values.length === 0)
            throw 'No customers found!?'; // prevent infinite loop
        dispatch(SetCustomerDropdownValuesAction(values));
        setActingForCustomer(values);
    };

    const setActingForCustomer = (values: any[]) => {
      var selectedCustomer: CustomerActingForValue;
      if (!!getUserCustomerShortCode() && !!getUserCustomerName()) {
        selectedCustomer = { shortCode: getUserCustomerShortCode()!, name: getUserCustomerName()! };
      }
      else {
        const linkedActingForShortCode = params.actingForShortCode;
        const linkedActingForCustomer = linkedActingForShortCode && values.some((customer: any) => customer.shortCode === linkedActingForShortCode) 
          ? values.find((customer: any) => customer.shortCode === linkedActingForShortCode) 
          : null;
        selectedCustomer = !!linkedActingForCustomer 
          ? linkedActingForCustomer 
          : customerActingFor.shortCode && values.some((customer: any) => customer.shortCode === customerActingFor.shortCode) 
            ? customerActingFor 
            : values.length > 0 
              ? (values.some((customer: any) => customer.shortCode === (getActingForShortCode())) 
                ? { ...values.find((customer: any) => customer.shortCode === (getActingForShortCode())) } 
                : { ...values[0] }) 
              : { shortCode: "", name: "" };
      }
      if (customerActingFor.shortCode !== selectedCustomer.shortCode || customerActingFor.name !== selectedCustomer.name) {
        dispatch(SetCustomerActingForValueAction(selectedCustomer.shortCode, selectedCustomer.name));
      }
    };

    if (customerValues.length === 0) {
      getCustomers();
    }
    else {
      setActingForCustomer(customerValues);
    }
  }, [dispatch, customerValues, customerActingFor]);

  return (!customerActingFor.shortCode
    ? <></>
    : <div className={`authenticated-layout ${customerSkin.themeCssClassName}`}>
      <Header {...props} />
      <div className="page-content">
        <UserMessagePanel />
        <div className="authenticated-content">{props.children}</div>
        <Footer />
      </div>
    </div>
  );
};
