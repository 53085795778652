import { CustomerDropdownValues, CustomerDropdownValuesAction, SET_CUSTOMER_DROPDOWN_VALUES } from '../actions/customerDropdownValuesAction';

const initialState: CustomerDropdownValues = { customers: [] };

export const customerDropdownValues = (
    state: CustomerDropdownValues = initialState,
    action: CustomerDropdownValuesAction
): CustomerDropdownValues => {
    if (action.type === SET_CUSTOMER_DROPDOWN_VALUES) {
        return {
            customers: action.customerDropdownValues.customers
        };
    }
    return state;

};

export const getCustomerDropdownValues = (state: CustomerDropdownValues) => state.customers;
