import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper, Pagination } from '@mui/material';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { StatesDropdown } from '../../components/common/states.dropdown/StatesDropdown';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { ClearCustomerSearchAction, CustomerSearch, SetCustomerSearchAction } from '../../actions/customerSearchAction';
import { customerSearchValues } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction, SetUserMessageWaitingAction } from '../../actions/userMessageAction';
import './Customers.css';
import { TabValue } from '../../constants/TabValue';
import { Customer } from '../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { InfoTitles } from '../../constants/InfoTitles';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { useNavigate } from 'react-router-dom';

export const Customers: React.FC<any> = (props) => {
    const [allCustomers, setAllCustomers] = useState<Customer[] | null>(null);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, CustomerSearch>(customerSearchValues);
    const dispatch = useDispatch();
    const customerSkin = useCustomerSkin();
    const canApplyDemoFilter = hasPermissions(Permissions.CAN_FILTER_CUSTOMERS_DEMO);
    const navigate = useNavigate();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_list_customers');
    }, [customerSkin])

    useEffect(() => {
        getCustomers(searchValues, 1);
    }, [])

    const getAllCustomers = async () => {
        const response = await makeJSONGetRequest(ApiUrls.GET_ALL_CUSTOMERS, dispatch, null, false, false);
        var allCustomers = response.body as Customer[];
        setAllCustomers(allCustomers);
        return allCustomers;
    }

    async function getCustomers(values: any, newPage: number, showWorkingMessage: boolean = true, showSuccessMessage: boolean = true) {
        if (showWorkingMessage) {
            dispatch(SetUserMessageWaitingAction("working_text"));
        }

        var filteredCustomers = allCustomers ?? await getAllCustomers();
        filteredCustomers = values.name ? filteredCustomers.filter(c => c.name.toLowerCase().includes(values.name.toLowerCase())) : filteredCustomers;
        filteredCustomers = values.city ? filteredCustomers.filter(c => c.city.toLowerCase().includes(values.city.toLowerCase())) : filteredCustomers;
        filteredCustomers = values.state ? filteredCustomers.filter(c => c.state === values.state) : filteredCustomers;
        filteredCustomers = (!canApplyDemoFilter || values.includeDemo) ? filteredCustomers : filteredCustomers.filter(c => c.forDemoOnly === false);
        filteredCustomers = values.includeInactive ? filteredCustomers : filteredCustomers.filter(c => c.isActive === true);

        filteredCustomers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        var totalCount = filteredCustomers.length;
        var skip = (newPage - 1) * SearchDefaults.TAKE;
        
        if (showSuccessMessage) {
            if (totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_none')));
            }
            else if (totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_many', {
                    totalCount: totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }
        setCustomers(filteredCustomers.slice(skip, skip + SearchDefaults.TAKE));
        setTotalResults(totalCount);
        setPage(newPage);
    }

    const selectCustomer = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, customer: Customer) => {
        navigate(createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: customer.shortCode, tab: TabValue.CUSTOMER_ATTRIBUTES }))
    }

    const createNewCustomer = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: ' ', tab: TabValue.CUSTOMER_ATTRIBUTES }))
    }

    const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await getCustomers(searchValues, value, true, false);
    };

    const performSearch = async (values: any) => {
        dispatch(SetCustomerSearchAction(values.Name, values.City, values.State, values.IncludeDemo, values.IncludeInactive));
        await getCustomers({
            name: values.Name,
            city: values.City,
            state: values.State,
            includeDemo: values.IncludeDemo,
            includeInactive: values.IncludeInactive
        }, 1);
    }

    async function clearSearchFilters() {
        dispatch(ClearCustomerSearchAction());
        await getCustomers({ name: '', city: '', state: '', includeDemo: false, includeInactive: false }, 1);
    }

    const getCustomerName = (customer: Customer) => {
        var cssClassName = "name";
        var customerName = "";
        if (customer.isActive && !customer.forDemoOnly) {
            customerName = customer.name;
        }
        else {
            cssClassName += " inactive-result";
            if (!customer.isActive && customer.forDemoOnly) {
                customerName = getLabel('demo_only_inactive_name', { name: customer.name });
            }
            else if (!customer.isActive) {
                customerName = getLabel('inactive_name', { name: customer.name });
            }
            else {
                customerName = getLabel('demo_only_name', { name: customer.name });
            }
        }
        return <div className={cssClassName}>{customerName}</div>
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_CUSTOMERS}>
            <Container maxWidth={false} className="listCustomers">
                <PageHeading nodes={[{label: "customer_page_heading"}]} />
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ 
                                Name: searchValues.name,
                                City: searchValues.city,
                                State: searchValues.state,
                                IncludeDemo: searchValues.includeDemo,
                                IncludeInactive: searchValues.includeInactive
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="grid">
                                        <TextInput name="Name" label="customer_filter_name" fullwidth={false} />
                                        <TextInput name="City" label="customer_filter_city" fullwidth={false} />
                                        <StatesDropdown name="State" label="customer_filter_state" />
                                    </div>
                                    <div></div>
                                    <div className="flexRow">
                                        <span className="flexFill"></span>
                                        {canApplyDemoFilter && <CheckboxInput name="IncludeDemo" label="customer_filter_include_demo" />}
                                        <CheckboxInput name="IncludeInactive" label="customer_filter_include_inactive" />
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                {hasPermissions(Permissions.CAN_CREATE_CUSTOMER) && <Button id="addButton" variant="contained" color="primary" onClick={createNewCustomer}>{getLabel('customer_add_label')}</Button>}
                <div>
                    <List id="resultList">
                        {customers.map((customer: Customer) => <Paper key={customer.shortCode}> <ListItem className="row" button onClick={(event) => selectCustomer(event, customer)}>
                            {getCustomerName(customer)}
                            <div className="truncate">
                                <span className="customerCol"><div className="colHeader">{getLabel("customer_list_short_code_header")}</div><div>{customer.shortCode}</div></span>
                                <span className="customerCol"><div className="colHeader">{getLabel("customer_list_address_header")}</div><div>{customer.address}</div></span>
                                <span className="customerCol"><div className="colHeader">{getLabel("customer_list_city_header")}</div><div>{customer.city}</div></span>
                                <span className="customerCol"><div className="colHeader">{getLabel("customer_list_state_header")}</div><div>{customer.state}</div></span>
                                <span className="customerCol"><div className="colHeader">{getLabel("customer_list_zip_code_header")}</div><div>{customer.zipCode}</div></span>
                                <span className="customerCol"><div className="colHeader">{getLabel("customer_list_telephone_header")}</div><div>{customer.telephone}</div></span>
                                <span className="customerCol"><div className="colHeader">{getLabel("customer_list_email_header")}</div><div>{customer.email}</div></span>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("customer_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handleChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
