import { createStore, applyMiddleware} from "redux";
import { createLogger } from 'redux-logger';
import rootReducer from "../reducers/rootReducer";

const logger = createLogger({
  collapsed: true, //(getState, action) => !action.type.includes('_USER_MESSAGE'),
  duration: true,
  timestamp: true,
  level: {
    // prevState: 'log',
    // nextState: 'log',
    action: 'log',
    error: 'error'
  },
  // diff: true,
  // diffPredicate: (getState, action) => action.type.includes('_USER_MESSAGE'),
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, applyMiddleware(logger));