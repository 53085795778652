import { UploadRestrictions } from "../interfaces/ApiInterfaces";

export interface FeatureFlags {
    SSOLogIn: boolean;
    Info: boolean;
    Files: boolean;
    ServiceDirectoryCustomers: boolean;
}

export interface ApplicationInfo {
    version: string;
    featureFlags: FeatureFlags | null;
    inactivityTimeoutInMinutes: number | null;
    uploadRestrictions: UploadRestrictions | null;
}

interface ApplicationInfoVersion {
    version: string;
}

interface ApplicationInfoFeatureFlags {
    featureFlags: FeatureFlags;
}

interface ApplicationInfoInactivityTimeout {
    inactivityTimeoutInMinutes: number;
}

interface ApplicationInfoUploadRestrictions {
    uploadRestrictions: UploadRestrictions;
}

export const SET_CURRENT_APP_VERSION = "SET_CURRENT_APP_VERSION";
export const SET_APP_FEATURE_FLAGS = "SET_APP_FEATURE_FLAGS";
export const SET_APP_INACTIVITY_TIMEOUT = "SET_APP_INACTIVITY_TIMEOUT";
export const SET_APP_UPLOAD_RESTRICTIONS = "SET_APP_UPLOAD_RESTRICTIONS";

interface SetCurrentAppVersion {
    type: typeof SET_CURRENT_APP_VERSION;
    applicationInfo: ApplicationInfoVersion;
}

interface SetAppFeatureFlags {
    type: typeof SET_APP_FEATURE_FLAGS;
    applicationInfo: ApplicationInfoFeatureFlags;
}

interface SetAppInactivityTimeout {
    type: typeof SET_APP_INACTIVITY_TIMEOUT;
    applicationInfo: ApplicationInfoInactivityTimeout;
}

interface SetAppUploadRestrictions {
    type: typeof SET_APP_UPLOAD_RESTRICTIONS;
    applicationInfo: ApplicationInfoUploadRestrictions;
}

export type ApplicationInfoActionTypes = SetCurrentAppVersion | SetAppFeatureFlags | SetAppInactivityTimeout | SetAppUploadRestrictions;

export const SetCurrentAppVersionAction = (version: string): SetCurrentAppVersion => ({
    type: SET_CURRENT_APP_VERSION,
    applicationInfo: { version: version }
});

export const SetAppFeatureFlagsAction = (featureFlags: FeatureFlags): SetAppFeatureFlags => ({
    type: SET_APP_FEATURE_FLAGS,
    applicationInfo: { featureFlags: featureFlags }
});

export const SetAppInactivityTimeoutAction = (inactivityTimeoutInMinutes: number): SetAppInactivityTimeout => ({
    type: SET_APP_INACTIVITY_TIMEOUT,
    applicationInfo: { inactivityTimeoutInMinutes: inactivityTimeoutInMinutes }
});

export const SetAppUploadRestrictionsAction = (uploadRestrictions: UploadRestrictions): SetAppUploadRestrictions => ({
    type: SET_APP_UPLOAD_RESTRICTIONS,
    applicationInfo: { uploadRestrictions: uploadRestrictions }
});