import React, { useState, useEffect } from 'react';
import { getLabel } from '../common/label/Label.library';
import { CardContent, Card, List, ListItem, Paper, Pagination } from '@mui/material';
import { useDispatch } from 'react-redux';
import './ReportCategories.css';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { ReportValues } from '../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { APIButton } from '../common/button/APIButton';

interface Props {
    report: ReportValues
}

export const ReportCategories: React.FC<Props> = ({ report }) => {
    const [categories, setCategories] = useState([]);
    const [reportCategories, setReportCategories] = useState([]);
    const dispatch = useDispatch();
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (report.id !== -1) {
            loadAllCategoriesPage(1);
            loadReportCategories(report.id);
        }
    }, [report]);

    const loadAllCategoriesPage = async (newPage: number, showSuccessMessage: boolean = true, showWorkingMessage: boolean = true) => {
        const data: any = {
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        const response = await makeJSONPostRequest(
            ApiUrls.GET_REPORT_CATEGORY_LIST, dispatch, data, showSuccessMessage, showWorkingMessage
        );
        
        if (showSuccessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setCategories(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const loadReportCategories = async (reportId:number) => {
        const response = await makeJSONGetRequest(
            createUrl(ApiUrls.GET_CATEGORIES_BY_REPORT, { reportId }), dispatch, null, false, false
        );
        setReportCategories(response.body);
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await loadAllCategoriesPage(page, false);
    };

    const addReportCategory = async (category: number) => {
        const data = {
            CategoryId: category,
            ReportId: report.id
        };
        const updateResponse = await makeJSONPostRequest(ApiUrls.ADD_CATEGORY_TO_REPORT, dispatch, data, true, true);
        await loadReportCategories(report.id);
        if (updateResponse.response.ok) {
            dispatch(SetUserMessageSuccessAction('category_success_included_text'));
        }
    };

    const removeReportCategory = async (category: number) => {
        const data = {
            CategoryId: category,
            ReportId: report.id
        };
        const updateResponse = await makeJSONPostRequest(ApiUrls.REMOVE_CATEGORY_FROM_REPORT, dispatch, data, true, true)
        await loadReportCategories(report.id);
        if (updateResponse.response.ok) {
            dispatch(SetUserMessageSuccessAction('category_success_excluded_text'));
        }
    };

    return (
        <Card>
            <CardContent>
                <div className="ReportCategories">
                    <List id="resultList">
                        {categories.map((category: {id: number, name: string}) => <Paper> <ListItem className="row">
                            <div className="category-row truncate">
                                <div>
                                    <div className="category-name">{category.name}</div>
                                </div>
                                <div>
                                    {hasPermissions(Permissions.CAN_UPDATE_REPORT_PARAMETERS) && !reportCategories.some((n: any) => n.id === category.id) && <APIButton className="button add-button" type="button" variant="contained" color="primary" onClick={(e: any) => addReportCategory(category.id)}>{getLabel("report_category_list_add_button_text")}</APIButton>}
                                    {hasPermissions(Permissions.CAN_UPDATE_REPORT_PARAMETERS) && reportCategories.some((n: any) => n.id === category.id) && <APIButton className="button remove-button" type="button" variant="contained" color="primary" onClick={(e: any) => removeReportCategory(category.id)}>{getLabel("report_category_list_remove_button_text")}</APIButton>}
                                </div>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("report_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handlePageChange} />}
                </div>
            </CardContent>
        </Card>
    )
}
