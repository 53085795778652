import React, { useState } from "react";
import { Button } from '@mui/material';

export const APIButton : React.FC<any> = (props) => {

    const [isProcessingEvent, setIsProcessingEvent] = useState(false);

    const handleOnClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsProcessingEvent(true);
        try {
            if(props.onClick){
                await props.onClick(event);
            }
        } finally {
            setIsProcessingEvent(false);
        }
    }

    return(
        <Button {...props} disabled={isProcessingEvent || props.disabled} onClick={(e: any) => handleOnClick(e)}>{props.children}</Button>
    );
}