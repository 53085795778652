import React from 'react';
import { isAuthenticated, hasPermissions, getAuthenticatedHomePage } from '../../../services/auth/auth';
import { ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { useDispatch } from 'react-redux';
import { SetLoginRedirectAction } from '../../../actions/loginRedirectAction';
import { Navigate, useLocation } from 'react-router-dom';

export const PrivateRoute = ({ Component, permissions, path }: any) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const renderRoute = () => {
        if (isAuthenticated()) {
            return (permissions && permissions.length > 0 && !(permissions.some((p: string) => hasPermissions(p))))
                ? (<Navigate to={{ pathname: getAuthenticatedHomePage() }} replace />)
                : <Component />;
        }
        dispatch(SetLoginRedirectAction(location.pathname+location.search));
        return <Navigate to={{ pathname: ApplicationRoutes.LOGIN }} replace />;
    };

    return renderRoute();
};
