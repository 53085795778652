import React from 'react';
import { getLabel } from './Label.library';

interface Props {
  label: string;
  params?: { [key: string]: any };
}

export const Label: React.FC<Props> = ({ label, params }) => {
  return (
    <label>
      <span dangerouslySetInnerHTML={{ __html: getLabel(label, params) }} />
    </label>
  );
};
