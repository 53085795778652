export class InfoTitles {
    public static readonly LIST_USERS = "List Users";
    public static readonly UPDATE_USER = "Add, Edit, or View User";
    public static readonly LIST_CUSTOMERS = "List Business Units";
    public static readonly UPDATE_CUSTOMER = "Add, Edit, or View Business Unit";
    public static readonly CUSTOMER_REPORTS = "Business Unit Reports";
    public static readonly CUSTOMER_ADVANCED = "Business Unit Advanced Attributes";
    public static readonly LIST_REPORTS = "List Reports";
    public static readonly VIEW_REPORT = "View Report";
    public static readonly UPDATE_REPORT_PARAMETERS = "Add, Edit, or View Report Parameters";
    public static readonly REPORT_CATEGORIES = "Report Categories";
    public static readonly LIST_CATEGORIES = "List Categories";
    public static readonly UPDATE_CATEGORY = "Add, Edit, or View Category";
    public static readonly FILE_UPLOAD = "Upload Files";
    public static readonly LIST_FILES = "List Files";
    public static readonly EDIT_FILE = "Edit File";
}