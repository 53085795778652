export enum Severity {
    Success = 'success',
    Error = 'error',
    Waiting = 'info'
}

export interface UserMessage {
    severity: Severity;
    message: string;
    ttl: number; // while greater than zero, next Set/Clear actions are ignored (and this message stays)
}

export const SET_USER_MESSAGE = "SET_USER_MESSAGE";
export const CLEAR_USER_MESSAGE = "CLEAR_USER_MESSAGE";

interface SetUserMessage {
    type: typeof SET_USER_MESSAGE;
    userMessage: UserMessage;
}

interface ClearUserMessage {
    type: typeof CLEAR_USER_MESSAGE;
}

export type UserMessageActionTypes = SetUserMessage | ClearUserMessage;

export const SetUserMessageSuccessAction = (message: string, overrideNextMessages: number = 0): SetUserMessage => ({
    type: SET_USER_MESSAGE,
    userMessage: {severity: Severity.Success, message: message, ttl: overrideNextMessages}
});

export const SetUserMessageErrorAction = (message: string, overrideNextMessages: number = 0): SetUserMessage => ({
    type: SET_USER_MESSAGE,
    userMessage: {severity: Severity.Error, message: message, ttl: overrideNextMessages}
});

export const SetUserMessageWaitingAction = (message: string): SetUserMessage => ({
    type: SET_USER_MESSAGE,
    userMessage: {severity: Severity.Waiting, message: message, ttl: 0}
});

export const ClearUserMessageAction = (): ClearUserMessage => ({
    type: CLEAR_USER_MESSAGE
});