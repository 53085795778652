import React, { useLayoutEffect, useEffect, useState } from 'react';
import { ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { getLabel } from '../../components/common/label/Label.library';
import { UnauthenticatedLayout } from '../../components/layouts/unauthenticated.layout/UnauthenticatedLayout';
import { TextInput } from '../../components/common/text.input/TextInput';
import { Formik } from 'formik';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@mui/material';
import './SetPassword.css';
import { ApiUrls } from '../../constants/ApiUrls';
import { makeAuthenticationRequest } from '../../services/ajax/ajax';
import queryString from 'query-string';
import { isAuthenticated, logOut } from '../../services/auth/auth';
import { useDispatch } from 'react-redux';
import { SetUserMessageErrorAction } from '../../actions/userMessageAction';
import { PasswordRules } from '../../constants/PasswordRules';
import { tryParseJson } from '../../services/error/error';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { useLocation, useNavigate } from 'react-router-dom';

export const SetPassword: React.FC<any> = (props) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const dispatch = useDispatch();
  const [showPasswordRules, setShowPasswordRules] = useState<boolean>(false);
  const customerSkin = useCustomerSkin();
  const navigate = useNavigate();

  useEffect(() => {
    setBrowserTitle(customerSkin.title, 'title_set_password');
  }, [customerSkin])

  async function setPassword(values: any, actions: any) {
    const data = {
      Email: queryParams.email || null,
      NewPassword: values.password,
      VerificationToken: queryParams.verificationToken || null,
    };
    const response = await makeAuthenticationRequest(
      ApiUrls.SET_PASSWORD,
      dispatch,
      JSON.stringify(data)
    );
    const responseJSON = await tryParseJson(response);
    if (response.ok) {
      navigate(ApplicationRoutes.LOGIN + '?passwordSet=true');
    } else if (response.status === 400 && responseJSON?.message === "Password is invalid.") {
      dispatch(SetUserMessageErrorAction(getLabel('set_password_authenticated_failed_text')));
      actions.setSubmitting(false);
      setShowPasswordRules(true);
    } else {
      dispatch(SetUserMessageErrorAction(getLabel('set_password_failed_text')));
    }
  }

  const validate = (values: any) => {
    const errors: { [key: string]: string } = {};
    // Validate password
    if (!values.password) {
      errors.password = getLabel('validation_message_required');
    }
    // Validate confirm password
    if (!values.confirmPassword) {
      errors.confirmPassword = getLabel('validation_message_required');
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = getLabel('validation_message_password_mismatch');
    }
    return errors;
  };

  const renderFormattedPasswordRules = () => {
    return (
      <div className="set-password-rules">
        <p>{getLabel('set_password_restrictions_header')}</p>
        <ul>
          <li>{getLabel('set_password_no_password_reuse_rule')}</li>
          <li>{getLabel('set_password_min_length_rule', { minLength: PasswordRules.MinPasswordLength })}</li>
          <li>{getLabel('set_password_min_char_types_rule', { minPassed: PasswordRules.MinCharacterTypes, total: PasswordRules.CharacterTypes.length })}
            <ul>
              {PasswordRules.CharacterTypes.map((x: string[]) => <li>{x[0]} <code>{x[1]}</code></li>)}
            </ul>
          </li>
          <li>{getLabel('set_password_disallowed_characters_rule')}
            <ul>
              {PasswordRules.DisallowedCharacters.map((x: string) => <li>{x}</li>)}
            </ul>
          </li>
        </ul>
      </div>
    );
  }

  useLayoutEffect(() => {
    if (isAuthenticated()) {
      logOut(dispatch);
      window.location.reload();
    }
    if (!queryParams.verificationToken) {
      navigate(ApplicationRoutes.NOT_FOUND);
    }
  }, [queryParams.verificationToken]);

  return (
    <UnauthenticatedLayout {...props}>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validate={validate}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          setPassword(values, actions);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Container maxWidth={false} className="set-password-container">
              <Card className="set-password-panel">
                <CardHeader title={getLabel('set_password_heading')} />
                <CardContent className="set-password-card-content">
                  <TextInput
                    name="password"
                    label="set_password_password_label"
                    type="password"
                    fullwidth={true}
                  />
                  <TextInput
                    name="confirmPassword"
                    label="set_password_confirm_password_label"
                    type="password"
                    fullwidth={true}
                  />
                  {showPasswordRules && renderFormattedPasswordRules()}
                  <Button
                    className="submit-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!(props.dirty && props.isValid) || props.isSubmitting}
                  >
                    {getLabel('set_password_submit_button_label')}
                  </Button>
                </CardContent>
              </Card>
            </Container>
          </form>
        )}
      </Formik>
    </UnauthenticatedLayout>
  );
};
