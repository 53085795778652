export interface UserSearch {
    name: string;
    email: string;
    city: string;
    state: string;
    customer: string;
    roleCode: string;
    includeInactive: boolean;
}

export const SET_USER_SEARCH = "SET_USER_SEARCH";
export const CLEAR_USER_SEARCH = "CLEAR_USER_SEARCH";


interface SetUserSearch {
    type: typeof SET_USER_SEARCH;
    userSearch: UserSearch;
}

interface ClearUserSearch {
    type: typeof CLEAR_USER_SEARCH;
}


export type UserSearchAction = SetUserSearch | ClearUserSearch;

export const SetUserSearchAction = (name: string, email: string, city: string, state: string, customer: string, roleCode: string, includeInactive: boolean): SetUserSearch => ({
    type: SET_USER_SEARCH,
    userSearch: { name: name, email: email, city: city, state: state, customer: customer, roleCode: roleCode, includeInactive: includeInactive}
});

export const ClearUserSearchAction = (): ClearUserSearch => ({
    type: CLEAR_USER_SEARCH
});
