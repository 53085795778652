export interface FileSearch {
    filename: string;
    container: string;
    startDate: string;
    endDate: string;
    metadata: string;
    orderBy: string;
    myFilesOnly: boolean;
    inclOffline: boolean;
}

export const SET_FILE_SEARCH = "SET_FILE_SEARCH";
export const CLEAR_FILE_SEARCH = "CLEAR_FILE_SEARCH";


interface SetFileSearch {
    type: typeof SET_FILE_SEARCH;
    fileSearch: FileSearch;
}

interface ClearFileSearch {
    type: typeof CLEAR_FILE_SEARCH;
}


export type FileSearchAction = SetFileSearch | ClearFileSearch;

export const SetFileSearchAction = (values: FileSearch): SetFileSearch => ({
    type: SET_FILE_SEARCH,
    fileSearch: { ...values }
});

export const ClearFileSearchAction = (): ClearFileSearch => ({
    type: CLEAR_FILE_SEARCH
});