import { RoleDropdown, RoleDropdownAction, SET_ROLE_DROPDOWN } from '../actions/roleDropdownAction';

const initialState: RoleDropdown = {
    roles: null
};

export const roleDropdown = (
    state: RoleDropdown = initialState,
    action: RoleDropdownAction
): RoleDropdown => {
    if (action.type === SET_ROLE_DROPDOWN) {
        return { roles: action.roleDropdown.roles};
    }
    return state;

};

export const getRoleDropdown = (state: RoleDropdown) => state.roles;
