export interface UserCategorySearch {
    name: string;
}

export const SET_USER_CATEGORY_SEARCH = "SET_USER_CATEGORY_SEARCH";
export const CLEAR_USER_CATEGORY_SEARCH = "CLEAR_USER_CATEGORY_SEARCH";

interface SetUserCategorySearch {
    type: typeof SET_USER_CATEGORY_SEARCH;
    userCategorySearch: UserCategorySearch;
}

interface ClearUserCategorySearch {
    type: typeof CLEAR_USER_CATEGORY_SEARCH;
}

export type UserCategorySearchAction = SetUserCategorySearch | ClearUserCategorySearch;

export const SetUserCategorySearchAction = (name: string): SetUserCategorySearch => ({
    type: SET_USER_CATEGORY_SEARCH,
    userCategorySearch: { name: name }
});

export const ClearUserCategorySearchAction = (): ClearUserCategorySearch => ({
    type: CLEAR_USER_CATEGORY_SEARCH
});