import React from 'react';
import { getLabel } from '../label/Label.library';
import { useField } from 'formik';
import { Autocomplete, TextField } from '@mui/material';

interface Props {
  name: string;
  label: string;
  disabled: boolean;
  options: string[];
  setFieldValue: any;
  key?: string;
}

export const AutocompleteInput: React.FC<Props> = ({ key, name, label, disabled, options, setFieldValue }) => {
  const [field, meta] = useField(name);

  return (
    <Autocomplete 
      key={key}
      id={field.name}
      multiple
      disabled={disabled}
      options={options}
      value={field.value}
      onChange={(event, values) => {
        field.onChange(event);
        field.onBlur(event);
        setFieldValue(name, values);
      }}
      renderInput={(params) =>
        <TextField
          {...params}
          label={getLabel(label)}
          variant="outlined"
        />
      }
    />
  );
};
