import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UserAttributes } from '../../components/users/UserAttributes';
import { makeJSONPostRequest, makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import './EditUser.css';
import { getLabel } from '../../components/common/label/Label.library';
import { TabValue } from '../../constants/TabValue';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { Customer, User, UserRoles } from '../../interfaces/ApiInterfaces';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { InfoTitles } from '../../constants/InfoTitles';
import { UserCustomers } from '../../components/users/user.customers/UserCustomers';
import { HeadingNode, PageHeading } from '../../components/common/page.heading/PageHeading';
import { UserReports } from '../../components/users/user.reports/UserReports';
import { UserCategories } from '../../components/users/user.categories/UserCategories';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { AppState } from '../../store/configureStore';
import { roleDropdownValues } from '../../reducers/rootReducer';
import { SetRoleDropdownAction } from '../../actions/roleDropdownAction';
import { APIButton } from '../../components/common/button/APIButton';
import { RoleIds } from '../../constants/RoleIds';
import { ViewUserAttributes } from '../../components/users/ViewUserAttributes';
import { ViewUserCustomers } from '../../components/users/user.customers/ViewUserCustomers';
import { ViewUserReports } from '../../components/users/user.reports/ViewUserReports';
import { ViewUserCategories } from '../../components/users/user.categories/ViewUserCategories';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogModal } from '../../components/common/dialog.modal/DialogModal';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export const EditUser: React.FC<any> = (props) => {
    const [tabIndex, setTabIndex] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();
    const userId = +params.id!;
    const isAdd = userId === -1;
    const [isResetPwdDialog, setResetPwdDialog] = useState(false);
    const [user, updateUser] = useState<User>();
    const [hasCustomerRole, updateHasCustomerRole] = useState(false);
    const canRestrictReportAccess = hasPermissions(Permissions.CAN_RESTRICT_REPORT_ACCESS);
    const canViewReportAccess = hasPermissions(Permissions.CAN_VIEW_REPORT_ACCESS);
    const customerSkin = useCustomerSkin();
    const roleValues = useSelector<AppState, any>(roleDropdownValues);
    const canUpdateUser = hasPermissions(Permissions.CAN_UPDATE_USER);
    const isSSOFeatureEnabled = useFeatureFlags()?.SSOLogIn;
    const navigate = useNavigate();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, isAdd ? 'title_add_user' : 'title_edit_user');
    }, [customerSkin])

    useEffect(() => {
        getUser()
    }, [userId, dispatch]);

    useEffect(() => {
        if (params.tab === TabValue.USER_ATTRIBUTES) {
            setTabIndex(0);
        }
        else if (params.tab === TabValue.USER_CUSTOMERS) {
            setTabIndex(1);
        }
        else if (params.tab === TabValue.USER_REPORTS) {
            setTabIndex(2);
        }
        else if (params.tab === TabValue.USER_CATEGORIES) {
            setTabIndex(3);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_ATTRIBUTES }))
            setTabIndex(0);
        }
    }, [params.tab, userId]);

    useEffect(() => {
        const getRoles = async () => {
            const result = await makeJSONGetRequest(ApiUrls.GET_ROLES, dispatch, null, false, false);
            dispatch(SetRoleDropdownAction(result));
        }
        if (!roleValues) {
            getRoles();
        }
    }, [roleValues, dispatch]);

    const getUser = async () => {
        if (!isAdd) {
            let userResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_USER, { userId }), dispatch, null, false, false);
            var user = userResponse.body;
            let roleIds = user.roles.map((role: UserRoles) => role.id);
            var customerShortCodes: string[] = user.customers.filter((c: Customer) => c.isActive).map((c: Customer) => c.shortCode);
            updateUser({ ...user, customerShortCodes, roleIds });
            updateHasCustomerRole(user.roles.some((role: UserRoles) => role.permissions.some(permission => permission.code === Permissions.CAN_ACT_FOR_ONLY_MY_CUSTOMERS)));
        }
        else {
            let roleIds: number[] = [];
            var restrictReportView = false;
            if (!hasPermissions(Permissions.CAN_VIEW_USERS))
            {
                roleIds.push(RoleIds.CUSTOMER);
                restrictReportView = true;
                if (hasPermissions(Permissions.CAN_ONLY_ACCESS_DEMO_CUSTOMERS)) roleIds.push(RoleIds.DEMO);
            }
            updateUser({ 
                id: -1,
                name: '',
                email: '',
                isActive: true,
                customerShortCodes: [],
                roleIds: roleIds,
                city: '',
                state: '',
                restrictReportView: restrictReportView,
                restrictFilesAccess: false,
                allowFileUpload: false,
                allowPwdLogin: null,
                allowSsoLogin: null,
                lastPwdLogin: null,
                lastSsoLogin: null,
                isCreator: true
            });
        }
    }

    function getAddEditLabel() {
        var label = "";
        if (userId === -1) {
            label = getLabel("user_add_page_heading");
        } else {
            label = getLabel("user_edit_page_heading", {
                displayName: user!.name,
            });
        }
        return label;
    }

    function getPageHeadingNodes(): HeadingNode[] {
        var nodes: HeadingNode[] = [];
        nodes.push({ label: "user_page_heading", link: createRoute(ApplicationRoutes.USERS) });
        nodes.push({ label: getAddEditLabel() });
        return nodes;
    }

    const resetPasswordBegin = () => { setResetPwdDialog(true); }
    const resetPasswordCancel = () => { setResetPwdDialog(false); }
    const resetPasswordEnd = async () => {
        setResetPwdDialog(false);
        const data = { Email: user!.email };
        await makeJSONPostRequest(ApiUrls.SEND_RESET_PASSWORD_EMAIL, dispatch, data);
        dispatch(SetUserMessageSuccessAction('user_password_reset_success_text'));
    }

    const sendOnboardingEmail = async (mode: 'Pwd' | 'Sso') => {
        const data = { Email: user!.email, LoginMode: mode };
        await makeJSONPostRequest(ApiUrls.SEND_ONBOARDING_EMAIL, dispatch, data);
        await getUser();
        dispatch(SetUserMessageSuccessAction('user_onboarding_email_success_text'));
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_ATTRIBUTES }))
        }
        if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_CUSTOMERS }))
        }
        if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_REPORTS }))
        }
        if (value === 3) {
            navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: userId, tab: TabValue.USER_CATEGORIES }))
        }
        setTabIndex(value);
    }

    const getInfoTitle = () => {
        return InfoTitles.UPDATE_USER;
    }

    const userHasPermission = (permission: string, userRoleIds: number[]): boolean => {
        if (!!roleValues) {
            var rolesWithPermission: UserRoles[] = roleValues.body.filter((role: UserRoles) => role.permissions.some(p => p.code === permission));
            return !!rolesWithPermission && rolesWithPermission.some(desiredRole => userRoleIds.includes(desiredRole.id));
        }
        return false;
    }

    const isMissingCustomers = (userRoleIds?: number[]): boolean => {
        const currentRoleIds = userRoleIds ?? user!.roleIds;
        const hasNonAdminRole = userHasPermission(Permissions.CAN_ACT_FOR_ONLY_MY_CUSTOMERS, currentRoleIds);
        return hasNonAdminRole && user!.customerShortCodes.length === 0;
    }

    const getUserMisconfiguredMsg = () => {
        if (isAdd || !user) return;
        if (isMissingCustomers())
            return getLabel('user_misconfigured_needCustomers');
        if (user.allowPwdLogin === null && user.allowSsoLogin === null)
            return getLabel('user_misconfigured_needSendInvite');
        if (user.allowPwdLogin !== true && user.allowSsoLogin !== true)
            return getLabel('user_misconfigured_needLoginMethod');
    }

    const canViewReportsAndCategoriesTabs = () => {
        return !isAdd && user?.restrictReportView && canViewReportAccess;
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="user-attributes-container">
                {!!user &&
                    <>
                        <PageHeading nodes={getPageHeadingNodes()} />
                        <Card className="user-attributes-panel">
                            <DialogModal id="resetPwdDialog" open={isResetPwdDialog} title={getLabel('user_resetPwdDialog_message', { email: user.email })}
                                onClickLeft={resetPasswordCancel} labelLeft={getLabel('user_resetPwdDialog_cancel_button')}
                                onClickRight={resetPasswordEnd} labelRight={getLabel('user_resetPwdDialog_confirm_button')} />
                            <AppBar position="static" color="default">
                                <Tabs value={tabIndex} onChange={(event, value) => handleTabChange(value)} aria-label="simple tabs example" indicatorColor="primary"
                                    textColor="primary">
                                    <Tab label={getLabel('tab_label_attributes')} value={0} />
                                    <Tab label={getLabel('tab_label_customers')} value={1} disabled={isAdd || !hasCustomerRole} />
                                    <Tab label={getLabel("tab_label_reports")} value={2} disabled={!canViewReportsAndCategoriesTabs()} />
                                    <Tab label={getLabel("tab_label_categories")} value={3} disabled={!canViewReportsAndCategoriesTabs()} />
                                </Tabs>
                            </AppBar>
                            {tabIndex === 0 &&
                            (canUpdateUser ?
                                (<UserAttributes user={user} reloadUser={getUser} userHasPermission={userHasPermission} needToAssociateWithCustomers={isMissingCustomers} />) :
                                (<ViewUserAttributes user={user} roleValues={roleValues.body} />))}
                            {tabIndex === 1 && (canUpdateUser ? (<UserCustomers user={user} updateUser={updateUser} />) : (<ViewUserCustomers user={user} />))}
                            {tabIndex === 2 && (canRestrictReportAccess ? (<UserReports user={user} />) : (<ViewUserReports user={user} />))}
                            {tabIndex === 3 && (canRestrictReportAccess ? (<UserCategories user={user} />) : (<ViewUserCategories user={user} />))}
                        </Card>
                        <div className="flexRow">
                            <span className="flexFill"></span>
                            <span className="user-misconfigured-msg">{getUserMisconfiguredMsg()}</span>
                            {!isAdd && user.allowPwdLogin === null && hasPermissions(Permissions.CAN_SEND_WELCOME_EMAIL) && (
                                <APIButton className="button" type="button" variant="contained" color="primary" onClick={() => sendOnboardingEmail('Pwd')} disabled={isMissingCustomers()}>
                                    {getLabel('user_attributes_sendPwdInvite_label')}
                                </APIButton>
                            )}
                            {!isAdd && user.allowSsoLogin === null && hasPermissions(Permissions.CAN_SEND_WELCOME_EMAIL) && isSSOFeatureEnabled && (
                                <APIButton className="button" type="button" variant="contained" color="primary" onClick={() => sendOnboardingEmail('Sso')} disabled={isMissingCustomers()}>
                                    {getLabel('user_attributes_sendSsoInvite_label')}
                                </APIButton>
                            )}
                            {!isAdd && tabIndex == 0 && hasPermissions(Permissions.CAN_REQUEST_PASSWORD_RESET) && (
                                <APIButton className="button resetPassword" type="button" variant="contained" color="primary" onClick={resetPasswordBegin} disabled={!user.allowPwdLogin}>
                                    {getLabel('user_attributes_resetPassword_label')}
                                </APIButton>
                            )}
                        </div>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};