import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent, List, ListItem, Paper } from '@mui/material';
import { Category, User } from '../../../interfaces/ApiInterfaces';
import { getLabel } from '../../common/label/Label.library';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { SetUserMessageSuccessAction } from '../../../actions/userMessageAction';

interface Props {
    user: User;
}

export const ViewUserCategories: React.FC<Props> = ({ user }) => {
    const [userCategories, setUserCategories] = useState<Category[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const getUserCategories= async () => {
            const response = await makeJSONGetRequest(
                createUrl(ApiUrls.GET_USER_REPORT_CATEGORIES, { userId: user.id }), dispatch, null, true, true
            );
            setUserCategories(response.body);
            var totalCount = response.body.length;
            if (totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_none')));
            }
            else if (totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('category_search_result_many', {
                    totalCount: totalCount,
                })));
            }
        };
        getUserCategories();
    }, [user]);

    return (
        <Card>
            <CardContent>
                <List id="categoriesList">
                    {userCategories.map((category: Category) =>
                        <Paper key={category.id}>
                            <ListItem className="row">
                                <div className="user-category-row truncate">
                                    <div>
                                        {category.active && <div className="name category">{category.name}</div>}
                                        {!category.active && <div className="name category inactive-result">{getLabel('inactive_name', { name: category.name })}</div>}
                                    </div>
                                    <div></div>
                                </div>
                            </ListItem>
                        </Paper>)}
                </List>
                {userCategories.length === 0 && <p className="paging">{getLabel("category_search_result_none")}</p>}
            </CardContent>
        </Card>
    );
}