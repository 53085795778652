import React, { useEffect } from 'react';
import './NotFound.css';
import { Label } from '../../components/common/label/Label';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';

interface Props {}

export const NotFound: React.FC<Props> = () => {
  const customerSkin = useCustomerSkin();

  useEffect(() => {
    setBrowserTitle(customerSkin.title, 'title_not_found');
  }, [customerSkin])

  return (
    <div className="page-not-found">
      <Label label="page_not_found" />
    </div>
  );
};
