import { CustomerActingForValue, CustomerActingForValueAction, SET_CUSTOMER_ACTING_FOR_VALUE } from '../actions/customerActingForValueAction';

const initialState: CustomerActingForValue = { shortCode: "", name: "" };

export const customerActingForValue = (
    state: CustomerActingForValue = initialState,
    action: CustomerActingForValueAction
): CustomerActingForValue => {
    if (action.type === SET_CUSTOMER_ACTING_FOR_VALUE) {
        return {
            shortCode: action.customerActingForValue.shortCode,
            name: action.customerActingForValue.name
        };
    }
    return state;

};

export const getCustomerActingForValue = (state: CustomerActingForValue) => state;
