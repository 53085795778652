import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper, Tooltip, Pagination } from '@mui/material';
import { makeJSONPostRequest, makePostBlobRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../../components/common/label/Label.library';
import { TextInput } from '../../../components/common/text.input/TextInput';
import { CheckboxInput } from '../../../components/common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import { ClearFileSearchAction, FileSearch, SetFileSearchAction } from '../../../actions/fileSearchAction';
import { fileSearchValues } from '../../../reducers/rootReducer';
import { ClearUserMessageAction, SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import './ListFiles.css';
import { BlobStorageContainer, File } from '../../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../../constants/SearchDefaults';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import { setBrowserTitle } from '../../../services/browser/browser';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { InfoTitles } from '../../../constants/InfoTitles';
import { useActingForShortCode } from '../../../hooks/useActingFor';
import { ActingFor } from '../../../components/common/acting.for/ActingFor';
import { formatLongDateTime } from '../../../services/format/date';
import { SelectInput } from '../../../components/common/select.input/SelectInput';
import { hasPermissions, hasRestrictedFilesAccess, isFileUploadAllowed } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { APIButton } from '../../../components/common/button/APIButton';
import queryString from 'query-string';
import { ReinstateApiTokenTimeoutExpiryAction, SuspendApiTokenTimeoutExpiryAction } from '../../../actions/authAction';
import { FileOrderBy } from '../../../constants/FileOrderBy';
import { DialogModal } from '../../../components/common/dialog.modal/DialogModal';
import { useLocation, useNavigate } from 'react-router-dom';

interface Query {
    file: string | null;
    container: string | null;
    purpose: string | null;
}

export const ListFiles: React.FC<any> = (props) => {
    const [files, setFiles] = useState<File[]>([]);
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, FileSearch>(fileSearchValues);
    const dispatch = useDispatch();
    const customerSkin = useCustomerSkin();
    const customerActingForShortCode = useActingForShortCode();
    const [containers, setContainers] = useState<BlobStorageContainer[]>([]);
    const [totalResults, setTotalResults] = useState(-1);
    const [isMisconfigured, setIsMisconfigured] = useState(false);
    const [queryParams, setQueryParams] = useState<Query>({ file: null, container: null, purpose: null });
    const [showDownloadAllDialog, setShowDownloadAllDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<File>();
    const [currentUserEmail, setCurrentUserEmail] = useState<string>("");
    const hasRestrictedAccess = hasRestrictedFilesAccess();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_list_files');
    }, [customerSkin])

    useEffect(() => {
        const queryParam = queryString.parse(location.search);
        const container = !queryParam.container || Array.isArray(queryParam.container) ? null : queryParam.container;
        const file = !queryParam.file || Array.isArray(queryParam.file) ? null : queryParam.file;
        const purpose = !queryParam.purpose || Array.isArray(queryParam.purpose) ? null : queryParam.purpose;
        if (container || file) {
            setQueryParams({ file: file, container: container, purpose: purpose });
            navigate(createRoute(ApplicationRoutes.LIST_FILES));
        }
    }, [])

    useEffect(() => {
        const getInitialList = async () => {
            if (customerActingForShortCode && containers.length !== 0) {
                try {
                    var queryParamsContainer = !!queryParams.container
                        ? containers.find(container => container.blobStorageName === decodeURIComponent(queryParams.container!))
                        : undefined;

                    await getFiles({ //TODO: the disconnect between this, the `initialFileSearch` in *Reducer.ts, and the `clearSearchFilters` and `initialValues` below
                        ...searchValues,
                        filename: !!queryParams.file ? decodeURIComponent(queryParams.file) : searchValues.filename,
                        container: queryParamsContainer?.uiName ?? (!!searchValues.container ? searchValues.container : getDefaultContainer()),
                        orderBy: searchValues.orderBy ? searchValues.orderBy : FileOrderBy.Default,
                    }, 1);
                    setIsMisconfigured(false);
                    setQueryParams({ file: null, container: null, purpose: null });
                }
                catch {
                    setContainers([]);
                    setIsMisconfigured(true);
                    setFiles([]);
                }
            }
        }
        getInitialList();
    }, [customerActingForShortCode, containers])

    useEffect(() => {
        const getContainers = async () => {
            try {
                dispatch(ClearUserMessageAction());
                await makeJSONPostRequest(createUrl(ApiUrls.CAN_CONNECT_TO_STORAGE, { customerShortCode: customerActingForShortCode }), dispatch, null, false, false);
                const response = await makeJSONPostRequest(createUrl(ApiUrls.GET_CONTAINERS, { customerShortCode: customerActingForShortCode, isForUpload: false }), dispatch, null, false, false);
                setIsMisconfigured(false);
                setContainers(response.body);
            } catch {
                setContainers([]);
                setFiles([]);
                setIsMisconfigured(true);
            }
        }
        if (customerActingForShortCode) {
            getContainers();
        }
    }, [customerActingForShortCode])

    async function getFiles(values: FileSearch, newPage: number, showMessage: boolean = true) {
        dispatch(SetFileSearchAction(values));
        const data = {
            Filename: values.filename ? values.filename : null,
            Container: values.container,
            StartDate: values.startDate ? values.startDate : null,
            EndDate: values.endDate ? values.endDate : null,
            Metadata: values.metadata ? values.metadata : null,
            MyFilesOnly: values.myFilesOnly,
            IncludeOfflineFiles: values.inclOffline,
            CustomerShortCode: customerActingForShortCode,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: values.orderBy,
            OrderByDir: values.orderBy == 'ModDate' ? 'DESC' : 'ASC'
        };
        const response = await makeJSONPostRequest(ApiUrls.SEARCH_FILES, dispatch, data, showMessage, showMessage);

        if (showMessage) {
            if (response.body.result.length === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('file_search_result_none')));
            }
            else if (response.body.result.length === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('file_search_result_one')));
            }
            else if (response.body.result.length < SearchDefaults.TAKE) {
                dispatch(SetUserMessageSuccessAction(getLabel('file_search_result_one_page', { count: response.body.result.length })));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('file_search_result_many_pages', { count: response.body.take })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setCurrentUserEmail(response.body.currentUserEmail);
        setFiles(response.body.result);
        setTotalResults(getTotalResults(response.body.skip, response.body.result.length));
        setPage(newPage);
    }

    const getTotalResults = (skipValue: number, pageSize: number) => {
        if (pageSize == 0) {
            return skipValue + 1;
        }
        if (pageSize < SearchDefaults.TAKE) {
            return skipValue + pageSize;
        }
        return (skipValue + SearchDefaults.TAKE) + 1;
    }

    const getContainerBlobStorageName = (file: File) => {
        const blobStorageContainer = containers.find(container => container.uiName === file.container) as BlobStorageContainer;
        return blobStorageContainer.blobStorageName;
    }

    const copyFileLinkToClipboard = (file: File) => {
        const containerBlobStorageName = getContainerBlobStorageName(file);
        dispatch(ClearUserMessageAction());
        navigator.clipboard.writeText(
            `${window.location.origin}/files/${customerActingForShortCode}?container=${encodeURIComponent(containerBlobStorageName)}&file=${encodeURIComponent(file.name)}`
        ).then(() => {
            dispatch(SetUserMessageSuccessAction(getLabel('file_copy_link_success')));
        });
    };

    const openEditFilePage = (file: File) => {
        const containerBlobStorageName = getContainerBlobStorageName(file);
        navigate(`${createRoute(ApplicationRoutes.EDIT_FILE, {container: containerBlobStorageName})}?filename=${encodeURIComponent(file.name)}`);
    };

    const continueDelete = async () => {
            setShowDeleteDialog(false);
        try {
            const data = {
                FileName: fileToDelete!.name,
                Container: fileToDelete!.container,
                CustomerShortCode: customerActingForShortCode
            }
            dispatch(SuspendApiTokenTimeoutExpiryAction());
            await makeJSONPostRequest(ApiUrls.DELETE_FROM_BLOB_STORAGE, dispatch, data, true, true);
            dispatch(SetUserMessageSuccessAction(getLabel('file_delete_success')));
            await getFiles(searchValues, 1, false);
        }
        finally {
            dispatch(ReinstateApiTokenTimeoutExpiryAction());
        }
    }

    const cancelDelete = () => {
        setShowDeleteDialog(false);
    }

    const deleteFile = (file: File) => {
        setFileToDelete(file);
        setShowDeleteDialog(true);
    }

    const downloadFile = async (file: File) => {
        try {
            const data = {
                FileName: file.name,
                Container: file.container,
                CustomerShortCode: customerActingForShortCode
            }
            dispatch(SuspendApiTokenTimeoutExpiryAction());
            await makePostBlobRequest(ApiUrls.GET_BLOB, dispatch, file.name, data, true, true);
            dispatch(SetUserMessageSuccessAction(getLabel('file_download_success')));
        }
        finally {
            dispatch(ReinstateApiTokenTimeoutExpiryAction());
        }
    }

    const continueDownloadAll = async () => {
        setShowDownloadAllDialog(false);
        var success = 0;
        var downloadErrors: string[] = [];
        await Promise.all(files.map(async file => {
            try {
                const data = {
                    FileName: file.name,
                    Container: file.container,
                    CustomerShortCode: customerActingForShortCode
                }
                dispatch(SuspendApiTokenTimeoutExpiryAction());
                await makePostBlobRequest(ApiUrls.GET_BLOB, dispatch, file.name, data, true, true, false);
                success++;
                return;
            }
            catch (error) {
                downloadErrors.push(`"${file.name}"`);
            }
            finally {
                dispatch(ReinstateApiTokenTimeoutExpiryAction());
                return;
            }
        })).finally(() => {
            if (success === files.length) {
                dispatch(SetUserMessageSuccessAction(getLabel('all_file_download_success', { success: success, total: files.length })));
            }
            else {
                dispatch(SetUserMessageErrorAction(getLabel('all_file_download_failure', { success: success, total: files.length, fileErrors: `${downloadErrors.join(", ")} failed to upload.` })));
            }
        });
    }

    const cancelDownloadAll = () => {
        setShowDownloadAllDialog(false);
    }

    const handleChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getFiles(searchValues, page, false);
    };

    async function clearSearchFilters() {
        dispatch(ClearFileSearchAction());
        await getFiles({ filename: '', container: getDefaultContainer(), startDate: '', endDate: '', metadata: '', orderBy: FileOrderBy.Default, myFilesOnly: false, inclOffline: false }, 1);
    }

    const getInfoTitle = () => {
        return InfoTitles.LIST_FILES;
    };

    const getContainerOptions = (): any => {
        return containers.map((container) =>
            <option key={container.uiName} value={container.uiName}>
                {container.uiName}
            </option>
        );
    };

    const getOrderByOptions = (): any => {
        return FileOrderBy.Options.map((item: any) => {
            return <option key={item.value} value={item.value}>{item.name}</option>;
        });
    }

    const isTrashContainer = (containerName: string): boolean => {
        const container = containers.find(container => container.uiName === containerName);
        return container?.isTrashContainer ?? false;
    }

    const getDefaultContainer = (): string => {
        return containers.filter((container) => (container.isDefaultSearchListFilter))[0].uiName;
    };

    const getVisibleToEmailsForTooltip = (emails: string[]): any => {
        return emails.length === 0 ? "" : 
            emails.map((email) =>
                <div key={email}>
                    {email === currentUserEmail ? <strong>{email}</strong> : <>{email}</>}
                </div>
            );
    };

    const getVisibleToEmailsForColumn = (emails: string[]): any => {
        return emails.length === 0 ? "" :
            emails.map((email) =>
                <React.Fragment key={email}>
                    {email === currentUserEmail ? <strong>{`${email} `}</strong> : <>{`${email} `}</>}
                </React.Fragment>
            );
    };

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="list-files">
                <DialogModal id="downloadAllDialog" title={files.length > 1 ? getLabel("download_all_title", { total: files.length }) : getLabel("download_all_title_single", { total: files.length }) }
                    onClickLeft={cancelDownloadAll} onClickRight={continueDownloadAll} open={showDownloadAllDialog} labelLeft={getLabel("download_dialog_cancel")} labelRight={getLabel("download_dialog_continue")} />
                <DialogModal id="deleteFileDialog" title={getLabel(hasPermissions(Permissions.CAN_PERMANENTLY_DELETE_FILE) && isTrashContainer(searchValues.container) ? "permanently_delete_file_title" : "delete_file_title")}
                    onClickLeft={cancelDelete} onClickRight={continueDelete} open={showDeleteDialog} labelLeft={getLabel("delete_file_dialog_cancel")} labelRight={getLabel("delete_file_dialog_continue")} />
                <div className="file-list-heading">
                    <PageHeading nodes={[{ label: "file_page_heading" }]} />
                    <ActingFor />
                </div>
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ ...searchValues, myFilesOnly: hasRestrictedAccess ? true : searchValues.myFilesOnly } as FileSearch}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                getFiles(values, 1).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="search-filter-fields">
                                        <SelectInput name="container" label="file_filter_folder" blankOption={false} values={getContainerOptions()} />
                                        <TextInput name="startDate" type="date" label="file_filter_startdate" fullwidth={false} shrink={true} />
                                        <TextInput name="endDate" type="date" label="file_filter_enddate" fullwidth={false} shrink={true} />
                                        <div />
                                        <TextInput name="filename" label="file_filter_filename" fullwidth={false} />
                                        <TextInput name="metadata" label="file_filter_metadata" fullwidth={false} />
                                        <SelectInput name="orderBy" label="file_filter_sort_by" blankOption={false} values={getOrderByOptions()} />
                                    </div>
                                    <div className="search-filter-buttons">
                                        {!hasPermissions(Permissions.CAN_VIEW_ALL_FILES) &&
                                            <CheckboxInput name="myFilesOnly" label="file_filter_my_files_only" disabled={hasRestrictedAccess} />}
                                        <CheckboxInput name="inclOffline" label="file_filter_include_offline" />
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                <div>
                    {hasPermissions(Permissions.CAN_UPLOAD_TO_CONTAINER) &&
                        <div className="upload-and-download-all-buttons-row">
                            <div>
                                {isFileUploadAllowed() &&
                                    <Button type="button" className="upload-button" variant="contained" color="primary" onClick={() => { navigate(createRoute(ApplicationRoutes.UPLOAD_FILE)) }} disabled={isMisconfigured}>
                                        {getLabel('upload_file_button')}
                                    </Button>
                                }
                            </div>
                            <Button type="button" className="download-all-button" variant="contained" color="primary" onClick={() => setShowDownloadAllDialog(true)} disabled={isMisconfigured || files.length === 0}>
                                {getLabel('download_all_file_button')}
                            </Button>
                        </div>}
                </div>
                <div>
                    <List id="resultList">
                        {files.map((file: File) =>
                            <Paper key={file.name}><ListItem className="row">
                                <div>
                                    <div>
                                        <div className="filename-and-buttons-row">
                                            {file.offline
                                                ? <div className="filename name inactive-result">{file.name} (offline)</div>
                                                : <div className="filename name">{file.name}</div>}
                                            <div className="file-buttons">
                                                {!hasRestrictedAccess &&
                                                    <APIButton className="button" type="button" variant="contained" color="primary" disabled={file.offline} onClick={() => openEditFilePage(file)}>{getLabel("file_edit_button_text")}</APIButton>}
                                                <APIButton className="button" type="button" variant="contained" color="primary" onClick={() => copyFileLinkToClipboard(file)}>{getLabel("file_copy_link_button_text")}</APIButton>
                                                <APIButton className="button" type="button" variant="contained" color="primary" disabled={file.offline} onClick={() => downloadFile(file)}>{getLabel("file_download_button_text")}</APIButton>
                                                {(!isTrashContainer(searchValues.container) || hasPermissions(Permissions.CAN_PERMANENTLY_DELETE_FILE)) &&
                                                    <APIButton className="button remove-button" type="button" variant="contained" color="primary" onClick={() => deleteFile(file)}>{getLabel("file_delete_button_text")}</APIButton>}
                                            </div>
                                        </div>
                                        <div className="file-attributes grid truncate">
                                            <span><div className="colHeader">{getLabel("file_list_folder_header")}</div><div>{file.container}</div></span>
                                            <span><div className="colHeader">{getLabel("file_list_moddate_header")}</div><div>{formatLongDateTime(file.modDate)}</div></span>
                                            <span><div className="colHeader">{getLabel("file_list_size_header")}</div><div>{getLabel("file_size_kb_value", {size: (file.length/1024).toLocaleString()})}</div></span>
                                            <Tooltip arrow title={getVisibleToEmailsForTooltip(file.visibleTo)}>
                                                <span><div className="colHeader">{getLabel("file_list_visible_to_header")}</div><div className="whitespace-pre">{getVisibleToEmailsForColumn(file.visibleTo)}</div></span>
                                            </Tooltip>
                                            <Tooltip arrow title={file.metadata}>
                                                <span><div className="colHeader">{getLabel("file_list_metadata_header")}</div><div className="whitespace-pre">{file.metadata}</div></span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                        </ListItem></Paper>)}
                    </List>
                    {files.length < SearchDefaults.TAKE && <div className="paging">{getLabel('file_end_of_results')}</div>}
                    {totalResults === 0 && <p className="paging">{getLabel("file_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" page={page} count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} onChange={handleChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
