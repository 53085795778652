export interface UserReportSearch {
    name: string;
    category: string;
    customerShortCode: string;
}

export const SET_USER_REPORT_SEARCH = "SET_USER_REPORT_SEARCH";
export const CLEAR_USER_REPORT_SEARCH = "CLEAR_USER_REPORT_SEARCH";


interface SetUserReportSearch {
    type: typeof SET_USER_REPORT_SEARCH;
    userReportSearch: UserReportSearch;
}

interface ClearUserReportSearch {
    type: typeof CLEAR_USER_REPORT_SEARCH;
}


export type UserReportSearchAction = SetUserReportSearch | ClearUserReportSearch;

export const SetUserReportSearchAction = (name: string, category: string, customerShortCode: string): SetUserReportSearch => ({
    type: SET_USER_REPORT_SEARCH,
    userReportSearch: { name: name, category: category, customerShortCode: customerShortCode }
});

export const ClearUserReportSearchAction = (): ClearUserReportSearch => ({
    type: CLEAR_USER_REPORT_SEARCH
});