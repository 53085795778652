import { CLEAR_LOGIN_REDIRECT, LoginRedirectAction, LoginRedirect, SET_LOGIN_REDIRECT } from "../actions/loginRedirectAction";

const initialState: LoginRedirect = {
    pathname: ''
};

export const loginRedirect = (state: LoginRedirect = initialState, action: LoginRedirectAction): LoginRedirect => {
    switch (action.type) {
        case SET_LOGIN_REDIRECT:
            return { pathname: action.redirect.pathname };
        case CLEAR_LOGIN_REDIRECT:
            return initialState;
        default: {
            return state;
        }
    }
};

export const getLoginRedirectPathname = (state: LoginRedirect) => state.pathname;
