import { useSelector } from "react-redux";
import { Customer } from "../interfaces/ApiInterfaces";
import { customerDropdownValues } from "../reducers/rootReducer";
import { AppState } from "../store/configureStore";

interface CustomerFlags {
    HasStorageAccount: Boolean;
}

export function useCustomerFlags(): CustomerFlags | null {
    const customerValues = useSelector<AppState, Customer[]>(customerDropdownValues);
    const hasStorageAccount = customerValues.some((customer: Customer) => customer.hasStorageAccountName);
    return {
        HasStorageAccount: hasStorageAccount
    }
}