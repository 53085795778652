import { Notifications, NotificationsAction, SET_NOTIFICATIONS } from '../actions/notificationsAction';

const initialState: Notifications = {
    notifications: []
};

export const notifications = (
    state: Notifications = initialState,
    action: NotificationsAction
): Notifications => {
    if (action.type === SET_NOTIFICATIONS) {
        return { notifications: action.notifications };
    }
    return state;
};

export const getNotifications = (state: Notifications) => state.notifications;
