import { AnyAction, combineReducers } from "redux";
import { userMessage, getSeverity, getUserMessage } from "./userMessageReducer";
import { userSearch, getUserSearch, customerSearch, getCustomerSearch, reportSearch, getReportSearch, infoSearch, getInfoSearch, categorySearch, getCategorySearch, userReportSearch, getUserReportSearch, userCategorySearch, getUserCategorySearch, fileSearch, getFileSearch } from "./searchFilterReducer";
import { roleDropdown, getRoleDropdown } from "./roleDropdownReducer";
import { customerDropdownValues as customerDropdownOptions, getCustomerDropdownValues } from "./customerDropdownValuesReducer";
import { customerActingForValue as customerActingForOption, getCustomerActingForValue } from "./customerActingForValueReducer";
import { notifications, getNotifications } from "./notificationsReducer";
import { AppState } from "../store/configureStore";
import { authStatus, getEnableApiTokenTimeoutExpiryStatus, getLoggedOutStatus, getStartInactivityTimerStatus } from "./authReducer";
import { applicationInfo, getApplicationFeatureFlags, getApplicationInactivityTimeoutInMinutes, getApplicationUploadRestrictions, getApplicationVersion } from "./applicationInfoReducer";
import { getReportCategoryDropdown, reportCategoryDropdown } from "./ReportCategoryDropdownReducer";
import { customerSkins, getCustomerSkins } from "./customerSkinsReducer";
import { loginRedirect, getLoginRedirectPathname } from "./loginRedirectReducer";
import { DESTROY_STORE } from "../actions/rootAction";

const appReducer = combineReducers({
    userMessage: userMessage,
    userSearch: userSearch,
    roleDropdown: roleDropdown,
    customerDropdownValues: customerDropdownOptions,
    customerActingForValue: customerActingForOption,
    notifications: notifications,
    authStatus: authStatus,
    customerSearch: customerSearch,
    reportSearch: reportSearch,
    applicationInfo: applicationInfo,
    infoSearch: infoSearch,
    reportCategoryDropdown: reportCategoryDropdown,
    categorySearch: categorySearch,
    userReportSearch: userReportSearch,
    userCategorySearch: userCategorySearch,
    customerSkins: customerSkins,
    loginRedirect: loginRedirect,
    fileSearch: fileSearch
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === DESTROY_STORE) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action);
}

export const userMessageSeverity = (state: AppState) => getSeverity(state.userMessage);
export const userMessageText = (state: AppState) => getUserMessage(state.userMessage);
export const userSearchValues = (state: AppState) => getUserSearch(state.userSearch);
export const roleDropdownValues = (state: AppState) => getRoleDropdown(state.roleDropdown);
export const customerDropdownValues = (state: AppState) => getCustomerDropdownValues(state.customerDropdownValues);
export const customerActingForValue = (state: AppState) => getCustomerActingForValue(state.customerActingForValue);
export const notificationListValues = (state: AppState) => getNotifications(state.notifications);
export const isLoggedOut = (state: AppState) => getLoggedOutStatus(state.authStatus);
export const shouldStartInactivityTimer = (state: AppState) => getStartInactivityTimerStatus(state.authStatus);
export const shouldEnableApiTokenTimeoutExpiry = (state: AppState) => getEnableApiTokenTimeoutExpiryStatus(state.authStatus);
export const customerSearchValues = (state: AppState) => getCustomerSearch(state.customerSearch);
export const reportSearchValues = (state: AppState) => getReportSearch(state.reportSearch);
export const applicationInfoCurrentVersion = (state: AppState) => getApplicationVersion(state.applicationInfo);
export const applicationInfoFeatureFlags = (state: AppState) => getApplicationFeatureFlags(state.applicationInfo);
export const applicationInfoInactivityTimeoutInMinutes = (state: AppState) => getApplicationInactivityTimeoutInMinutes(state.applicationInfo);
export const applicationInfoUploadRestrictions = (state: AppState) => getApplicationUploadRestrictions(state.applicationInfo);
export const infoSearchValues = (state: AppState) => getInfoSearch(state.infoSearch);
export const reportCategoryDropdownValues = (state: AppState) => getReportCategoryDropdown(state.reportCategoryDropdown);
export const categorySearchValues = (state: AppState) => getCategorySearch(state.categorySearch);
export const userReportSearchValues = (state: AppState) => getUserReportSearch(state.userReportSearch);
export const userCategorySearchValues = (state: AppState) => getUserCategorySearch(state.userCategorySearch);
export const customerSkinValues = (state: AppState) => getCustomerSkins(state.customerSkins);
export const loginRedirectPathname = (state: AppState) => getLoginRedirectPathname(state.loginRedirect);
export const fileSearchValues = (state: AppState) => getFileSearch(state.fileSearch);

export default rootReducer;