import React, { useState, useEffect, useRef } from 'react';
import { Badge, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { AppState } from '../../../store/configureStore';
import { notificationListValues } from '../../../reducers/rootReducer';
import { SetNotificationsAction } from '../../../actions/notificationsAction';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import './Notifications.css';
import { getUserCustomerShortCode } from '../../../services/auth/auth';
import { useActingFor } from '../../../hooks/useActingFor';
import { CONTEXT_PATH } from '../../../constants/ApplicationRoutes';

interface Props {
    isAuthenticated: boolean;
}

export const Notifications: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const notifications = useSelector<AppState, any>(notificationListValues);
    const anchorRef = useRef<HTMLDivElement>(null);
    const customerActingFor = useActingFor();

    const onMenuClick = async (e: any, id: number, type: string, link: string) => {
      await makeJSONPostRequest(createUrl(ApiUrls.DISMISS_USER_NOTIFICATION, { notificationId: id }), dispatch, null, false, false);
      const navigationTo = link.startsWith(CONTEXT_PATH) ? link : CONTEXT_PATH + link;
      navigate(navigationTo);
      onClose(e);
    };

    const onClose = (e: any) => {
      if (anchorRef.current && anchorRef.current.contains(e.target)) return;
      setOpen(false);
    };

    const onToggle = () => {
      setOpen((prevOpen) => notifications.length !== 0 && !prevOpen);
    };
  
    function onKeyDown(e: any) {
      if (e.key === 'Tab') {
        e.preventDefault();
        setOpen(false);
      }
    }
  
    useEffect(() => {
        if (!props.isAuthenticated) return;
        const getNotifications = async () => {
            var result = await makeJSONGetRequest(ApiUrls.GET_USER_NOTIFICATIONS, dispatch, null, false, false);
            var body = result.body;
            if (getUserCustomerShortCode() || customerActingFor.shortCode) {
                var customerShortCode = getUserCustomerShortCode() || customerActingFor.shortCode;
                var customerResult = await makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER_NOTIFICATIONS, { customerShortCode }), dispatch, null, false, false);
                body = body.concat(customerResult.body);
            }
            body.sort(function (a:any , b: any) {
                return b.id - a.id;
            });
        dispatch(SetNotificationsAction(body));
        }
        getNotifications();
    }, [customerActingFor]);

    return (
        <>
            <Badge
              className={"notification-badge" + (notifications.length === 0 ? " empty" : "")}
              color="error"
              overlap="circular"
              onClick={onToggle}
              badgeContent={
                <div
                  className="notification-badge-number"
                  ref={anchorRef}
                  aria-controls={isOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true">
                  {notifications.length}
                </div>
              }
            />
            <Popper open={isOpen} placement="bottom-end" anchorEl={anchorRef.current}
              className="notifications-popper" role={undefined} transition disablePortal>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
                  <Paper>
                    <ClickAwayListener onClickAway={onClose}>
                      <MenuList autoFocusItem={isOpen} id="menu-list-grow" onKeyDown={onKeyDown}>
                        {notifications.map((n: any) =>
                          <MenuItem key={n.id} onClick={(e) => onMenuClick(e, n.id, n.type, n.link)}>
                            {n.message}
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
        </>
    );
};
