import { useSelector } from "react-redux";
import { CustomerActingForValue } from "../actions/customerActingForValueAction";
import { customerActingForValue } from "../reducers/rootReducer";
import { AppState } from "../store/configureStore";


export function useActingFor() {
    return useSelector<AppState, CustomerActingForValue>(customerActingForValue);
}

export function useActingForShortCode() {
    const actingFor = useActingFor();
    return actingFor.shortCode;
}