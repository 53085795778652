export interface CategorySearch {
    name: string;
    includeInactive: boolean;
}

export const SET_CATEGORY_SEARCH = "SET_CATEGORY_SEARCH";
export const CLEAR_CATEGORY_SEARCH = "CLEAR_CATEGORY_SEARCH";


interface SetCategorySearch {
    type: typeof SET_CATEGORY_SEARCH;
    categorySearch: CategorySearch;
}

interface ClearCategorySearch {
    type: typeof CLEAR_CATEGORY_SEARCH;
}


export type CategorySearchAction = SetCategorySearch | ClearCategorySearch;

export const SetCategorySearchAction = (name: string, includeInactive: boolean): SetCategorySearch => ({
    type: SET_CATEGORY_SEARCH,
    categorySearch: { name: name, includeInactive: includeInactive}
});

export const ClearCategorySearchAction = (): ClearCategorySearch => ({
    type: CLEAR_CATEGORY_SEARCH
});