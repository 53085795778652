export interface ReportCategoryDropdown {
    categories: any
}

export const SET_REPORT_CATEGORY_DROPDOWN = "SET_REPORT_CATEGORY_DROPDOWN";

interface SetReportCategoryDropdown {
    type: typeof SET_REPORT_CATEGORY_DROPDOWN;
    reportCategoryDropdown: ReportCategoryDropdown;
}

export type ReportCategoryDropdownAction = SetReportCategoryDropdown;

export const SetReportCategoryDropdownAction = (categories: any): SetReportCategoryDropdown => ({
    type: SET_REPORT_CATEGORY_DROPDOWN,
    reportCategoryDropdown: { categories: categories}
});
