import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createRoute, getActiveClass, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { Label } from '../common/label/Label';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { useLocation, useNavigate } from 'react-router-dom';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { useActingFor } from '../../hooks/useActingFor';

interface Props {
    infoTitle: string;
}

export const InfoLink: React.FC<Props> = ({ infoTitle }) => {
    const infoTabName = "info_tab";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const customerSkin = useCustomerSkin();
    const customerActingFor = useActingFor();

    useEffect(() => {
        if (window.name === infoTabName) setBrowserTitle(customerSkin.title, 'title_list_info')
    }, [customerSkin])

    const handleClick = () => {
        if (location.pathname.startsWith(createRoute(ApplicationRoutes.INFO))) {
            navigate(createRoute(ApplicationRoutes.INFO));
            return;
        }
        const data = {
            title: infoTitle
        };
        var routeTo = "";
        makeJSONPostRequest(ApiUrls.GET_INFO_ID_BY_TITLE, dispatch, data, false, false).then((response) => {
            routeTo = createRoute(ApplicationRoutes.VIEW_INFO, { id: response.body, actingForShortCode: customerActingFor.shortCode});
        }, () => {
            routeTo = createRoute(ApplicationRoutes.INFO, { actingForShortCode: customerActingFor.shortCode });
        }).finally(() => {
            if (window.name === infoTabName) {
                navigate(routeTo);
            } else {
                const aTag = document.createElement('a');
                aTag.target = infoTabName;
                aTag.href = routeTo;
                aTag.click();
            }
        });
    }

    return (<a className={getActiveClass(location.pathname, [ApplicationRoutes.INFO])} onClick={handleClick}>
        <Label label="info_nav_heading" />
    </a>);
}