export class TabValue {
    public static readonly USER_ATTRIBUTES = "attributes";
    public static readonly USER_CUSTOMERS = "customers";
    public static readonly USER_REPORTS = "reports";
    public static readonly USER_CATEGORIES = "categories";
    public static readonly CUSTOMER_ATTRIBUTES = "attributes";
    public static readonly CUSTOMER_REPORTS = "reports";
    public static readonly CUSTOMER_ADVANCED = "advanced";
    public static readonly REPORT_ATTRIBUTES = "attributes";
    public static readonly REPORT_CATEGORIES = "categories";
    public static readonly CATEGORY_ATTRIBUTES = "attributes";
}
