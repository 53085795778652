import React, { useState, useEffect} from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Button, List, ListItem, CardContent, Card, Paper, Pagination } from '@mui/material';
import './Users.css';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { Formik } from 'formik';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { SelectInput } from '../../components/common/select.input/SelectInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { ClearUserSearchAction, SetUserSearchAction } from '../../actions/userSearchAction';
import { SetRoleDropdownAction } from '../../actions/roleDropdownAction';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { userSearchValues, roleDropdownValues, customerDropdownValues } from '../../reducers/rootReducer';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { TabValue } from '../../constants/TabValue';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { InfoTitles } from '../../constants/InfoTitles';
import { Customer, UserRoles } from '../../interfaces/ApiInterfaces';
import { StatesDropdown } from '../../components/common/states.dropdown/StatesDropdown';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { setBrowserTitle } from '../../services/browser/browser';
import { useCustomerSkin } from '../../hooks/useCustomerSkin';
import { useNavigate } from 'react-router-dom';

export const Users: React.FC<any> = (props) => {
    const [roles, setRoles] = useState('');
    const [users, setUsers] = useState([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const [emails, setEmails] = useState<string[]>([]);
    const searchValues = useSelector<AppState, { name: string, email: string, city: string, state: string, customer: string, roleCode: string, includeInactive: boolean }>(userSearchValues);
    const roleValues = useSelector<AppState, any>(roleDropdownValues);
    const customerValues = useSelector<AppState, any>(customerDropdownValues);
    const canViewAllUsers = hasPermissions(Permissions.CAN_VIEW_USERS);
    const dispatch = useDispatch();
    const customerSkin = useCustomerSkin();
    const navigate = useNavigate();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_list_users');
    }, [customerSkin]);

    useEffect(() => {
        const getRoles = async () => {
            var result;
            if (!roleValues) {
                result = await makeJSONGetRequest(ApiUrls.GET_ROLES, dispatch, null, false, false);
                dispatch(SetRoleDropdownAction(result));
            }
            else {
                result = roleValues;
            }
            const body = result.body;
            setRoles(body.map((e: any) => {
                return <option key={e.code} value={e.code}>{e.name}</option>;
            }));
        }
        if (!roles) {
            getRoles();
        }
    }, [roles, roleValues, dispatch]);

    useEffect(() => {
        getUsers(searchValues, 1);
    }, []);


    async function getUsers(values: any, newPage: number, showWorkingMessage: boolean = true, showSucessMessage: boolean = true) {
        const data: any = {
            Name: values.name,
            Email: values.email,
            City: values.city,
            State: values.state,
            CustomerShortCode: values.customer,
            RoleCode: values.roleCode,
            IncludeInactive: values.includeInactive,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        if (!data.Email) delete data.Email;
        if (!data.Name) delete data.Name;
        if (!data.CustomerShortCode) delete data.CustomerShortCode;
        if (!data.RoleCode) delete data.RoleCode;
        if (!data.City) delete data.City;
        if (!data.State) delete data.State;
        const response = await makeJSONPostRequest(ApiUrls.GET_USERS, dispatch, data, showWorkingMessage, showWorkingMessage);
        
        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('user_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('user_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('user_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }
        
        setUsers(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
        setEmails(response.body.emails);
    }

    const selectUser = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        var user: any = users.find((user: any) => user.id == index);
        navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: user.id, tab: TabValue.USER_ATTRIBUTES }))
    };

    const createNewUser = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: -1, tab: TabValue.USER_ATTRIBUTES }))
    };

    const handleChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getUsers(searchValues, page, true, false);
    };

    async function performSearch(values: any) {
        dispatch(SetUserSearchAction(values.Name, values.Email, values.City, values.State, values.Customer, values.Role, values.IncludeInactive));
        await getUsers({name: values.Name, email: values.Email, city: values.City, state: values.State, customer: values.Customer, roleCode: values.Role, includeInactive: values.IncludeInactive}, 1);
    }

    async function clearSearchFilters() {
        dispatch(ClearUserSearchAction());
        await getUsers({name: '', email: '', city: '', state: '', customer: '', roleCode: '', includeInactive: false}, 1);
    }

    const copyEmails = async () => {
        await navigator.clipboard.writeText(emails.join());
        dispatch(SetUserMessageSuccessAction(getLabel('user_emails_copied')));
    };

    const getUserRolesString = (roles: UserRoles[]) => {
        if (roleValues) {
            const rolesCurrentUserCanView = roles.filter(r => (roleValues.body as UserRoles[]).some(rv => r.id == rv.id));
            const rolesString = rolesCurrentUserCanView.map(role => role.name).join(', ');
            return rolesString;
        }
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.LIST_USERS}>
            <Container maxWidth={false} className="listUsers">
                <PageHeading nodes={[{label: "user_page_heading"}]} />
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ Name: searchValues.name, Email: searchValues.email, City: searchValues.city, State: searchValues.state, Customer: searchValues.customer, Role: searchValues.roleCode, IncludeInactive: searchValues.includeInactive}}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form  onSubmit={props.handleSubmit}>
                                    <div className="grid">
                                        <TextInput name="Name" label="user_filter_name" fullwidth={ false } />
                                        <TextInput name="Email" label="user_filter_email" fullwidth={ false } />
                                        <TextInput name="City" label="user_filter_city" fullwidth={ false } />
                                        <StatesDropdown name="State" label="user_filter_state" />
                                    </div>
                                    <div className="grid">
                                        {canViewAllUsers && <SelectInput name="Role" label="user_filter_role" values={roles} />}
                                        {canViewAllUsers &&
                                            <SelectInput name="Customer" label="user_filter_customer" values={customerValues.map((customer: Customer) =>
                                                <option key={customer.shortCode} value={customer.shortCode}>
                                                    {customer.name}
                                                </option>
                                            )} />
                                        }
                                        <div/>
                                        <div/>
                                    </div>
                                    <div className="flexRow">
                                        <span className="flexFill"></span>
                                        <CheckboxInput name="IncludeInactive" label="user_filter_include_inactive"/>
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                <div className="flex userButtons">
                    {hasPermissions(Permissions.CAN_CREATE_USER) && <Button id="addButton" variant="contained" color="primary" onClick={createNewUser}>{getLabel('user_add_label')}</Button>}
                    <Button className="button copyEmailButton" variant="contained" color="primary" onClick={copyEmails}>{getLabel('user_copy_email_label')}</Button>
                </div>
                <div>
                    <List id="resultList">
                        {users.map((user: { id: number; name: string; email: string; roles: UserRoles[]; customers: Customer[]; isActive: boolean; city: string; state: string; }) => <Paper key={user.id}> <ListItem className="row" button onClick={(event) => selectUser(event, user.id)}>
                            {user.isActive && <div className="name">{user.name}</div>}
                            {!user.isActive && <div className="name inactive-result">{getLabel('inactive_name', { name: user.name })}</div>}
                            <div className="grid  user-grid truncate">
                                <span className="userCol"><div className="colHeader">{getLabel("user_list_email_header")}</div><div>{user.email}</div></span>
                                <span className="userCol"><div className="colHeader">{getLabel("user_list_city_header")}</div><div>{user.city}</div></span>
                                <span className="userCol"><div className="colHeader">{getLabel("user_list_state_header")}</div><div>{user.state}</div></span>
                                <span className="userCol"><div className="colHeader">{getLabel("user_list_roles_header")}</div><div>{getUserRolesString(user.roles)}</div></span>
                                <span className="userCol">
                                    {
                                        user.customers && user.customers.length > 0 &&
                                        <>
                                            <div className="colHeader">{getLabel("user_list_customer_header")}</div>
                                            {user.customers
                                                .sort((a: Customer, b: Customer) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)
                                                .map((customer: Customer) => <div key={customer.shortCode}>{customer.name}</div>)
                                            }
                                        </>
                                    }
                                </span>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("user_search_result_none")}</p>}
                    {totalResults > 0 && <Pagination className="paging" count={totalResults > 0 ? Math.ceil(totalResults / SearchDefaults.TAKE) : 0} page={page} onChange={handleChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    );
};
