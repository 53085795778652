import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import './DialogModal.css';

interface Props {
    open: boolean;
    onClickLeft: any;
    onClickRight: any;
    labelLeft: string;
    labelRight: string;
    id: string;
    title: string;
}

export const DialogModal: React.FC<Props> = ({ open, onClickLeft, onClickRight, labelLeft, labelRight, id, title }) => {

    return (
        <Dialog open={open} id={id}>
            <DialogTitle id="dialog-title">{title}</DialogTitle>
            <DialogActions id="dialog-buttons">
                <Button className="left-button button" variant="contained" color="primary" onClick={onClickLeft}>{ labelLeft }</Button>
                <Button className="right-button button" variant="contained" color="primary" onClick={onClickRight}>{ labelRight }</Button>
            </DialogActions>
        </Dialog>
    );
};
