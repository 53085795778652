export class PasswordRules { //informational only, server's appsettings is what really matters
    public static readonly MinPasswordLength = 8;
    public static readonly MinCharacterTypes = 3;
    public static readonly CharacterTypes = [
        ['Lowercase:', 'a - z'],
        ['Uppercase:', 'A - Z'],
        ['Numbers:', '0 - 9'],
        ['Symbols:', '@ # $ % ^ & * _ ! + = [ ] { } | \\ : \' , . ? / ` ~ ( ) ; \" -']
    ];
    public static readonly DisallowedCharacters = [
        'Spaces',
        'Other, non-English alphabet letters'
    ];
}