import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent, List, ListItem, Paper } from '@mui/material';
import { Customer, User } from '../../../interfaces/ApiInterfaces';
import { getLabel } from '../../common/label/Label.library';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls } from '../../../constants/ApiUrls';
import { SetUserMessageSuccessAction } from '../../../actions/userMessageAction';

interface Props {
    user: User;
}

export const ViewUserCustomers: React.FC<Props> = ({ user }) => {
    const [userCustomers, setUserCustomers] = useState<Customer[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const getCustomers = async () => {
            const response = await makeJSONGetRequest(ApiUrls.GET_ALL_CUSTOMERS, dispatch, null, true, true);
            var activeCustomers = response.body.filter((c: any) => c.isActive === true);
            var userActiveCustomers = activeCustomers.filter((c: any) => user.customerShortCodes.includes(c.shortCode));
            setUserCustomers(userActiveCustomers);

            var totalCount = userActiveCustomers.length;
            if (totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_none')));
            }
            else if (totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('customer_search_result_many', {
                    totalCount: totalCount,
                })));
            }
        }
        getCustomers();
    }, [user.id]);

    return (
        <Card>
            <CardContent className="user-customers">
                <List>
                    {userCustomers.map((customer: Customer) =>
                        <Paper key={customer.shortCode} elevation={2}>
                            <ListItem className="row">
                                <div className="user-customer-row truncate">
                                    <div>
                                        <div className="name">{customer.name}</div>
                                        <div className="user-customer-row-data truncate">
                                            <span><div className="colHeader">{getLabel("customer_list_address_header")}</div><div>{customer.address}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_city_header")}</div><div>{customer.city}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_state_header")}</div><div>{customer.state}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_zip_code_header")}</div><div>{customer.zipCode}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_telephone_header")}</div><div>{customer.telephone}</div></span>
                                            <span><div className="colHeader">{getLabel("customer_list_email_header")}</div><div>{customer.email}</div></span>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                            </ListItem>
                        </Paper>
                    )}
                </List>
                {userCustomers.length === 0 && <p className="paging">{getLabel("user_customer_search_result_none")}</p>}
            </CardContent>
        </Card>
        );
}