import { createTheme, Theme } from "@mui/material";
import { CustomerSkinDefaults } from "../../constants/CustomerSkinDefaults";

export function getTheme (primaryMainColor: string, primaryContrastTextColor: string): Theme {
  return createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: { fontFamily: 'var(--font-roboto-bold)' }
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '*, *::before, *::after': {
            transition: 'none !important',
            animation: 'none !important',
          },
        },
      },
    },
    palette: {
      primary: {
        main: primaryMainColor,
        contrastText: primaryContrastTextColor,
      },
      secondary: {
        main: CustomerSkinDefaults.THEME_SECONDARY_COLOR
      }
    },
  });
}
