import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Severity,
  ClearUserMessageAction,
} from '../../actions/userMessageAction';
import { AppState } from '../../store/configureStore';
import {
  userMessageSeverity,
  userMessageText,
} from '../../reducers/rootReducer';
import { Alert, Snackbar } from '@mui/material';
import { getLabel } from '../common/label/Label.library';
import CheckIcon from '@mui/icons-material/Check';
import WaitingIcon from '@mui/icons-material/Autorenew';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import './UserMessagePanel.css';

export const UserMessagePanel: React.FC = () => {
  const dispatch = useDispatch();
  const severity = useSelector<AppState, Severity>(userMessageSeverity);
  const message = useSelector<AppState, string>(userMessageText);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  return (
    <div className="user-message-panel">
      {message && (
        <Snackbar
          className="snackbar"
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            severity={severity}
            variant="filled"
            iconMapping={{
              success: <CheckIcon fontSize="inherit" />,
              info: <WaitingIcon fontSize="inherit" />,
              error: <ErrorIcon fontSize="inherit" />,
            }}
            onClose={() => {
              dispatch(ClearUserMessageAction());
            }}
          >
            {getLabel(message)}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};
