import React from 'react';
import { Header } from '../../header/Header';
import { Footer } from '../../footer/Footer';
import { UserMessagePanel } from '../../user.message.panel/UserMessagePanel';
import './UnauthenticatedLayout.css';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';

export const UnauthenticatedLayout: React.FC<any> = (props) => {
  const customerSkin = useCustomerSkin();
  
  return (
    <div className={`unauthenticated-layout ${customerSkin.themeCssClassName}`}>
      <Header {...props} />
      <div className="page-content">
        <UserMessagePanel />
        <div className="registration-content">{props.children}</div>
        <Footer />
      </div>
    </div>
  );
};
