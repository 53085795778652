import React, { useState } from 'react';
import { Card, CardContent, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { getLabel } from '../../common/label/Label.library';
import { TextInput } from '../../common/text.input/TextInput';
import { StatesDropdown } from '../../common/states.dropdown/StatesDropdown';
import { CheckboxInput } from '../../common/checkbox.input/CheckboxInput';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { makeJSONPostRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import './CustomerAttributes.css';
import { SetCustomerDropdownValuesAction } from '../../../actions/customerDropdownValuesAction';
import { Customer } from '../../../interfaces/ApiInterfaces';
import { clearUserCustomer } from '../../../services/auth/auth';
import { TextFieldLengths } from '../../../constants/TextFieldLengths';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { DialogModal } from '../../common/dialog.modal/DialogModal';
import moment from 'moment';
import { Permissions } from '../../../constants/Permissions';
import { hasPermissions } from '../../../services/auth/auth';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isValidEmail, isValidPhone } from '../../../services/validate/validate';
import { formatEditDate } from '../../../services/format/date';

interface Props {
    customer: Customer
}

export const CustomerAttributes: React.FC<Props> = ({ customer }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const featureFlags = useFeatureFlags();
    const [openDialog, setOpenDialog] = useState(false);
    const [customerValues, setCustomerValues] = useState<any>();
    const isAdd = !customer.shortCode;

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (isEmpty(values.name)) {
            errors.name = getLabel('validation_message_required');
        }
        if (isEmpty(values.contactName)) {
            errors.contactName = getLabel('validation_message_required');
        }
        if (isEmpty(values.email)) {
            errors.email = getLabel('validation_message_required');
        } else if (!isValidEmail(values.email)) {
            errors.email = getLabel('validation_message_email');
        }
        if (isEmpty(values.telephone)) {
            errors.telephone = getLabel('validation_message_required');
        } else if (!isValidPhone(values.telephone)) {
            errors.telephone = getLabel('validation_message_telephone');
        }
        if (isEmpty(values.streetAddress)) {
            errors.streetAddress = getLabel('validation_message_required');
        }
        if (isEmpty(values.city)) {
            errors.city = getLabel('validation_message_required');
        }
        if (isEmpty(values.state)) {
            errors.state = getLabel('validation_message_required');
        }
        if (isEmpty(values.zip)) {
            errors.zip = getLabel('validation_message_required');
        }
        if (isAdd && isEmpty(values.shortCode)) {
            errors.shortCode = getLabel('validation_message_required');
        }
        if (isAdd && !isEmpty(values.shortCode) && (
            values.shortCode.length < TextFieldLengths.SHORT_CODE_MIN_LENGTH ||
            values.shortCode.length > TextFieldLengths.SHORT_CODE_MAX_LENGTH ||
            !values.shortCode.match(/^(?![0-9]+$)[A-Z0-9]+$/gm))
        ) {
            errors.shortCode = getLabel('validation_message_shortcode_length', {
                minLength: TextFieldLengths.SHORT_CODE_MIN_LENGTH,
                maxLength: TextFieldLengths.SHORT_CODE_MAX_LENGTH
            });
        }
        if (featureFlags?.ServiceDirectoryCustomers && isEmpty(values.startDate)) {
            errors.startDate = getLabel('validation_message_required');
        }
        if (featureFlags?.ServiceDirectoryCustomers && isEmpty(values.endDate)) {
            errors.endDate = getLabel('validation_message_required');
        }
        if (featureFlags?.ServiceDirectoryCustomers && !isEmpty(values.startDate) && !isEmpty(values.endDate)) {
            if (moment(values.startDate).isSameOrAfter(moment(values.endDate))) {
                errors.startDate = getLabel('validation_message_end_after_start');
                errors.endDate = getLabel('validation_message_end_after_start');
            }
        }
        return errors;
    };

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.CUSTOMERS));
    };

    const saveCustomer = async (values: any) => {
        if (isAdd) {
            setCustomerValues(values);
            setOpenDialog(true);
        }
        else {
            completeSaveCustomer(values);
        }
    }

    const completeSaveCustomer = async (values: any) => {
        const data = {
            Name: values.name,
            ContactName: values.contactName,
            Address: values.streetAddress,
            City: values.city,
            State: values.state,
            ZipCode: values.zip,
            Telephone: values.telephone,
            Extension: values.extension,
            Email: values.email,
            ShortCode: values.shortCode,
            ForDemoOnly: values.forDemoOnly,
            HasCoreProduct: values.hasCoreProduct,
            IsActive: values.isActive,
            StartDate: values.startDate ? values.startDate : null,
            EndDate: values.endDate ? values.endDate : null
        };
        try {
            const response = isAdd
                ? await makeJSONPostRequest(ApiUrls.CREATE_CUSTOMER, dispatch, data)
                : await makeJSONPostRequest(createUrl(ApiUrls.EDIT_CUSTOMER, { customerShortCode: customer.shortCode }), dispatch, data);

            if (response.response.ok) {
                dispatch(SetCustomerDropdownValuesAction([] as Customer[]));
                dispatch(SetUserMessageSuccessAction(isAdd ? 'customer_attributes_success_add_text' : 'customer_attributes_success_edit_text'));
                if (isAdd) {
                    /* After adding customer user may have gone from 1 customer association to 2
                     * so need to clear UserCustomer data from local storage to be able to see acting for without logging out */
                    clearUserCustomer();
                }
                navigate(createRoute(ApplicationRoutes.CUSTOMERS));
            }
        } catch (error: any) {
            if (error.status === 401) {
                dispatch(SetUserMessageErrorAction('customer_failed_save_unauthorized'));
            }
        }
    };

    const continueSubmit = () => {
        setOpenDialog(false);
        completeSaveCustomer(customerValues);
    }

    const cancelSubmit = () => {
        setOpenDialog(false);
    }

    return (
        <Card>
            <DialogModal id="confirmDialog" title={getLabel("customer_confirm_dialog_title")} onClickLeft={cancelSubmit} onClickRight={continueSubmit} open={openDialog} labelLeft={getLabel("customer_dialog_cancel_submit")} labelRight={getLabel("customer_dialog_complete_submit")} />
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        name: customer.name ?? '',
                        contactName: customer.contactName ?? '',
                        email: customer.email ?? '',
                        telephone: customer.telephone ?? '',
                        extension: customer.extension ?? '',
                        streetAddress: customer.address ?? '',
                        city: customer.city ?? '',
                        state: customer.state ?? '',
                        zip: customer.zipCode ?? '',
                        shortCode: customer.shortCode ?? '',
                        forDemoOnly: customer.forDemoOnly ?? false,
                        hasCoreProduct: customer.hasCoreProduct ?? false,
                        isActive: customer.isActive ?? true,
                        startDate: customer.startDate ? formatEditDate(customer.startDate) : '',
                        endDate: customer.endDate ? formatEditDate(customer.endDate) : ''
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveCustomer(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}
                >
                    {(props) => (
                        <form className="edit-customer-attributes" onSubmit={props.handleSubmit}>
                            <div className="edit-customer-attributes-fields grid">
                                <div>
                                    {isAdd &&
                                        <TextInput name="shortCode" label="customer_attributes_short_code_label" maxLength={TextFieldLengths.SHORT_CODE_MAX_LENGTH} fullwidth={false} />}
                                    {!isAdd &&
                                        <span className="customerLabel"><div className="colHeader">{getLabel('customer_attributes_short_code_label')}</div><div>{customer.shortCode}</div></span>}
                                    <TextInput name="name" label="customer_attributes_name_label" fullwidth={false} />
                                    {featureFlags?.ServiceDirectoryCustomers &&
                                        <span className="half-width grid">
                                            <TextInput name="startDate" label="customer_attributes_startDate_label" type="date" fullwidth={false} shrink={true} />
                                            <TextInput name="endDate" label="customer_attributes_endDate_label" type="date" fullwidth={false} shrink={true} />
                                        </span>}
                                </div>
                                <div>
                                    <TextInput name="streetAddress" label="customer_attributes_address_label" fullwidth={false} />
                                    <TextInput name="city" label="customer_attributes_city_label" fullwidth={false} />
                                    <span className="half-width grid">
                                        <StatesDropdown name="state" label="customer_attributes_state_label" halfWidth={true} />
                                        <TextInput name="zip" label="customer_attributes_zip_label" fullwidth={false} />
                                    </span>
                                </div>
                                <div>
                                    <TextInput name="contactName" label="customer_attributes_contact_name_label" fullwidth={false} />
                                    <TextInput name="email" label="customer_attributes_email_label" fullwidth={false} />
                                    <span className="half-width grid">
                                        <TextInput name="telephone" label="customer_attributes_telephone_label" fullwidth={false} />
                                        <TextInput name="extension" label="customer_attributes_extension_label" type="digit" maxLength={TextFieldLengths.PHONE_EXTENSION_MAX_LENGTH} fullwidth={false} />
                                    </span>
                                </div>
                            </div>
                            <div className="edit-customer-attributes-buttons">
                                {featureFlags?.ServiceDirectoryCustomers && hasPermissions(Permissions.CAN_VIEW_PRIVILEGED_ATTRIBUTES) && <CheckboxInput name="hasCoreProduct" label="customer_attributes_has_core_product_label" />}
                                <CheckboxInput name="forDemoOnly" label="customer_attributes_for_demonstration_only_label" />
                                {!featureFlags?.ServiceDirectoryCustomers && <CheckboxInput name="isActive" label="customer_attributes_active_label" />}
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>
                                    {getLabel('customer_attributes_cancel_button_label')}
                                </Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={!props.dirty || props.isSubmitting}>
                                    {getLabel('customer_attributes_submit_button_label')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};
