import {
    UserSearch, UserSearchAction, SET_USER_SEARCH, CLEAR_USER_SEARCH
} from '../actions/userSearchAction';
import {
    CLEAR_CUSTOMER_SEARCH,
    CustomerSearch, CustomerSearchAction, SET_CUSTOMER_SEARCH
} from '../actions/customerSearchAction';
import {
    CLEAR_REPORT_SEARCH,
    ReportSearch, ReportSearchAction, SET_REPORT_SEARCH
} from '../actions/reportSearchAction';

import { CLEAR_INFO_SEARCH, InfoSearch, InfoSearchAction, SET_INFO_SEARCH } from '../actions/infoSearchAction';
import { CategorySearch, CategorySearchAction, CLEAR_CATEGORY_SEARCH, SET_CATEGORY_SEARCH } from '../actions/categorySearchAction';
import { UserReportSearch, UserReportSearchAction, CLEAR_USER_REPORT_SEARCH, SET_USER_REPORT_SEARCH } from '../actions/userReportSearchAction';
import { UserCategorySearch, UserCategorySearchAction, CLEAR_USER_CATEGORY_SEARCH, SET_USER_CATEGORY_SEARCH } from '../actions/userCategorySearchAction';
import { FileSearch, FileSearchAction, CLEAR_FILE_SEARCH, SET_FILE_SEARCH } from '../actions/fileSearchAction';


const initialUserState: UserSearch = {
    name: '',
    email: '',
    city: '',
    state: '',
    customer: '',
    roleCode: '',
    includeInactive: false
};

const initialCustomerState: CustomerSearch = {
    name: '',
    city: '',
    state: '',
    includeDemo: false,
    includeInactive: false
};

const initialReportState: ReportSearch = {
    name: '',
    category: ''
};

const initialInfoSearch: InfoSearch = {
    search: ''
}

const initialCategoryState: CategorySearch = {
    name: '',
    includeInactive: false
};

const initialUserReportSearch: UserReportSearch = {
    name: '',
    category: '',
    customerShortCode: ''
};

const initialUserCategorySearch: UserCategorySearch = {
    name: ''
}

const initialFileSearch: FileSearch = {
    filename: '',
    container: '',
    startDate: '',
    endDate: '',
    metadata: '',
    orderBy: '',
    myFilesOnly: false,
    inclOffline: false
}

export const userSearch = (
    state: UserSearch = initialUserState,
    action: UserSearchAction
): UserSearch => {
    if (action.type === SET_USER_SEARCH) {
        return { name: action.userSearch.name, email: action.userSearch.email, city: action.userSearch.city, state: action.userSearch.state, customer: action.userSearch.customer, roleCode: action.userSearch.roleCode, includeInactive: action.userSearch.includeInactive };
    } else if (action.type === CLEAR_USER_SEARCH) {
        return initialUserState;
    }
    return state;

};

export const customerSearch = (
    state: CustomerSearch = initialCustomerState,
    action: CustomerSearchAction
): CustomerSearch => {
    if (action.type === SET_CUSTOMER_SEARCH) {
        return {name: action.customerSearch.name, city: action.customerSearch.city, state: action.customerSearch.state, includeDemo: action.customerSearch.includeDemo, includeInactive: action.customerSearch.includeInactive };
    } else if (action.type === CLEAR_CUSTOMER_SEARCH) {
        return initialCustomerState;
    }
    return state;
}

export const reportSearch = (
    state: ReportSearch = initialReportState,
    action: ReportSearchAction
): ReportSearch => {
    if (action.type === SET_REPORT_SEARCH) {
        return { name: action.reportSearch.name, category: action.reportSearch.category };
    } else if (action.type === CLEAR_REPORT_SEARCH) {
        return initialReportState;
    }
    return state;
}

export const infoSearch = (
    state: InfoSearch = initialInfoSearch,
    action: InfoSearchAction
): InfoSearch => {
    if (action.type === SET_INFO_SEARCH) {
        return { search: action.infoSearch.search };
    } else if (action.type === CLEAR_INFO_SEARCH) {
        return initialInfoSearch;
    }
    return state;
}

export const categorySearch = (
    state: CategorySearch = initialCategoryState,
    action: CategorySearchAction
): CategorySearch => {
    if (action.type === SET_CATEGORY_SEARCH) {
        return { name: action.categorySearch.name, includeInactive: action.categorySearch.includeInactive};
    } else if (action.type === CLEAR_CATEGORY_SEARCH) {
        return initialCategoryState;
    }
    return state;
}

export const userReportSearch = (
    state: UserReportSearch = initialUserReportSearch,
    action: UserReportSearchAction
): UserReportSearch => {
    if (action.type === SET_USER_REPORT_SEARCH) {
        return { name: action.userReportSearch.name, category: action.userReportSearch.category, customerShortCode: action.userReportSearch.customerShortCode };
    } else if (action.type === CLEAR_USER_REPORT_SEARCH) {
        return initialUserReportSearch;
    }
    return state;
}

export const userCategorySearch = (
    state: UserCategorySearch = initialUserCategorySearch,
    action: UserCategorySearchAction
): UserCategorySearch => {
    if (action.type === SET_USER_CATEGORY_SEARCH) {
        return { name: action.userCategorySearch.name };
    } else if (action.type === CLEAR_USER_CATEGORY_SEARCH) {
        return initialUserCategorySearch;
    }
    return state;
}

export const fileSearch = (
    state: FileSearch = initialFileSearch,
    action: FileSearchAction
): FileSearch => {
    if (action.type === SET_FILE_SEARCH) {
        return { ...action.fileSearch };
    } else if (action.type === CLEAR_FILE_SEARCH) {
        return initialFileSearch;
    }
    return state;
}

export const getUserSearch = (state: UserSearch) => state;
export const getCustomerSearch = (state: CustomerSearch) => state;
export const getReportSearch = (state: ReportSearch) => state;
export const getInfoSearch = (state: InfoSearch) => state;
export const getCategorySearch = (state: CategorySearch) => state;
export const getUserReportSearch = (state: UserReportSearch) => state;
export const getUserCategorySearch = (state: UserCategorySearch) => state;
export const getFileSearch = (state: FileSearch) => state;