export interface InfoSearch {
    search: string;
}
export const SET_INFO_SEARCH = "SET_INFO_SEARCH";
export const CLEAR_INFO_SEARCH = "CLEAR_INFO_SEARCH";
interface SetInfoSearch {
    type: typeof SET_INFO_SEARCH;
    infoSearch: InfoSearch;
}
interface ClearInfoSearch {
    type: typeof CLEAR_INFO_SEARCH;
}
export type InfoSearchAction = SetInfoSearch | ClearInfoSearch;
export const SetInfoSearchAction = (search: string): SetInfoSearch => ({
    type: SET_INFO_SEARCH,
    infoSearch: { search: search }
});
export const ClearInfoSearchAction = (): ClearInfoSearch => ({
    type: CLEAR_INFO_SEARCH
});
