import React from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { getLabel } from '../../../components/common/label/Label.library';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import { customerDropdownValues } from '../../../reducers/rootReducer';
import { SetCustomerActingForValueAction } from '../../../actions/customerActingForValueAction';
import './ActingFor.css';
import { getUserCustomerName, hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { useActingFor } from '../../../hooks/useActingFor';
import { Customer } from '../../../interfaces/ApiInterfaces';

interface Props {
    onChange?: any;
}

export const ActingFor: React.FC<Props> = ({ onChange }) => {
    const dispatch = useDispatch();
    const customerValues = useSelector<AppState, any>(customerDropdownValues);
    const customerActingFor = useActingFor();
    const canActForAllCustomers = hasPermissions(Permissions.CAN_ACT_FOR_CUSTOMER);
    const canActForOnlyMyCustomers = hasPermissions(Permissions.CAN_ACT_FOR_ONLY_MY_CUSTOMERS);

    return (getUserCustomerName() || (!canActForAllCustomers && !canActForOnlyMyCustomers)) ? <></> : (
        <>
            <span className="acting-for-label">{getLabel("acting_for_label")}</span>
            <FormControl variant="outlined" className="acting-for-form" size="small">
                <InputLabel id="label" htmlFor="Customer">
                    {getLabel("acting_for_customer_label")}
                </InputLabel>
                <Select
                    native
                    labelId="label"
                    label={getLabel("acting_for_customer_label")}
                    value={customerActingFor.shortCode}
                    onChange={(event: SelectChangeEvent<unknown>) => {
                        var shortCode = (event.target.value as string);
                        var name = customerValues.find((customer: Customer) => customer.shortCode === shortCode)?.name ?? "";
                        dispatch(SetCustomerActingForValueAction(shortCode, name));
                        onChange?.(shortCode, name);
                    }}
                    id="Customer"
                    name="Customer">
                    {customerValues.map((c: Customer) =>
                        <option key={c.shortCode} value={c.shortCode}>
                            {c.name}
                        </option>
                    )}
                </Select>
            </FormControl>
        </>
    );
};
