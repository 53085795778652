import English from '../../../resources/data/en.json';

const labelValues: { [key: string]: any } = { ...English };

export function getLabel(key: string, params?: { [key: string]: any }): string {
  let label: string = labelValues[key];

  if (!label) {
    return key;
  }

  if (!params) {
    return label;
  }

  Object.keys(params).forEach((paramKey) => {
    label = label.replace('${' + paramKey + '}', params[paramKey]);
  });
  return label;
}
