import React from "react";
import { User } from "../../interfaces/ApiInterfaces";
import { formatShortDateTime } from "../../services/format/date";
import { getLabel } from "../common/label/Label.library";

export const getAllowLoginLabel = (user: User, method: 'Pwd' | 'Sso') => {
    let mainLabel = getLabel(`user_attributes_allow${method}Login_label`);
    let lastUsedDate = user[`last${method}Login`];
    let statusLabel = lastUsedDate == null ? ''
        : getLabel('user_attributes_allowLogin_lastUsed', { datetime: formatShortDateTime(lastUsedDate) });
    return (<span>{mainLabel} <span className='last-login-status'>{statusLabel}</span></span>);
}

