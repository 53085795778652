import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import { useDispatch } from "react-redux";
import { ApiUrls, createUrl } from "../../constants/ApiUrls";
import { makeJSONGetRequest } from "../../services/ajax/ajax";
import { SetCustomerSkinsAction } from "../../actions/customerSkinsAction";
import { getTheme } from "./Theme.library";
import { useCustomerSkin, useCustomerSkins } from "../../hooks/useCustomerSkin";

export const Theme: React.FC<any> = (props) => {
    const customerSkins = useCustomerSkins();
    const customerSkin = useCustomerSkin();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!customerSkins) {
            const getCustomerSkins = async () => {
                const customerSkins = await makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER_SKINS_BY_HOSTNAME, { hostname: window.location.hostname }), dispatch, null, false, false);
                dispatch(SetCustomerSkinsAction(customerSkins.body.customerSkins));
            }
            getCustomerSkins();
        }
    }, [dispatch, customerSkins]);

    return (!customerSkin ? <></>
        : <ThemeProvider theme={getTheme(customerSkin.themePrimaryMainColor, customerSkin.themePrimaryContrastTextColor)}>
            {props.children}
        </ThemeProvider>
    );
};