import { ReportCategoryDropdown, ReportCategoryDropdownAction, SET_REPORT_CATEGORY_DROPDOWN } from "../actions/ReportCategoryDropdownAction";

const initialState: ReportCategoryDropdown = { categories: [] };

export const reportCategoryDropdown = (
    state: ReportCategoryDropdown = initialState,
    action: ReportCategoryDropdownAction
): ReportCategoryDropdown => {
    if (action.type === SET_REPORT_CATEGORY_DROPDOWN) {
        return { categories: action.reportCategoryDropdown.categories};
    }
    return state;

};

export const getReportCategoryDropdown = (state: ReportCategoryDropdown) => state.categories;