import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { Container, Card, AppBar, Tabs, Tab } from '@mui/material';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { CustomerAttributes } from '../../../components/customers/customer.info/CustomerAttributes';
import { ViewCustomerAttributes } from '../../../components/customers/customer.info/ViewCustomerAttributes';
import './EditCustomer.css';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { TabValue } from '../../../constants/TabValue';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { hasPermissions } from '../../../services/auth/auth';
import { Permissions } from '../../../constants/Permissions';
import { Customer, EmptyCustomer } from '../../../interfaces/ApiInterfaces';
import { CustomerReports } from '../../../components/customers/customer.reports/CustomerReports';
import { CustomerAdvanced } from '../../../components/customers/customer.advanced/CustomerAdvanced';
import { InfoTitles } from '../../../constants/InfoTitles';
import { HeadingNode, PageHeading } from '../../../components/common/page.heading/PageHeading';
import { setBrowserTitle } from '../../../services/browser/browser';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { useNavigate, useParams } from 'react-router-dom';

export const EditCustomer: React.FC<any> = (props) => {
    const dispatch = useDispatch();

    const [tabValue, setTabValue] = useState(0);
    const params = useParams();
    const customerShortCode = !!params.shortCode && !!params.shortCode.trim() ? params.shortCode : '';
    const [customer, updateCustomer] = useState<Customer>(EmptyCustomer);
    const customerSkin = useCustomerSkin();
    const reportsEnabled = customer.hasReportEnv;
    const canViewAdvancedTab = hasPermissions(Permissions.CAN_VIEW_PRIVILEGED_ATTRIBUTES);
    const navigate = useNavigate();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, !customerShortCode ? 'title_add_customer' : 'title_edit_customer');
    }, [customerSkin]);

    useEffect(() => {
        if (customerShortCode) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER, { customerShortCode }), dispatch, null, false, false).then((customerResponse) => {
                updateCustomer(customerResponse.body);
            });
        }
    }, [customerShortCode, dispatch]);

    useEffect(() => {
        if (params.tab === TabValue.CUSTOMER_ATTRIBUTES) {
            setTabValue(0);
        } else if (params.tab === TabValue.CUSTOMER_ADVANCED && canViewAdvancedTab) {
            setTabValue(1);
        } else if (params.tab === TabValue.CUSTOMER_REPORTS && reportsEnabled) {
            setTabValue(2);
        } else {
            navigate(createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: customerShortCode, tab: TabValue.CUSTOMER_ATTRIBUTES }))
            setTabValue(0);
        }
    }, [customerShortCode, params.tab])

    function getAddEditLabel() {
        return !customerShortCode
            ? getLabel('customer_add_page_heading')
            : getLabel('customer_edit_page_heading', { displayName: customer.name });
    }

    function getPageHeadingNodes(): HeadingNode[] {
        var nodes: HeadingNode[] = [];
        nodes.push({label: "customer_page_heading", link: createRoute(ApplicationRoutes.CUSTOMERS)});
        nodes.push({label: getAddEditLabel()});
        return nodes;
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: customerShortCode, tab: TabValue.CUSTOMER_ATTRIBUTES }))
        }
        if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: customerShortCode, tab: TabValue.CUSTOMER_ADVANCED }))
        }
        if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: customerShortCode, tab: TabValue.CUSTOMER_REPORTS }))
        }
        setTabValue(value);
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_CUSTOMER;
        }
        if (tabValue === 1) {
            return InfoTitles.CUSTOMER_ADVANCED;
        }
        if (tabValue === 2) {
            return InfoTitles.CUSTOMER_REPORTS;
        }
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="edit-customer-container">
                <PageHeading nodes={getPageHeadingNodes()} />
                <Card className="edit-customer-panel">
                    <AppBar position="static" color="default">
                        <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary"
                            textColor="primary">
                            <Tab value={0} label={getLabel('tab_label_attributes')} />
                            {canViewAdvancedTab && <Tab value={1} label={getLabel('tab_label_advanced')} disabled={!customerShortCode} />}
                            {hasPermissions(Permissions.CAN_VIEW_REPORT_PARAMETERS) && <Tab value={2} label={getLabel('tab_label_reports')} disabled={!customerShortCode || !reportsEnabled} />}
                        </Tabs>
                    </AppBar>
                    {tabValue === 0 && ((hasPermissions(Permissions.CAN_CREATE_CUSTOMER) || hasPermissions(Permissions.CAN_UPDATE_CUSTOMER)) ? (<CustomerAttributes customer={customer} />) : (<ViewCustomerAttributes customer={customer} />))}
                    {tabValue === 1 && canViewAdvancedTab && <CustomerAdvanced customerShortCode={customer.shortCode} />}
                    {tabValue === 2 && hasPermissions(Permissions.CAN_VIEW_REPORT_PARAMETERS) && reportsEnabled && <CustomerReports customer={customer} />}
                </Card>
            </Container>
        </AuthenticatedLayout>
    );
};
