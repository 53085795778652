import React, {  } from 'react';
import { Card, CardContent, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { getLabel } from '../common/label/Label.library';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import './ReportAttributes.css';
import { ReportValues } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { TabValue } from '../../constants/TabValue';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';


interface Props {
    report: ReportValues,
    customerShortCode: string,
    getReport: any;
}
export const ReportAttributes: React.FC<Props> = ({ report, customerShortCode, getReport }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (!values.ReportName) {
            errors.ReportName = getLabel('validation_message_required');
        }
        if (!values.ReportShortname) {
            errors.ReportShortname = getLabel('validation_message_required');
        }
        if (!values.ReportId) {
            errors.ReportId = getLabel('validation_message_required');
        }
        return errors;
    };

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: customerShortCode, tab: TabValue.CUSTOMER_REPORTS }));
    };

    const saveReport = async (values: any) => {
        const isAdd = report.id === -1;
        const data = {
            Id: report.id,
            Active: values.Active ? values.Active : false,
            ReportName: values.ReportName ? values.ReportName : null,
            ReportShortname: values.ReportShortname ? values.ReportShortname : null,
            ReportId: values.ReportId ? values.ReportId : null,
            SendDatasetRoles: values.SendDatasetRoles ?? false,
            IsDashboard: values.IsDashboard ?? false,
            HasExternalNav: values.HasExternalNav ?? false
        };
        try {
            const response = isAdd
                ? await makeJSONPostRequest(createUrl(ApiUrls.CREATE_REPORT, { customerShortCode }), dispatch, data)
                : await makeJSONPostRequest(ApiUrls.UPDATE_REPORT, dispatch, data);

            if (response.response.ok) {
                getReport();
                dispatch(SetUserMessageSuccessAction(isAdd ? 'report_attributes_success_add_text' : 'report_attributes_success_edit_text'));
                navigate(createRoute(ApplicationRoutes.EDIT_REPORT, { reportId: report.id === -1 ? response.body : report.id, customerShortCode: customerShortCode, tab: TabValue.REPORT_CATEGORIES }))
            }
        } catch (error: any) {
            if (error.status === 401) {
                dispatch(SetUserMessageErrorAction('report_failed_save_unauthorized'));
            }
        }
    };

    const canSave = () => {
        return hasPermissions(report.id === -1 ? Permissions.CAN_CREATE_REPORT_PARAMETERS : Permissions.CAN_UPDATE_REPORT_PARAMETERS);
    };

    return (
        <Card>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        Active: report.active ?? true,
                        ReportName: report.reportName ?? '',
                        ReportShortname: report.reportShortname ?? '',
                        ReportId: report.reportId ?? '',
                        SendDatasetRoles: report.sendDatasetRoles ?? false,
                        IsDashboard: report.isDashboard ?? false,
                        HasExternalNav: report.hasExternalNav ?? false
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveReport(values).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}
                >
                    {(props) => (
                        <form className="edit-report-attributes" onSubmit={props.handleSubmit}>
                            <div>
                                <TextInput name="ReportName" label="report_attributes_name_label" fullwidth={false} />
                                <TextInput name="ReportShortname" label="report_attributes_shortname_label" fullwidth={false} />
                                <TextInput name="ReportId" label="report_attributes_reportid_label" fullwidth={false} />
                            </div>
                            <div className="edit-report-attributes-buttons flex">
                                <CheckboxInput name="SendDatasetRoles" label="report_attributes_send_dataset_roles_label" />
                                <CheckboxInput name="HasExternalNav" label="report_attributes_has_external_nav_label" />
                                <CheckboxInput name="IsDashboard" label="report_attributes_is_dashboard_label" />
                                <CheckboxInput name="Active" label="report_attributes_active_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>
                                    {getLabel('report_attributes_cancel_button_label')}
                                </Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={!props.dirty || props.isSubmitting || !canSave()}>
                                    {getLabel('report_attributes_submit_button_label')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};
