import { CustomerSkinsDictionary } from "../interfaces/ApiInterfaces";

export const SET_CUSTOMER_SKINS = "SET_CUSTOMER_SKINS";

interface SetCustomerSkins {
    type: typeof SET_CUSTOMER_SKINS;
    customerSkins: CustomerSkinsDictionary | null;
}

export type CustomerSkinsActionTypes = SetCustomerSkins;

export const SetCustomerSkinsAction = (customerSkins: CustomerSkinsDictionary): SetCustomerSkins => ({
    type: SET_CUSTOMER_SKINS,
    customerSkins: customerSkins
});