export interface ReportSearch {
    name: string;
    category: string;
}

export const SET_REPORT_SEARCH = "SET_REPORT_SEARCH";
export const CLEAR_REPORT_SEARCH = "CLEAR_REPORT_SEARCH";


interface SetReportSearch {
    type: typeof SET_REPORT_SEARCH;
    reportSearch: ReportSearch;
}

interface ClearReportSearch {
    type: typeof CLEAR_REPORT_SEARCH;
}


export type ReportSearchAction = SetReportSearch | ClearReportSearch;

export const SetReportSearchAction = (name: string, category: string): SetReportSearch => ({
    type: SET_REPORT_SEARCH,
    reportSearch: { name: name, category: category }
});

export const ClearReportSearchAction = (): ClearReportSearch => ({
    type: CLEAR_REPORT_SEARCH
});