import moment, { Moment } from 'moment';

// Our API returns ISO8601 strings - e.g. '2024-07-12T15:29:17' - assumed to be UTC.
// The UI displays that in the LOCAL browser's time zone, using current locale settings.

// EXCEPT for "date only" fields... unfortunately, API still returns the same format: '2024-07-12T00:00:00'.
// That should remain as is (EST would shift to day before), especially for edits (must remain '2024-07-12').

type MaybeUtcStringOrAnyMoment = string | Moment;

const getMoment = (input: MaybeUtcStringOrAnyMoment, convertFromUtc: boolean) => {
  if (typeof input === 'string')
    input = moment.utc(input); // parse the day/time part of the string, ignore time zone (if any)

  return convertFromUtc
    ? input.local() // assume input was UTC, convert that to local time zone
    : input; // leave as is - maybe it's just the date we care about and don't want it to "shift"
};

const format = (formatString: string, defaultToNoTimeConversion: boolean = false) =>
  (input: MaybeUtcStringOrAnyMoment, convertFromUtc: boolean = !defaultToNoTimeConversion) => {
    return getMoment(input, convertFromUtc).format(formatString);
  };

// format codes reference: http://momentjs.com/docs/#/displaying/format/

export const formatEditDate = format('YYYY-MM-DD', true); // e.g. '1986-09-04'
export const formatShortDate = format('l', true); // e.g. '9/4/1986' in US locale
export const formatLongDate = format('LL', true); // e.g. 'September 4, 1986' in US locale
export const formatShortDateTime = format('l LT'); // e.g. '9/4/1986 8:30 PM' in US locale
export const formatLongDateTime = format('LLL'); // e.g. 'September 4, 1986 8:30 PM' in US locale
