export const CONTEXT_PATH: string = '/';
export const ACTING_FOR_SHORTCODE: string = '/:actingForShortCode';

export class ApplicationRoutes {
    public static readonly ROOT_ROUTE: string = CONTEXT_PATH;
    public static readonly LOGIN: string = CONTEXT_PATH + 'login';
    public static readonly SET_PASSWORD: string = CONTEXT_PATH + 'set-password';
    public static readonly RESET_PASSWORD: string = CONTEXT_PATH + 'reset-password';
    public static readonly NOT_FOUND: string = CONTEXT_PATH + 'notFound';
    public static readonly USERS: string = CONTEXT_PATH + 'users';
    public static readonly EDIT_USER: string = CONTEXT_PATH + 'users/edit/:id/:tab';
    public static readonly CUSTOMERS: string = CONTEXT_PATH + 'customers';
    public static readonly EDIT_CUSTOMERS: string = CONTEXT_PATH + 'customers/edit/:shortCode/:tab';
    public static readonly EDIT_REPORT: string = CONTEXT_PATH + 'customers/:customerShortCode/report/:reportId/:tab';
    public static readonly REPORTS: string = CONTEXT_PATH + 'reports';
    public static readonly VIEW_REPORT: string = CONTEXT_PATH + 'reports/view/:id';
    public static readonly INFO: string = CONTEXT_PATH + 'info';
    public static readonly VIEW_INFO: string = CONTEXT_PATH + 'info/view/:id';
    public static readonly EDIT_CATEGORY: string = CONTEXT_PATH + 'categories/edit/:id/:tab';
    public static readonly CATEGORIES: string = CONTEXT_PATH + 'categories';
    public static readonly UPLOAD_FILE: string = CONTEXT_PATH + 'files/upload';
    public static readonly EDIT_FILE: string = CONTEXT_PATH + 'files/edit/:container';
    public static readonly LIST_FILES: string = CONTEXT_PATH + 'files';
}

export function getActiveClass(locationPath: string, routes: string[]): string {
    return routes.some((route) => locationPath.startsWith(createRoute(route))) ? 'active' : '';
}

export function createRoute(url: string, params?: { [key: string]: any }): string {
    let newRoute = url;
    if (params) {
        Object.keys(params).forEach((key) => {
            newRoute = newRoute.replace(':' + key + '?', params[key] || '');
            newRoute = newRoute.replace(':' + key, params[key] || '');
        });
    }
    newRoute = newRoute.replace(ACTING_FOR_SHORTCODE, '');
    return newRoute;
}