import { Button, Card, CardContent, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { getLabel } from '../../../components/common/label/Label.library';
import { Formik } from 'formik';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { HeadingNode, PageHeading } from '../../../components/common/page.heading/PageHeading';
import { InfoTitles } from '../../../constants/InfoTitles';
import './FileEdit.css';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { setBrowserTitle } from '../../../services/browser/browser';
import { formatLongDateTime } from '../../../services/format/date';
import { FileInfo } from '../../../interfaces/ApiInterfaces';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { AutocompleteInput } from '../../../components/common/autocomplete.input/AutocompleteInput';
import { ClearUserMessageAction, SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { useActingForShortCode } from '../../../hooks/useActingFor';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const FileEdit: React.FC<any> = (props) => {
    const customerActingForShortCode = useActingForShortCode();
    const dispatch = useDispatch();
    const customerSkin = useCustomerSkin();
    const [file, setFile] = useState<FileInfo>({container: '', name: '', offline: false, modDate: '', metadata: '', length: 0, visibleTo: [], canEditVisibleTo: false});
    const [emails, setEmails] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, 'title_edit_file');
    }, [customerSkin])

    useEffect(() => {
        const setUp = async () => {
            try {
                await getEmails();
                await getFile();
            } catch {
                cancelEdit();
            }
        }
        setUp();
    }, [])

    const getFile = async () => {
        const container = params.container;
        const queryParams = queryString.parse(location.search);
        const filename = String(queryParams.filename);
        const data = {
            Filename: filename,
            Container: container,
            CustomerShortCode: customerActingForShortCode
        }
        const response = await makeJSONPostRequest(ApiUrls.GET_BLOB_INFO, dispatch, data, true, true);
        setFile(response.body);
        dispatch(ClearUserMessageAction());
    }

    const getEmails = async () => {
        const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER_AND_CUSTOMER_ADMIN_EMAILS, { customerShortCode: customerActingForShortCode }), dispatch, null, false, false);
        setEmails(response.body);
    }

    const updateFile = async (values: any) => {
        const data = {
            Filename: file.name,
            Container: file.container,
            CustomerShortCode: customerActingForShortCode,
            VisibleTo: values.VisibleTo
        }
        try {
            await makeJSONPostRequest(ApiUrls.UPDATE_BLOB, dispatch, data, false, false);
            dispatch(SetUserMessageSuccessAction(getLabel('file_update_success')));
            cancelEdit();
        } catch {
            dispatch(SetUserMessageErrorAction(getLabel('file_update_failure')));
        }
    }

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.LIST_FILES));
    }

    const getPageHeadingNodes = () : HeadingNode[] => {
        var nodes: HeadingNode[] = [];
        nodes.push({label: "file_page_heading", link: createRoute(ApplicationRoutes.LIST_FILES)});
        nodes.push({label: getLabel('file_edit_page_heading', { displayName: file.name})});
        return nodes;
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={InfoTitles.EDIT_FILE}>
            <Container maxWidth={false} className="file-edit">
                <div className="report-list-heading">
                    <PageHeading nodes={getPageHeadingNodes()} />
                </div>
                {file.name &&
                <Card className="file-edit-panel">
                    <CardContent>
                        <div className="field"><div className="colHeader">{getLabel("file_edit_name_header")}</div><div>{file.name}</div></div>
                        <div className="field"><div className="colHeader">{getLabel("file_edit_folder_header")}</div><div>{file.container}</div></div>
                        <div className="field"><div className="colHeader">{getLabel("file_edit_moddate_header")}</div><div>{formatLongDateTime(file.modDate)}</div></div>
                        <div className="field"><div className="colHeader">{getLabel("file_edit_size_header")}</div><div>{getLabel("file_size_kb_value", {size: (file.length/1024).toLocaleString()})}</div></div>
                        <div className="field"><div className="colHeader">{getLabel("file_edit_metadata_header")}</div><div>{file.metadata}</div></div>
                        <div className="autocomplete-field">
                            <Formik
                                initialValues={{ VisibleTo: file.visibleTo }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values, actions) => {
                                    updateFile(values).finally(() => {
                                        actions.setSubmitting(false);
                                    });
                                }}>
                                {(props) => (
                                <form encType='multipart/form-data' onSubmit={props.handleSubmit}>
                                    <div className='autocomplete-width'>
                                        <AutocompleteInput name="VisibleTo" setFieldValue={props.setFieldValue} disabled={!file.canEditVisibleTo} options={emails} label="file_edit_visible_to_header" />
                                    </div>
                                    <div className="file-edit-buttons">
                                        <Button className="button" type="button" variant="contained" color="primary" onClick={() => cancelEdit()} disabled={props.isSubmitting}>
                                            {getLabel('file_edit_cancel_button_label')}
                                        </Button>
                                        <Button className="edit-button button" type="submit" variant="contained" color="primary" disabled={!file.canEditVisibleTo || props.isSubmitting || !props.dirty}>
                                            {getLabel('file_edit_submit_button_label')}
                                        </Button>
                                    </div>
                                </form>)}
                            </Formik>
                        </div>
                    </CardContent>
                </Card>}
            </Container>
        </AuthenticatedLayout>
    )
}
