import React from 'react';
import { Card, CardContent, Checkbox, FormControlLabel } from '@mui/material';
import { getLabel } from '../common/label/Label.library';
import './ViewUserAttributes.css';
import { User, UserRoles } from '../../interfaces/ApiInterfaces';
import { getAllowLoginLabel } from './UserAttributesUtil';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

interface Props {
  user: User;
  roleValues: UserRoles[];
}

export const ViewUserAttributes: React.FC<Props> = ({ user, roleValues }) => {

  const isSSOFeatureEnabled = useFeatureFlags()?.SSOLogIn;

  const getRole = (roleId: number) => {
    const role = roleValues.filter(r => r.id == roleId)[0];
    return role?.name;
  }

  return (
    <Card className="view-user-attributes">
      <CardContent>
        <div className="grid">
          <div>
            <div className="userLabel"><div className="colHeader">{getLabel('user_attributes_displayName_label')}</div><div>{user.name}</div></div>
            <div className="userLabel"><div className="colHeader">{getLabel('user_attributes_email_label')}</div><div>{user.email}</div></div>
          </div>
          <div>
            <div className="userLabel"><div className="colHeader">{getLabel('user_attributes_city_label')}</div><div>{user.city}</div></div>
            <div className="userLabel"><div className="colHeader">{getLabel('user_attributes_state_label')}</div><div>{user.state}</div></div>
          </div>
          <div>
            <div className="userLabel"><div className="colHeader">{getLabel('user_attributes_roles_label')}</div>
              <div>
                {user.roleIds.map(roleId => <div key={roleId}>{getRole(roleId)}</div>)}
              </div>
            </div>
          </div>
          <div className="flexColumn">
            <FormControlLabel control={<Checkbox checked={user.restrictReportView} disabled />} label={getLabel('user_attributes_restrictReportView_label')} />
            <FormControlLabel control={<Checkbox checked={user.restrictFilesAccess} disabled />} label={getLabel('user_attributes_restrictFilesAccess_label')} />
            {user.restrictFilesAccess &&
              <FormControlLabel control={<Checkbox checked={user.allowFileUpload} disabled />} label={getLabel('user_attributes_allowFileUpload_label')} />}
            <span className="flexFill"></span>
            {isSSOFeatureEnabled && <>
              <FormControlLabel control={<Checkbox checked={!!user.allowPwdLogin} disabled />} label={getAllowLoginLabel(user, 'Pwd')} />
              <FormControlLabel control={<Checkbox checked={!!user.allowSsoLogin} disabled />} label={getAllowLoginLabel(user, 'Sso')} />
            </>}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};