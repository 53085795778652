export interface Customer {
    name: string;
    contactName: string;
    email: string;
    telephone: string;
    extension: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    forDemoOnly: boolean;
    hasCoreProduct: boolean;
    isActive: boolean;
    shortCode: string;
    startDate: string;
    endDate: string;
    hasStorageAccountName: boolean;
    hasReportEnv: boolean;
}
export const EmptyCustomer: Customer = {
    name: '',
    contactName: '',
    email: '',
    telephone: '',
    extension: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    forDemoOnly: false,
    hasCoreProduct: false,
    isActive: true,
    shortCode: '',
    startDate: '',
    endDate: '',
    hasStorageAccountName: false,
    hasReportEnv: false
}

export interface CustomerAttribute {
    value: string;
    name: string;
    type: string;
    readOnly: boolean;
    isSecret: boolean;
    default: string;
}

export interface Category {
    id: number;
    name: string;
    active: boolean;
}

export interface Report {
    id: number;
    name: string;
    reportShortName: string;
    url: string;
    categories: Category[];
    customer: Customer;
    active: boolean;
    embedToken: string;
    reportId: string;
    isDashboard: boolean;
    hasExternalNav: boolean;
}

export interface ReportValues {
    id: number;
    active: boolean;
    reportName: string;
    reportShortname: string;
    reportId: string;
    sendDatasetRoles: boolean;
    isDashboard: boolean;
    hasExternalNav: boolean;
}

export interface RolesPermission {
    id: number;
    code: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
    isActive: boolean;
    city: string;
    state: string;
    restrictReportView: boolean;
    restrictFilesAccess: boolean;
    allowFileUpload: boolean;
    allowPwdLogin: boolean | null;
    allowSsoLogin: boolean | null;
    lastPwdLogin: string | null;
    lastSsoLogin: string | null;
    customerShortCodes: string[];
    roleIds: number[];
    isCreator: boolean;
}

export interface UserRoles {
    id: number;
    name: string;
    code: string;
    permissions: RolesPermission[];
}

export interface Info {
    id: number;
    category: string;
    title: string;
    content: string;
}

export interface BooleanDictionary {
    [index: string]: boolean
}

export interface CustomerSkin {
    customerShortCode: string;
    hostname: string;
    title: string;
    logoName: string;
    themePrimaryMainColor: string;
    themePrimaryContrastTextColor: string;
    themeCssClassName: string;
}

export interface CustomerSkinsDictionary {
    [index: string]: CustomerSkin
}

export interface File {
    container: string;
    name: string;
    offline: boolean;
    modDate: string;
    metadata: string;
    length: number;
    visibleTo: string[];
}

export interface FileInfo extends File {
    canEditVisibleTo: boolean;
}

export interface BlobStorageContainer {
    blobStorageName: string;
    uiName: string;
    isTrashContainer: boolean;
    isDefaultForUpload: boolean;
    isDefaultSearchListFilter: boolean;
}

export interface UploadContainer extends BlobStorageContainer {
    isVisibleToEditable: boolean;
}

export interface UploadRestrictions {
    maximumSizeInBytes: number | null;
    allowedExtensions: string[];
}