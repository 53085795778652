import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { Container, Card, AppBar, Tabs, Tab } from '@mui/material';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import './EditReport.css';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { TabValue } from '../../../constants/TabValue';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { Customer, EmptyCustomer, ReportValues } from '../../../interfaces/ApiInterfaces';
import { ReportAttributes } from '../../../components/reports/ReportAttributes';
import { ReportCategories } from '../../../components/reports/ReportCategories';
import { HeadingNode, PageHeading } from '../../../components/common/page.heading/PageHeading';
import { setBrowserTitle } from '../../../services/browser/browser';
import { useCustomerSkin } from '../../../hooks/useCustomerSkin';
import { InfoTitles } from '../../../constants/InfoTitles';
import { useNavigate, useParams } from 'react-router-dom';

export const EditReport: React.FC<any> = (props) => {
    const dispatch = useDispatch();

    const [tabValue, setTabValue] = useState(0);
    const params = useParams();
    const reportId = +params.reportId!;
    const customerShortCode = params.customerShortCode!;
    const [customer, updateCustomer] = useState<Customer>({ ...EmptyCustomer, shortCode: customerShortCode });
    const [report, updateReport] = useState<ReportValues>({ id: -1, active: false, reportName: '', reportShortname: '', reportId: '', sendDatasetRoles: false, isDashboard: false, hasExternalNav: false });
    const customerSkin = useCustomerSkin();
    const navigate = useNavigate();

    useEffect(() => {
        setBrowserTitle(customerSkin.title, reportId === -1 ? 'title_add_report' : 'title_edit_report');
    }, [customerSkin]);

    useEffect(() => {
        getReport();
    }, [reportId, dispatch]);

    const getReport = () => {
        if (reportId !== -1) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_REPORT_VALUES, { reportId }), dispatch, null, false, false).then((response) => {
                updateReport(response.body);
            });
        }
    }

    useEffect(() => {
        getCustomer();
    }, []);

    const getCustomer = () => {
        if (customerShortCode) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_CUSTOMER, { customerShortCode }), dispatch, null, false, false).then((response) => {
                updateCustomer(response.body);
            });
        }
    }

    useEffect(() => {
        if (params.tab === TabValue.REPORT_ATTRIBUTES) {
            setTabValue(0);
        }
        if (params.tab === TabValue.REPORT_CATEGORIES) {
            setTabValue(1);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_REPORT, { reportId: reportId, customerShortCode: customerShortCode, tab: TabValue.REPORT_ATTRIBUTES }))
            setTabValue(0);
        }
    }, [reportId, params.tab])

    function getAddEditLabel() {
        return reportId === -1
            ? getLabel('report_add_page_heading')
            : getLabel('report_edit_page_heading', { displayName: report.reportName });
    }

    function getPageHeadingNodes(): HeadingNode[] {
        var nodes: HeadingNode[] = [];
        nodes.push({label: "customer_page_heading", link: createRoute(ApplicationRoutes.CUSTOMERS)});
        nodes.push({ label: getLabel("customer_edit_page_heading", { displayName: customer.name }), link: createRoute(ApplicationRoutes.EDIT_CUSTOMERS, { shortCode: customerShortCode, tab: TabValue.CUSTOMER_REPORTS})});
        nodes.push({label: getAddEditLabel()});
        return nodes;
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_REPORT, { reportId: reportId, customerShortCode: customerShortCode, tab: TabValue.REPORT_ATTRIBUTES }))
        }
        if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_REPORT, { reportId: reportId, customerShortCode: customerShortCode, tab: TabValue.REPORT_CATEGORIES }))
        }
        setTabValue(value);
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_REPORT_PARAMETERS;
        }
        if (tabValue === 1) {
            return InfoTitles.REPORT_CATEGORIES;
        }
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="edit-report-container">
                <PageHeading nodes={getPageHeadingNodes()} />
                <Card className="edit-report-panel">
                    <AppBar position="static" color="default">
                        <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary"
                            textColor="primary">
                            <Tab value={0} label={getLabel('tab_label_attributes')} />
                            <Tab value={1} label={getLabel('tab_label_categories')} disabled={reportId === -1} />
                        </Tabs>
                    </AppBar>
                    {tabValue === 0 && <ReportAttributes report={report} customerShortCode={customerShortCode} getReport={getReport} />}
                    {tabValue === 1 && <ReportCategories report={report} />}
                </Card>
            </Container>
        </AuthenticatedLayout>
    );
};
